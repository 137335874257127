import { Trans } from '@lingui/macro'
import { nanoid } from '@reduxjs/toolkit'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { Currency } from '@uniswap/sdk-core'
import { pubkeyFromStringForced } from 'apollo/utils'
import { ButtonError, ButtonLight } from 'components/Button'
import { OutlineCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { useApolloProgram } from 'components/SolanaManager'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useWalletModalToggle } from 'state/application/hooks'
import { useCreateFirebaseCollection } from 'state/collections/firebase'
import { Collection, CollectionType } from 'state/collections/models'
import { metadataFromMint } from 'state/sales/chain'
import styled from 'styled-components/macro'
import { ExternalLink, TYPE } from 'theme'

import { CreateCollectionTabs } from '../../components/NavigationTabs'
import { UNI } from '../../constants/tokens'
import AppBody from '../AppBody'
import { CollectionAction } from './CollectionActionSelector'
import { CollectionEditor } from './CollectionEditor'
import { CollectionMintsInput } from './CollectionMintsInput'
import { CollectionSubmissionModal } from './CollectionSubmissionModal'
import { Wrapper } from './styled'

const CreateCollectionButton = ({
  isFormInvalid,
  isMintsInvalid,
  handleCreateCollection,
}: {
  isFormInvalid: boolean
  isMintsInvalid: boolean
  handleCreateCollection: () => void
}) => {
  return (
    <ButtonError
      style={{ marginTop: '18px' }}
      error={isMintsInvalid && !isFormInvalid}
      disabled={isMintsInvalid || isFormInvalid}
      onClick={handleCreateCollection}
    >
      {isFormInvalid ? (
        <Trans>Submit Collection</Trans>
      ) : isMintsInvalid ? (
        <Trans>Invalid Mints List</Trans>
      ) : (
        <Trans>Submit Collection</Trans>
      )}
    </ButtonError>
  )
}

const CreateCollectionWrapper = styled(Wrapper)`
  display: flex;
  flex-flow: column wrap;
`

const AutonomousCollectionCTA = styled.div`
  text-align: center;
  margin-top: 10px;
`

export default function CreateCollection() {
  const { account, chainId } = useActiveWeb3React()

  const [modalOpen, setModalOpen] = useState(false)
  const [requestID, setRequestID] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)
  const [proposalAction, setCollectionAction] = useState(CollectionAction.TRANSFER_TOKEN)
  const [toAddressValue, setToAddressValue] = useState('')
  const [currencyValue, setCurrencyValue] = useState<Currency>(UNI[chainId ?? 1])
  const [amountValue, setAmountValue] = useState('')
  const [titleValue, setTitleValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const [discordValue, setDiscordValue] = useState('')
  const [twitterValue, setTwitterValue] = useState('')
  const [websiteValue, setWebsiteValue] = useState('')

  const [mintsRaw, setMintsRaw] = useState('')
  const [verifiedMints, setVerifiedMints] = useState<string[]>([])
  const [mintsErrorMessage, setMintsErrorMessage] = useState('')

  const wallet = useAnchorWallet()
  const apollo = useApolloProgram()
  const dispatch = useDispatch()
  const toggleWalletModal = useWalletModalToggle()

  const publicKey = wallet?.publicKey

  const createFirebaseCollection = useCreateFirebaseCollection()

  useEffect(() => {
    if (publicKey) {
      setMintsErrorMessage('')
      setMintsRaw('')
    }
  }, [publicKey])

  const mints = useMemo(() => {
    return mintsRaw
      .replace(/\s/g, '')
      .split(',')
      .filter((m) => m != '')
      .sort((a, b) => a.localeCompare(b))
  }, [mintsRaw])

  const handleMintsRawInput = useCallback(
    (mintsRaw: string) => {
      setMintsRaw(mintsRaw)
    },
    [setMintsRaw]
  )

  useEffect(() => {
    async function verifyMints() {
      setVerifiedMints([])
      setMintsErrorMessage('')
      if (mints.length === 0) {
        setMintsErrorMessage('')
        return
      }

      for (const mint of mints) {
        try {
          const pubkey = pubkeyFromStringForced(mint)
          const metadata = await metadataFromMint(pubkey)
          if (!metadata) {
            setMintsErrorMessage('Invalid mint: ' + mint + '(metadata not found)')
            return
          }
          if (!metadata.data.creators.map((c) => c.address).includes(publicKey?.toBase58() || '')) {
            setMintsErrorMessage('Invalid mint: ' + mint + ' (not creator)')
            return
          }
          setVerifiedMints((prev) => [...prev.filter((p) => p != mint), mint])
        } catch (e) {
          setMintsErrorMessage('Invalid mint: ' + mint + ' (' + e.message + ')')
          return
        }
      }
    }
    verifyMints()
  }, [mints, publicKey])

  const verifiedMintCount = useMemo(() => verifiedMints.length, [verifiedMints])

  const handleDismissSubmissionModal = useCallback(() => {
    setRequestID(undefined)
    setAttempting(false)
  }, [setAttempting])

  const handleTitleInput = useCallback(
    (title: string) => {
      setTitleValue(title)
    },
    [setTitleValue]
  )
  const handleDescriptionInput = useCallback(
    (description: string) => {
      setDescriptionValue(description)
    },
    [setDescriptionValue]
  )
  const handleDiscordInput = useCallback(
    (discord: string) => {
      setDiscordValue(discord)
    },
    [setDiscordValue]
  )
  const handleTwitterInput = useCallback(
    (twitter: string) => {
      setTwitterValue(twitter)
    },
    [setTwitterValue]
  )
  const handleWebsiteInput = useCallback(
    (website: string) => {
      setWebsiteValue(website)
    },
    [setWebsiteValue]
  )

  const isMintsInvalid = useMemo(
    () => Boolean(!publicKey || mints.length == 0 || !(mints.length == verifiedMints.length)),
    [publicKey, mints.length, verifiedMints.length]
  )

  const isFormInvalid = useMemo(
    () => Boolean(!publicKey || isMintsInvalid || titleValue === '' || descriptionValue === ''),
    [publicKey, isMintsInvalid, titleValue, descriptionValue]
  )

  // const createCollectionCallback = useCreateCollectionCallback()

  const handleCreateCollection = async () => {
    if (!publicKey) {
      toggleWalletModal()
      return
    }
    const collectionData: Collection = {
      id: nanoid(),
      type: CollectionType.Curated,
      user: publicKey?.toBase58(),
      mints: verifiedMints,
      name: titleValue,
      description: descriptionValue,
      discord: discordValue,
      twitter: twitterValue,
      website: websiteValue,
      createdDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      onChainCollectionKey: '',
      candyMachine: '',
    }
    const requestID = await createFirebaseCollection(collectionData)
    setRequestID(requestID)
    setAttempting(true)
  }

  return (
    <AppBody {...{ maxWidth: '800px' }}>
      <CreateCollectionTabs />
      <CreateCollectionWrapper>
        <OutlineCard>
          <AutoColumn gap="10px">
            <TYPE.link fontWeight={400} color={'primaryText1'}>
              <Trans>
                <strong>For use by NFT Collection Creators:</strong>{' '}
                {`Submit collection information and list of mints for
                your community's collection to be featured on Apollo Market.    
                `}
                <span>
                  <ExternalLink href="https://docs.projectapollo.app/collections">Learn More</ExternalLink>.
                </span>
              </Trans>
            </TYPE.link>
          </AutoColumn>
        </OutlineCard>
        {!publicKey ? (
          <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'}>
            <Trans>Connect Wallet</Trans>
          </ButtonLight>
        ) : (
          <>
            <CollectionEditor
              title="Name"
              placeholder="Collection Name"
              input={titleValue}
              onInput={handleTitleInput}
            />
            <CollectionEditor
              title="Description"
              placeholder="Description"
              input={descriptionValue}
              onInput={handleDescriptionInput}
            />
            <CollectionEditor
              title="Discord"
              placeholder="https://discord.com/invite/code"
              input={discordValue}
              onInput={handleDiscordInput}
              optional
            />
            <CollectionEditor
              title="Twitter"
              placeholder="@"
              input={twitterValue}
              onInput={handleTwitterInput}
              optional
            />
            <CollectionEditor
              title="Website"
              placeholder="https://website.com"
              input={websiteValue}
              onInput={handleWebsiteInput}
              optional
            />
            <CollectionMintsInput
              mintsRaw={mintsRaw}
              onMintsRawInput={handleMintsRawInput}
              mints={mints}
              verifiedMints={verifiedMints}
              errorMessage={mintsErrorMessage}
            ></CollectionMintsInput>
            <CreateCollectionButton
              isFormInvalid={isFormInvalid}
              isMintsInvalid={isMintsInvalid}
              handleCreateCollection={handleCreateCollection}
            />
          </>
        )}
      </CreateCollectionWrapper>
      <CollectionSubmissionModal isOpen={attempting} requestID={requestID} onDismiss={handleDismissSubmissionModal} />
    </AppBody>
  )
}
