import { getVersionUpgrade, minVersionBump, VersionUpgrade } from '@uniswap/token-lists'
import { querySalesByCollectionIDs, useQuerySalesByCollectionIDsCallback } from 'api/firebaseHooks'
import { SupportedChainId } from 'constants/chains'
import { ARBITRUM_LIST, OPTIMISM_LIST, UNSUPPORTED_LIST_URLS } from 'constants/lists'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'
import { useAllLists } from 'state/lists/hooks'

import { useFetchListCallback } from '../../hooks/useFetchListCallback'
import useInterval from '../../hooks/useInterval'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { useActiveWeb3React } from '../../hooks/web3'
import { useCollectionIDsFilter } from './hooks'

export default function Updater(): null {
  const dispatch = useAppDispatch()
  const isWindowVisible = useIsWindowVisible()

  // get all loaded lists, and the active urls
  const collectionsIDsFilter = useCollectionIDsFilter()
  const querySalesByCollectionIDs = useQuerySalesByCollectionIDsCallback()

  useEffect(() => {
    console.log('aState collectionsIDsFilter: ', collectionsIDsFilter)
    querySalesByCollectionIDs(collectionsIDsFilter)
  }, [collectionsIDsFilter, dispatch, querySalesByCollectionIDs])
  // fetch all lists every 10 minutes, but only after we initialize library

  return null
}
