import { PrivacyPolicyModal } from 'components/PrivacyPolicy'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useRef } from 'react'
import { useModalOpen, useToggleModal } from 'state/application/hooks'

import { FilterButton, FilterCoverText, StyledChevronDown, StyledMenu } from '../styled'
import MintsStatusFlyout from './MintsStatusFlyout'

function MintsStatusCoverButton({ toggle }: { toggle: () => void }) {
  return (
    <FilterButton onClick={toggle} aria-label={`Menu`}>
      <FilterCoverText>Status</FilterCoverText>
      <StyledChevronDown size={'18px'} />
    </FilterButton>
  )
}

export default function MintsStatusFilter() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen('MintsStatusFilter')
  const toggleMenu = useToggleModal('MintsStatusFilter')
  useOnClickOutside(node, open ? toggleMenu : undefined)

  return (
    <>
      <StyledMenu ref={node as any}>
        <MintsStatusCoverButton toggle={toggleMenu} />
        {open && <MintsStatusFlyout />}
      </StyledMenu>
      <PrivacyPolicyModal />
    </>
  )
}
