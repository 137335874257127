import { Trans } from '@lingui/macro'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { BaseButton, ButtonConfirmed, ButtonCreate } from 'components/Button'
import { DarkGreyCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import GenericArtDisplay from 'components/GenericArtDisplay'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import MintArtDisplay from 'components/MintArtDisplay'
import MintInfoContent, { MintInfoField } from 'components/MintInfoContent'
import NiceNav from 'components/NiceNav'
import OrderCard from 'components/orders/OrderCard'
import { RowBetween } from 'components/Row'
import SaleCardArtDisplay from 'components/SaleCard/SaleCardArtDisplay'
import { useApolloProgram } from 'components/SolanaManager'
import TXConfirmationModal, { ConfirmationModalContent } from 'components/TXConfirmationModal'
import { useListColor } from 'hooks/useColor'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import useTheme from 'hooks/useTheme'
import { ContentPageWrapper } from 'pages/ExplorePage'
import { PageWrapper } from 'pages/styled'
import { darken, opacify, transparentize } from 'polished'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { useCreateOrderModalToggle, useWalletModalToggle } from 'state/application/hooks'
import { setGlobalColor } from 'state/application/reducer'
import { resetToBuyOrder, resetToSellOrder } from 'state/createOrder/actions'
import { addRequiredMints, refreshMint } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import { addRequiredOrderQueries } from 'state/orders/actions'
import { ORDER_SIDE } from 'state/orders/chain'
import { useOrdersByAssetMintAndSide } from 'state/orders/hooks'
import { RequiredOrderQuery } from 'state/orders/reducer'
import { addRequiredUsers } from 'state/users/actions'
import { useSafeUser } from 'state/users/hooks'
import styled from 'styled-components/macro'
import { Break, StyledInternalLink, TYPE } from 'theme'

import { MintPageBaseView } from './MintPageBaseView'
import { ScrollablePage } from './styled'

const TopWrapper = styled.div`
  display: flex;
  // justify-content: center;
  width: 100%;
  // max-height: 50vh !important;
  overflow: visible;
  padding: 0;
  margin-bottom: 20px;
  // margin-top: 40px;
`

const TopTwoColumn = styled.div`
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: row;
  align-items: start;

  // min-width: 1200px;
  max-width: 100%;
  // overflow: visible;
  padding: 0.75rem 3rem 0.75rem 1rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   grid-template-columns: 1fr;
    min-width: 680px;
    max-width: 680px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 600px;
    max-width: 600px;
  `};

  @media only screen and (max-width: 620px) {
    min-width: 500px;
    max-width: 500px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 340px;
    max-width: 340px;
  `};
`
const BottomTwoColumn = styled.div`
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  grid-template-columns: 300px 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: row;
  align-items: start;

  // min-width: 1200px;
  max-width: 100%;
  width: 100%;
  // overflow: visible;
  padding: 0.75rem 3rem 0.75rem 1rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   grid-template-columns: 1fr;
    min-width: 680px;
    max-width: 680px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 600px;
    max-width: 600px;
  `};

  @media only screen and (max-width: 620px) {
    min-width: 500px;
    max-width: 500px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 340px;
    max-width: 340px;
  `};
`

const BottomWrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;
  background-color: ${({ theme }) => theme.bg1};
`

export const Section = styled(AutoColumn)`
  padding: 15px;
`

export const ArtContainer = styled(AutoColumn)`
  max-width: 500px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  max-width: 100%;

`};
`

export const SellBox = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor, theme }) => theme.bg1};
  width: 90%;
  margin: 5rem 5rem 0 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 90%;
  `};
  border: 2px solid ${({ bgColor, theme }) => bgColor};
  border-radius: 1rem;
  filter: ${({ bgColor, theme }) =>
    `drop-shadow(0px 3.76515px 20.7083px ${transparentize(0.9, bgColor ?? theme.bg1)})`};
  height: 100%;
  width: 100%;
`

// export const BottomTwoColumns = styled.div`
//   display: grid;
//   grid-column-gap: 50px;
//   grid-row-gap: 15px;
//   grid-template-columns: 1fr 1fr;
//   grid-template-rows: max-content;
//   grid-auto-flow: row;
//   align-items: start;
//   // justify-content: start;
//   width: 100%;

//   padding-top: 0px;

//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     grid-template-columns: 1fr;

//     margin-top: 0;
//   `};
// `

const BigSellButton = styled(ButtonCreate)`
  padding: 10px 40px;
  // width: 60px;
  width: fit-content;
  color: ${({ theme }) => theme.text2};
  border-radius: 1rem;
`

const BigOfferButton = styled(BaseButton)<{ xColor?: string }>`
  // ${({ theme }) => theme.flexColumnNoWrap}
  // border: ${({ xColor, theme }) => `2px solid ${theme.primary1 ?? theme.primary1}`};
  padding: ${({ padding }) => padding ?? '10px 14px'};
  font-weight: 600;
  // width: 100%;
  background-color: ${({ theme }) => transparentize(0.0, theme.blue1)};
  color: ${({ theme }) => (theme.darkMode ? theme.text1 : theme.text5)};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.1, theme.blue1)};
    color: ${({ theme }) => (theme.darkMode ? theme.text1 : theme.text5)};
  }
  &:hover {
    background-color: ${({ theme }) => (theme.darkMode ? transparentize(0.1, theme.blue1) : darken(0.1, theme.blue1))};
    color: ${({ theme }) => (theme.darkMode ? theme.text1 : theme.text5)};
  }
  &:active {
    background-color: ${({ theme }) => transparentize(0.1, theme.blue1)};
    color: ${({ theme }) => (theme.darkMode ? theme.text1 : theme.text5)};
  }
  padding: 10px 40px;
  width: fit-content;
  border-radius: 0.5rem;
`

const OfferButton = styled(ButtonCreate)`
  padding: 5px 20px;
  // width: 60px;
  width: fit-content;
  color: ${({ theme }) => theme.text2};
`

const TopSectionWrapper = styled.div<{ bgColor?: string }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  // background-color: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.transparent)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 0.5rem;
  margin: 0;
  // padding: 1rem 1rem 1rem 3rem;
  width: 100%;
  height: 100%;
`

// const BottomWrapper = styled.div`
//   width: 80%;
//   max-width: 1400px;
//   // background-color: ${({ theme }) => theme.bg0};
// `

export default function MintPage({
  match: {
    params: { mintAddress: mintAddressFromURL, action: actionFromURL },
  },
}: RouteComponentProps<{ mintAddress?: string; action?: string }>) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const wallet = useAnchorWallet()
  const userPubkey = wallet?.publicKey
  const apollo = useApolloProgram()
  const theme = useTheme()
  const dispatch = useDispatch()

  const user = useSafeUser(userPubkey?.toBase58())

  useEffect(() => {
    if (userPubkey) {
      dispatch(addRequiredUsers([userPubkey.toBase58()]))
    }
  }, [dispatch, userPubkey])

  console.log('mintpage mintAddressFromURL', mintAddressFromURL)

  const mintPubkey = useMemo(
    () => (mintAddressFromURL ? new PublicKey(mintAddressFromURL) : undefined),
    [mintAddressFromURL]
  )

  const mintData = useSafeMint(mintPubkey?.toBase58())

  useEffect(() => {
    console.log('mintpage: ', mintPubkey?.toBase58())
    if (apollo && mintPubkey && !mintData) {
      dispatch(addRequiredMints([mintPubkey.toBase58()]))
    }
  }, [apollo, dispatch, mintData, mintPubkey])

  // const { tokenRegistry } = useTokenRegistry()

  const [showConfirm, setShowConfirm] = useState(false)
  const [submitError, setSubmitError] = useState<string | undefined>()
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [clickedAskUUID, setClickedAskUUID] = useState<PublicKey | undefined>()

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setTimeout(() => {
      setSubmitError(undefined)
    }, 200)
    if (txHash) {
    }
    setTxHash('')
  }, [txHash])

  const toggleCreateOrderModal = useCreateOrderModalToggle()
  const toggleWalletModal = useWalletModalToggle()

  // const sellOrdersQueryRequest = useMemo(
  //   () => new RequiredOrderQuery([mintData?.address ?? ''], ORDER_SIDE.SELL),
  //   [mintData?.address]
  // )

  // const buyOrdersQueryRequest = useMemo(
  //   () => new RequiredOrderQuery([mintData?.address ?? ''], ORDER_SIDE.BUY),
  //   [mintData?.address]
  // )

  // useEffect(() => {
  //   dispatch(addRequiredOrderQueries([sellOrdersQueryRequest, buyOrdersQueryRequest]))
  // }, [dispatch, sellOrdersQueryRequest, buyOrdersQueryRequest])

  const orderQueryRequest = useMemo(() => new RequiredOrderQuery([mintData?.address ?? '']), [mintData?.address])

  useEffect(() => {
    dispatch(addRequiredOrderQueries([orderQueryRequest]))
  }, [dispatch, orderQueryRequest])

  const sellOrders = useOrdersByAssetMintAndSide(mintData?.address, ORDER_SIDE.SELL)
  console.log('mintpage sellOrders', sellOrders)
  const topSectionSellOrders = useMemo(() => {
    return sellOrders?.slice(0, 2)
  }, [sellOrders])

  const userSellOrders = useMemo(() => {
    return sellOrders?.filter((order) => order.maker == userPubkey?.toBase58() ?? '')
  }, [sellOrders, userPubkey])

  const buyOrders = useOrdersByAssetMintAndSide(mintData?.address, ORDER_SIDE.BUY)
  const topSectionBuyOrders = useMemo(() => {
    return buyOrders?.slice(0, 2)
  }, [buyOrders])

  const mintColor = useListColor(mintData?.metadata?.extended?.image)
  useEffect(() => {
    console.log('mintColo2r', mintColor)
    dispatch(setGlobalColor({ color: mintColor }))
  }, [dispatch, mintColor])

  const handleNewOffer = useCallback(() => {
    if (!mintData) {
      setSubmitError('No mint data')
      return
    }
    dispatch(resetToBuyOrder({ assetMint: mintData.address }))
    if (!wallet) {
      toggleWalletModal()
    } else {
      toggleCreateOrderModal()
    }
  }, [dispatch, mintData, toggleCreateOrderModal, toggleWalletModal, wallet])

  const userOwnsMint = useMemo(() => {
    if (!mintData) {
      return false
    }
    return user?.ownedMints
      .filter((om) => parseFloat(om.amount) > 0)
      .map((om) => om.address)
      .includes(mintData.address)
  }, [user, mintData])

  const userSellOrder = useMemo(
    () => sellOrders.find((o) => o.side === ORDER_SIDE.SELL && o.maker === userPubkey?.toBase58()),
    [sellOrders, userPubkey]
  )

  return mintData ? (
    <>
      {}
      <TXConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        errorText={submitError}
        doneLink={'/bundles'}
        content={() => (
          <ConfirmationModalContent
            title={<Trans>Confirm Instant Purchase</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={() => <Trans>Tokens</Trans>}
            bottomContent={() => (
              <TYPE.darkGray fontWeight={500} fontSize={20}>
                <Trans>Submit</Trans>
              </TYPE.darkGray>
            )}
          />
        )}
        pendingText={'...'}
      />
      <ContentPageWrapper>
        <NiceNav></NiceNav>
        <TopWrapper>
          <TopTwoColumn>
            <ArtContainer>
              <MintArtDisplay mintAddress={mintData.address} size={'100%'} clickable={true} />
            </ArtContainer>
            <TopSectionWrapper>
              <MintInfoContent
                mintAddress={mintData?.address}
                size={''}
                hidden={[MintInfoField.Mint, MintInfoField.Attributes, MintInfoField.Description]}
              />
              {userOwnsMint && !userSellOrder && (
                <BigSellButton
                  xColor={mintColor}
                  onClick={() => {
                    dispatch(resetToSellOrder({ assetMint: mintData.address }))
                    if (!wallet) {
                      toggleWalletModal()
                    } else {
                      toggleCreateOrderModal()
                    }
                  }}
                >
                  Sell
                </BigSellButton>
              )}
              {topSectionSellOrders.length == 0 && (
                <BigOfferButton
                  xColor={mintColor}
                  onClick={() => {
                    dispatch(resetToBuyOrder({ assetMint: mintData.address }))
                    if (!wallet) {
                      toggleWalletModal()
                    } else {
                      toggleCreateOrderModal()
                    }
                  }}
                >
                  Make Offer
                </BigOfferButton>
              )}
              {topSectionSellOrders.map((order, i) => {
                if (order && !order.revoked) {
                  return (
                    <React.Fragment key={i}>
                      <OrderCard index={i} order={order} />
                      <Break />
                    </React.Fragment>
                  )
                }
                return null
              })}
            </TopSectionWrapper>
          </TopTwoColumn>
        </TopWrapper>

        <TopWrapper>
          <BottomTwoColumn>
            <AutoColumn>
              <MintInfoContent
                mintAddress={mintData?.address}
                size={''}
                hidden={[MintInfoField.Name, MintInfoField.Collection]}
              />
            </AutoColumn>
            <AutoColumn>
              <RowBetween>
                <AutoColumn>
                  <TYPE.heavy color={theme.text2} fontSize={18}>
                    Top Offers
                  </TYPE.heavy>
                </AutoColumn>
                <OfferButton padding={'1rem 2rem'} xColor={mintColor} onClick={handleNewOffer}>
                  New Offer
                </OfferButton>
              </RowBetween>
              <SectionBreak></SectionBreak>
              {topSectionBuyOrders.map((order, i) => {
                if (order && !order.revoked) {
                  return (
                    <React.Fragment key={i}>
                      <OrderCard index={i} order={order} />
                      <Break />
                    </React.Fragment>
                  )
                }
                return null
              })}
            </AutoColumn>
          </BottomTwoColumn>
        </TopWrapper>
      </ContentPageWrapper>
    </>
  ) : (
    <TYPE.subHeader>Mint</TYPE.subHeader>
  )
}
