// eslint-disable-next-line no-restricted-imports
import { nanoid } from '@reduxjs/toolkit'
import { useWallet } from '@solana/wallet-adapter-react'
import CollectionLogo from 'components/CollectionLogo'
import { AutoRow, RowBetween } from 'components/Row'
import useTheme from 'hooks/useTheme'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { Button } from 'rebass'
import { useCreateFirebaseCollection, useEditCollectionMint } from 'state/collections/firebase'
import { useCollectionsByUserID } from 'state/collections/hooks'
import { Collection, CollectionType } from 'state/collections/models'
import { useSafeMint } from 'state/mints/hooks'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { ReactComponent as Arrow } from '../../assets/svg/niceNavArrow.svg'
import { ReactComponent as Plus } from '../../assets/svg/plus.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useModalOpen, useToggleModal, useWalletModalToggle } from '../../state/application/hooks'
import { ExternalLink, IconWrapper, TYPE } from '../../theme'
import { ButtonPrimary } from '../Button'

export enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 38px;
  background-color: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg0};

  padding: 0.15rem 0.5rem;
  border-radius: 12px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    border: 1px solid ${({ theme }) => theme.bg3};
  }

  svg {
    margin-top: 2px;
  }
`

const UNIbutton = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
  border: none;
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span<{ flyoutAlignment?: FlyoutAlignment }>`
  min-width: 250px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 3rem;
  z-index: 100;

  ${({ flyoutAlignment = FlyoutAlignment.RIGHT }) =>
    flyoutAlignment === FlyoutAlignment.RIGHT
      ? css`
          right: 0rem;
        `
      : css`
          left: 0rem;
        `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: unset;
    right: 0;
    left: unset;
  `};
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const InternalMenuItem = styled(Link)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const ButtonMenuItem = styled(Button)`
  display: flex;
  // flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text3};
  background-color: ${({ theme }) => theme.transparent};
  :hover {
    color: ${({ theme }) => theme.text2};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    // margin-right: 8px;
  }
`

const InternalLinkMenuItem = styled(InternalMenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

export const Break = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
  width: 100%;
`

interface MintMenuProps {
  flyoutAlignment?: FlyoutAlignment
  ToggleUI?: React.FunctionComponent
  mintAddress: string
  setButtonIsHovered?: (isHovered: boolean) => void
}

const MintMenuFlyout = styled(MenuFlyout)`
  top: 20px !important;
`
const MintMenuItem = styled(ButtonMenuItem)`
  // width: max-content;
  text-decoration: none;
`

const ExternalMenuItem = styled(MenuItem)`
  width: max-content;
  text-decoration: none;
`

const StyledChevron = styled(Arrow)<{ disabled: boolean; left?: boolean }>`
  // margin: 0 0rem 0 0rem;
  // height: 35%;

  path {
    stroke: ${({ disabled, theme }) => (disabled ? theme.text4 : theme.text3)};
    stroke-width: ${({ disabled }) => (disabled ? '3px' : '3px')}};
  }
  // if left flip horizontally
  ${({ left }) => left && 'transform: scaleX(-1)'}
`

const StyledPlus = styled(Plus)`
  // margin: 0 0.25rem 0 0.35rem;
  // height: 35%;

  path {
    stroke: ${({ theme }) => theme.text2};
    stroke-width: 3px;
  }
`
const MenuText = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text3};
  background-color: ${({ theme }) => theme.transparent};
  :hover {
    color: ${({ theme }) => theme.text2};
    cursor: pointer;
    text-decoration: none;
  }
`

export const MintMenu = ({
  flyoutAlignment = FlyoutAlignment.RIGHT,
  ToggleUI,
  // menuItems,
  mintAddress,
  setButtonIsHovered,
  ...rest
}: MintMenuProps) => {
  const theme = useTheme()
  const mint = useSafeMint(mintAddress)
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(mintAddress)
  const toggle = useToggleModal(mintAddress)
  useOnClickOutside(node, open ? toggle : undefined)
  const ToggleElement = ToggleUI || StyledMenuIcon

  const toggleWalletModal = useWalletModalToggle()

  const [mouseInAddToCollectionButton, setMouseInAddToCollectionButton] = useState(false)
  const [mouseInAddToCollectionFlyout, setMouseInAddToCollectionFlyout] = useState(false)

  const wallet = useWallet()
  const userPubkey = wallet?.publicKey
  const userCollections = useCollectionsByUserID(userPubkey?.toBase58())

  const createFirebaseCollection = useCreateFirebaseCollection()
  const editCollectionMint = useEditCollectionMint()

  const handleCreateCollection = async () => {
    if (!mint) return
    if (!userPubkey) {
      toggleWalletModal()
      return
    }
    const collectionData: Collection = {
      id: nanoid(),
      type: CollectionType.Curated,
      user: userPubkey.toBase58(),
      mints: [mint.address],
      name: 'New Collection',
      description: '',
      discord: '',
      twitter: '',
      website: '',
      createdDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      candyMachine: '',
      onChainCollectionKey: '',
    }
    const requestID = await createFirebaseCollection(collectionData)
    console.log('createCollection RequestID', requestID)
    // setCreateCollectionRequestID(requestID)
  }

  const handleAddToCollection = async (collectionID: string) => {
    if (!mint) return
    if (!userPubkey) {
      toggleWalletModal()
      return
    }
    const collection = userCollections.find((c) => c.id == collectionID)
    if (!collection) return
    const requestID = await editCollectionMint(collection, mint.address, true)
    console.log('createCollection RequestID', requestID)
    if (requestID) {
      toggle()
    }
  }

  const collectionRows = () => {
    return userCollections?.map((collection) => {
      return (
        <ButtonMenuItem
          onClick={() => handleAddToCollection(collection.id)}
          key={collection.id}
          onMouseEnter={() => {
            console.log('AddToCollectionMenu mouse enter')
            setMouseInAddToCollectionButton(true)
          }}
          onMouseLeave={() => {
            console.log('AddToCollectionMenu mouse leave')
            setMouseInAddToCollectionButton(false)
          }}
        >
          <AutoRow gap="5px">
            <CollectionLogo collectionID={collection.id}></CollectionLogo>
            <MenuText color={theme.text3}>{collection.name}</MenuText>
          </AutoRow>
        </ButtonMenuItem>
      )
    })
  }

  return (
    <StyledMenu
      ref={node as any}
      {...rest}
      onMouseEnter={() => {
        if (setButtonIsHovered) {
          setButtonIsHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (setButtonIsHovered) {
          setButtonIsHovered(false)
        }
      }}
    >
      <ToggleElement onClick={toggle} />
      {open && (
        <MintMenuFlyout flyoutAlignment={flyoutAlignment}>
          <ButtonMenuItem
            onMouseEnter={() => {
              console.log('AddToCollectionMenu mouse enter')
              setMouseInAddToCollectionButton(true)
            }}
            onMouseLeave={() => {
              console.log('AddToCollectionMenu mouse leave')
              setMouseInAddToCollectionButton(false)
            }}
          >
            <RowBetween width={'100%'}>
              <TYPE.subHeader textAlign={'left'} fontSize={'18px'} color={theme.text3}>
                Add To Collection
              </TYPE.subHeader>
              <IconWrapper size={'16px'}>
                <StyledChevron left={true} disabled={false} />
              </IconWrapper>
            </RowBetween>
          </ButtonMenuItem>
        </MintMenuFlyout>
      )}
      {(mouseInAddToCollectionButton || mouseInAddToCollectionFlyout) && (
        <AddToCollectionFlyout
          onMouseEnter={() => {
            console.log('AddToCollectionMenu mouse enter')
            setMouseInAddToCollectionFlyout(true)
          }}
          onMouseLeave={() => {
            console.log('AddToCollectionMenu mouse leave')
            setMouseInAddToCollectionFlyout(false)
          }}
          flyoutAlignment={FlyoutAlignment.LEFT}
        >
          <ButtonMenuItem
            onClick={() => {
              handleCreateCollection()
            }}
            onMouseEnter={() => {
              console.log('AddToCollectionMenu mouse enter')
              setMouseInAddToCollectionButton(true)
            }}
            onMouseLeave={() => {
              console.log('AddToCollectionMenu mouse leave')
              setMouseInAddToCollectionButton(false)
            }}
          >
            <AutoRow
              gap="5px"
              style={{
                // borderBottom: `1px solid ${theme.text3}`,
                padding: '0px',
                paddingBottom: '3px',
                width: 'fit-content',
              }}
            >
              <IconWrapper size={'16px'}>
                <StyledPlus />
              </IconWrapper>
              <TYPE.subHeader textAlign={'left'} fontSize={'18px'} color={theme.text3}>
                New Collection
              </TYPE.subHeader>
            </AutoRow>
          </ButtonMenuItem>
          <Break />
          {collectionRows()}
        </AddToCollectionFlyout>
      )}
    </StyledMenu>
  )
}

const AddToCollectionFlyout = styled(MenuFlyout)`
  top: 20px !important;
  left: 15px !important;
  // min-width: 280px;

  // height: 100px !important;
  // width: 100px !important;
  // z-index: 100;
`
