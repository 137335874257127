// eslint-disable-next-line no-restricted-imports
import { MoonIcon, SunIcon } from '@heroicons/react/solid'
import { Trans } from '@lingui/macro'
import { PrivacyPolicyModal } from 'components/PrivacyPolicy'
import { LOCALE_LABEL, SupportedLocale } from 'constants/locales'
import { useLocationLinkProps } from 'hooks/useLocationLinkProps'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect, useRef, useState } from 'react'
import { ArrowDownCircle, Check, ChevronDown } from 'react-feather'
import { Link } from 'react-router-dom'
import { useModalOpen, useToggleModal } from 'state/application/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { css } from 'styled-components/macro'
import { ExternalLink, IconWrapper, TYPE } from 'theme'

// import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import { FilterFlyout, FlyoutAlignment } from '../styled'

const ActiveRowWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 0 8px 0;
  width: 100%;
`
const FlyoutRow = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active, theme }) => (active ? theme.bg2 : 'transparent')};
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 12px;
  text-align: left;
  width: 100%;
`

const StyledOptionBubble = styled.div<{ active: boolean }>`
  align-items: center;
  // background-color: ${({ active, theme }) => (active ? theme.bg2 : 'transparent')};
  border: 1px solid ${({ active, theme }) => (active ? theme.bg0 : theme.bg2)};
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 12px;
  text-align: left;
  width: 100%;
`

const FlyoutRowActiveIndicator = styled.div`
  background-color: ${({ theme }) => theme.green1};
  border-radius: 50%;
  height: 9px;
  width: 9px;
`
const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`
const NetworkLabel = styled.div`
  flex: 1 1 auto;
`
const OptionRowList = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`
const OptionBubbleList = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 3px;
`

// function OptionBubble({ text, handleClick, active }: { text: string; handleClick: () => void; active: boolean }) {
//   const RowContent = () => (
//     <StyledOptionBubble onClick={handleClick} active={active}>
//       <NetworkLabel>{text}</NetworkLabel>
//       {active && <FlyoutRowActiveIndicator />}
//     </StyledOptionBubble>
//   )
//   return <RowContent />
// }
function OptionRow({ text, handleClick, active }: { text: string; handleClick: () => void; active: boolean }) {
  const RowContent = () => (
    <FlyoutRow onClick={handleClick} active={active}>
      <NetworkLabel>{text}</NetworkLabel>
      {active && <FlyoutRowActiveIndicator />}
    </FlyoutRow>
  )
  return <RowContent />
}

export default function MintsPriceFlyout({ flyoutAlignment }: { flyoutAlignment?: FlyoutAlignment }) {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen('MintsCollectionFilter')
  const toggleMenu = useToggleModal('MintsCollectionFilter')
  useOnClickOutside(node, open ? toggleMenu : undefined)

  const theme = useTheme()

  return (
    <FilterFlyout>
      {/* <OptionBubbleList>
        <OptionBubble text="Mint" handleClick={() => toggleMenu()} active={false} />
      </OptionBubbleList> */}
      <TYPE.heavy fontSize={14} color={theme.text2}>
        Collection type
      </TYPE.heavy>
      <OptionRowList>
        {/* <OptionRow text="Any" handleClick={toggleMenu} active={true} /> */}
        <OptionRow text="Following" handleClick={toggleMenu} active={true} />
        <OptionRow text="Verified" handleClick={toggleMenu} active={true} />
      </OptionRowList>
    </FilterFlyout>
  )
}
