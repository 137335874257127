import { pubkeyFromString } from 'apollo/utils'
import { useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useCreateFirebaseCollection, useFetchFirebaseCollection, useFetchFirebaseCollectionsQuery } from './firebase'
import {
  useAllCollections,
  useAllCollectionsFirebaseRequests,
  useAllFirebaseCollectionQueryRequests,
  useRequiredCollections,
} from './hooks'
import { RequestStatus } from './reducer'

export default function Updater(): null {
  const dispatch = useAppDispatch()

  const requiredCollections = useRequiredCollections()
  const allCollections = useAllCollections()
  const allFirebaseRequests = useAllCollectionsFirebaseRequests()

  const fetchFirebaseCollection = useFetchFirebaseCollection()
  const updateFirebaseCollection = useCreateFirebaseCollection()

  useEffect(() => {
    console.log('requiredCollections requiredCollections: ', requiredCollections)
    requiredCollections.forEach((collectionID) => {
      const firebaseCollection = allCollections.find((c) => c.id == collectionID)
      const firebaseFetchRequest = allFirebaseRequests.find(
        (r) => r.context == collectionID && r.type == 'fetch' && r.status != RequestStatus.Fulfilled
      )
      console.log('requiredCollections collectionID: ', collectionID)
      console.log('requiredCollections firebaseCollection: ', firebaseCollection)
      console.log('requiredCollections firebaseFetchRequest: ', firebaseFetchRequest)

      if (!firebaseCollection && !firebaseFetchRequest) {
        console.log('requiredCollections request1')
        fetchFirebaseCollection(collectionID)
        return
      }
    })
  }, [
    allFirebaseRequests,
    // updateFirebaseCollection,
    dispatch,
    requiredCollections,
    allCollections,
    fetchFirebaseCollection,
  ])

  const fetchFirebaseCollectionsQuery = useFetchFirebaseCollectionsQuery()
  const allFirebaseCollectionQueryRequests = useAllFirebaseCollectionQueryRequests()

  useEffect(() => {
    // if (requiredCollections.length == 0) {
    console.log('requiredCollections requiredCollections.length == 0')
    const fetchAllQueryRequest = allFirebaseCollectionQueryRequests.find((r) => r.type == 'fetch' && r.context == 'all')
    if (!fetchAllQueryRequest) {
      console.log('requiredCollections request2')
      fetchFirebaseCollectionsQuery('all', [])
    }
    // }
  }, [allFirebaseCollectionQueryRequests, allFirebaseRequests, fetchFirebaseCollectionsQuery])

  return null
}
