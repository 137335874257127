import useTheme from 'hooks/useTheme'
import { PageWrapper } from 'pages/styled'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'

const Center = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
export default function RewardsPage() {
  const theme = useTheme()
  return (
    <>
      <Center>
        <TYPE.heavy fontSize={'30rem'}>?</TYPE.heavy>
      </Center>
    </>
  )
}
