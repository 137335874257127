import { pubkeyFromString } from 'apollo/utils'
import { SupportedChainId } from 'constants/chains'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useFetchListCallback } from '../../hooks/useFetchListCallback'
import useInterval from '../../hooks/useInterval'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { useActiveWeb3React } from '../../hooks/web3'
import { useFetchFirebaseOrder, useFetchFirebaseOrdersQuery, useUpdateFirebaseOrder } from './firebase'
import {
  useAllChainRequests,
  useAllFirebaseOrders,
  useAllFirebaseRequests,
  useAllOrderQueries,
  useAllOrderQueryRequests,
  useRequiredOrderQueries,
  useRequiredOrders,
} from './hooks'
import { Order } from './models'
import { ChainOrderStatus, RequestStatus } from './reducer'

const shouldRefreshFirebaseOrder = (chainOrderStatus: ChainOrderStatus, firebaseOrder: Order) => {
  return chainOrderStatus.found && chainOrderStatus.revoked == firebaseOrder.revoked
}

export default function Updater(): null {
  const dispatch = useAppDispatch()

  // const requiredOrders = useRequiredOrders()
  // const allFirebaseOrders = useAllFirebaseOrders()
  // const allChainOrderRequests = useAllChainRequests()
  // const allFirebaseRequests = useAllFirebaseRequests()

  // useEffect(() => {
  //   console.log('requiredOrders requiredOrders: ', requiredOrders)
  //   requiredOrders.forEach((orderPDA) => {
  //     const pubkey = pubkeyFromString(orderPDA)
  //     if (!pubkey) return
  //     console.log('requiredOrders allFirebaseOrders: ', allFirebaseOrders)
  //     const firebaseOrder = allFirebaseOrders.find((m) => m.delegatePDA == orderPDA)
  //     const firebaseFetchRequest = allFirebaseRequests.find(
  //       (r) => r.context == pubkey.toBase58() && r.type == 'fetch' && r.status != RequestStatus.Fulfilled
  //     )
  //     const firebaseCreateRequest = allFirebaseRequests.find(
  //       (r) => r.context == pubkey.toBase58() && r.type == 'update' && r.status != RequestStatus.Fulfilled
  //     )
  //     console.log('requiredOrders orderPDA: ', orderPDA)
  //     console.log('requiredOrders pubkey: ', pubkey)
  //     console.log('requiredOrders firebaseOrder: ', firebaseOrder)
  //     console.log('requiredOrders firebaseFetchRequest: ', firebaseFetchRequest)
  //     console.log('requiredOrders firebaseCreateRequest: ', firebaseCreateRequest)
  //   })
  // }, [allChainOrderRequests, allFirebaseOrders, allFirebaseRequests, dispatch, requiredOrders])

  const requiredOrderQueries = useRequiredOrderQueries()
  // const orderQueries = useAllOrderQueries()
  const orderQueryRequests = useAllOrderQueryRequests()
  const orderQueries = useAllOrderQueries()
  const fetchOrderQuery = useFetchFirebaseOrdersQuery()

  useEffect(() => {
    console.log('requiredOrderQueries: ', requiredOrderQueries)
    requiredOrderQueries.forEach((roq) => {
      const query = orderQueries.find((m) => m.queryKey == roq.key)
      const queryRequest = orderQueryRequests.find(
        (r) => r.context == roq.key && r.type == 'fetch' && r.status != RequestStatus.Fulfilled
      )
      if (!query && !queryRequest) {
        fetchOrderQuery(roq)
      }
    })
  }, [dispatch, fetchOrderQuery, orderQueries, orderQueryRequests, requiredOrderQueries])

  return null
}
