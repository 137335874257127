import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { BaseButton, ButtonCreate, ButtonSmall, SavedIcon } from 'components/Button'
import CollectionLogo from 'components/CollectionLogo'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import MintArtDisplay from 'components/MintArtDisplay'
import { MintMenu } from 'components/NiceMenu'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import UserLogo from 'components/UserLogo'
import { useListColor } from 'hooks/useColor'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useCreateOrderModalToggle, useWalletModalToggle } from 'state/application/hooks'
import { addRequiredCollections } from 'state/collections/actions'
import { useCollection, useCollections } from 'state/collections/hooks'
import { CollectionType } from 'state/collections/models'
import { resetToSellOrder } from 'state/createOrder/actions'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import { addRequiredOrderQueries } from 'state/orders/actions'
import { ORDER_SIDE } from 'state/orders/chain'
import { useOrdersByAssetMintAndSide } from 'state/orders/hooks'
import { RequiredOrderQuery } from 'state/orders/reducer'
import { useEditSavedMint } from 'state/users/firebase'
import { useMintIsSaved, useSafeUser } from 'state/users/hooks'
import styled, { keyframes } from 'styled-components/macro'
import { TYPE } from 'theme'
import { truncateString } from 'utils'
import { mintIsNative } from 'utils/utils'

import { ReactComponent as Ellipsis } from '../../assets/svg/ellipsis.svg'

const MintCardContainer = styled(Link)`
  padding: 0.25rem 0.2rem 0.5rem;
  width: 100%;
  border-radius: 0.7rem;

  // height: 100%;
  align-items: center;
  text-align: left;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  background-color: ${({ theme }) => transparentize(1.0, theme.bg0)};
  background-opacity: 0.1;
  &:focus {
    background-color: ${({ theme }) => transparentize(0.6, theme.bg0)};
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.6, theme.bg0)};
  }

  overflow: visible;
`

const AskCardRow = styled.div`
  color: ${({ theme }) => theme.secondary2} !important;
  display: grid;
  grid-template-columns: 30px 20px;
  justify-content: end;
`

const DetailsSection = styled(AutoColumn)`
  padding: 15px 17px 0px 17px;
  width: 100%;
`

const ActivityRow = styled.div`
  // padding: 0px 8px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.7rem;
  background-color: ${({ theme }) => theme.transparent};
  width: 100%;
`
const SellButton = styled(ButtonCreate)`
  padding: 15px; 17px; 5px; 17px;
  color: ${({ theme }) => theme.text2};
  width: 60px;
`

const MintDisplayContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // overflow: auto;
  // width: 100%;
  // height: 100%;
`

const ArtDisplayPillsRow = styled(RowBetween)`
  position: absolute;
  bottom: -14px;
  // left: 8px;
  // width: 100%;
  padding: 0px 8px;
`

const ArtDisplayPill = styled(BaseButton)`
  padding: 4px; 6px;
  display: flex;
  // gap: 10px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  // height: 28px;
  // border-radius: 14px;
  background-color: ${({ theme }) => theme.bg0};
`

const animatedgradient = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`

const StyledEllipis = styled(Ellipsis)`
  // margin: 0 0.25rem 0 0.35rem;
  // height: 35%;

  // path {ii
  //   // stroke: ${({ theme }) => theme.text2};
  //   stroke-width: 3px;
  // }
`

const UserCollectionsButton = styled(ButtonSmall)`
  color: ${({ theme }) => theme.blue1};
  background-color: ${({ theme }) => theme.bg0};
  // margin-right: 10px;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:hover {
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:active {
    // box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
`

const CollectionRowGrid = styled.div`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: center;
  // align-content: space-between;
  grid-template-columns: 15px 250px 75px 150px 110px 60px 50px;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`
function LabelWithContent({ label, children }: React.PropsWithChildren<{ label: string }>) {
  return (
    <AutoColumn style={{ justifyContent: 'end' }}>
      <AutoRow width={'100%'}>
        <TYPE.heavy width={'100%'} textAlign="right" color={'text3'} fontSize={'12px'}>
          {label}
        </TYPE.heavy>
      </AutoRow>

      {children}
    </AutoColumn>
  )
}

export default function CollectionCard({ collectionID, i }: { collectionID?: string; i?: number }) {
  const [buttonIsHovered, setButtonIsHovered] = React.useState(false)
  const [mintCardIsHovered, setMintCardIsHovered] = React.useState(false)
  const { tokenRegistry } = useTokenRegistry()

  const wallet = useAnchorWallet()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const userPubkey = wallet?.publicKey

  const collection = useCollection(collectionID)

  return (
    <MintCardContainer
      to={buttonIsHovered ? history.location : `/c/${collection?.id}`}
      key={`${collection}`}
      onMouseEnter={() => {
        setMintCardIsHovered(true)
      }}
      onMouseLeave={() => {
        if (!buttonIsHovered) {
          setMintCardIsHovered(false)
        }
      }}
    >
      {collection ? (
        <>
          <CollectionRowGrid key={collection.id}>
            {i && (
              <TYPE.label fontSize={'18px'} color={theme.text2}>
                {i}
              </TYPE.label>
            )}
            <RowFixed>
              <CollectionLogo collectionID={collection.id} size={'55px'}></CollectionLogo>
              <AutoColumn>
                {collection.type == CollectionType.Curated && (
                  <ArtDisplayPill>
                    <TYPE.body fontSize={'12px'} color={theme.text2}>
                      User Curated Collection
                    </TYPE.body>
                  </ArtDisplayPill>
                )}
                <TYPE.heavy style={{ marginLeft: '8px' }} fontSize={'24px'} color={theme.text2}>
                  {collection.name}
                </TYPE.heavy>
              </AutoColumn>
            </RowFixed>
          </CollectionRowGrid>
        </>
      ) : (
        <LoadingOpacityContainer style={{ minHeight: '400px' }} $loading={true}></LoadingOpacityContainer>
      )}
    </MintCardContainer>
  )
}
