import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { useSafeSales } from 'state/sales/hooks'

import { AppState } from '../index'
import { decodeFirebaseMintData } from './firebase'
import { MintData } from './models'
import { Request } from './reducer'

export function useRequiredMints(): string[] {
  console.log('aState useRequiredMints')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredMints useAppSelector state: ', state)
    return state.mints.requiredMints
  })
}

export function useAllFirebaseMints(): MintData[] {
  console.log('aState useAllFirebaseMints')
  return useAppSelector((state: AppState) => {
    // console.log('aState useHomepageMints useAppSelector state: ', state)
    return state.mints.firebaseMints
  })
}

export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.mints.firebaseRequests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.mints.firebaseRequests.filter((r) => r.context === context)
  })
}

export function useFirebaseMint(mintAddress?: string): MintData | undefined {
  const allMints = useAllFirebaseMints()
  const mint = useMemo(() => {
    return allMints.find((mint) => mint.address === mintAddress)
  }, [allMints, mintAddress])
  return mint
}

export function useAllChainMints(): MintData[] {
  // console.log('aState useAllChainMints')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllChainMints useAppSelector state: ', state)
    return state.mints.chainMints
  })
}

export function useAllChainRequests(): Request[] {
  console.log('aState useChainRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useChainRequests useAppSelector state: ', state)
    return state.mints.onChainRequests
  })
}

export function useChainRequestsByContext(context?: string): Request[] {
  console.log('aState useChainRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useChainRequests useAppSelector state: ', state)
    return state.mints.onChainRequests.filter((r) => r.context === context)
  })
}

export function useChainMint(mintAddress?: string): MintData | undefined {
  const allMints = useAllChainMints()
  const mint = useMemo(() => {
    return allMints.find((mint) => mint.address === mintAddress)
  }, [allMints, mintAddress])
  return mint
}

export function useSafeMint(mintAddress?: string): MintData | undefined {
  const firebaseMint = useFirebaseMint(mintAddress)
  // const chainMint = useChainMint(mintAddress)

  return useMemo(() => {
    console.log('aState useSafeMint useMemo firebaseMint: ', firebaseMint)
    // console.log('aState useSafeMint useMemo chainMint: ', chainMint)
    return firebaseMint ?? undefined
  }, [firebaseMint])
}

export function useSafeMints(mintAddresses?: string[]): MintData[] {
  const firebaseMints = useAllFirebaseMints()
  // const chainMints = useAllChainMints()

  return useMemo(() => {
    console.log('aState useSafeMints useMemo firebaseMints: ', firebaseMints)
    // console.log('aState useSafeMints useMemo chainMints: ', chainMints)
    const mints: MintData[] = []
    mintAddresses?.forEach((mintAddress) => {
      const firebaseMint = firebaseMints.find((mint) => mint.address === mintAddress)
      // const chainMint = chainMints.find((mint) => mint.address === mintAddress)
      if (firebaseMint) {
        mints.push(firebaseMint)
      }
      // else if (chainMint) {
      //   mints.push(chainMint)
      // }
    })
    return mints
  }, [firebaseMints, mintAddresses])
}
