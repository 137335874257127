import { Token } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'
import Vibrant from 'node-vibrant/lib/bundle'
import { shade } from 'polished'
import { useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setGlobalColor } from 'state/application/reducer'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import uriToHttp from 'utils/uriToHttp'
import { hex } from 'wcag-contrast'

function URIForEthToken(address: string) {
  return `https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/${address}/logo.png`
}

async function getColorFromToken(token: Token): Promise<string | null> {
  if (!(token instanceof WrappedTokenInfo)) {
    return null
  }

  const wrappedToken = token as WrappedTokenInfo
  const { address } = wrappedToken
  let { logoURI } = wrappedToken
  if (!logoURI) {
    if (token.chainId !== SupportedChainId.MAINNET) {
      return null
    } else {
      logoURI = URIForEthToken(address)
    }
  }

  try {
    return await getColorFromUriPath(logoURI)
  } catch (e) {
    if (logoURI === URIForEthToken(address)) {
      return null
    }

    try {
      logoURI = URIForEthToken(address)
      return await getColorFromUriPath(logoURI)
    } catch (e) {}
  }

  return null
}

async function getColorFromUriPath(uri: string): Promise<string | null> {
  try {
    const formattedPath = uriToHttp(uri)[0]

    const palette = await Vibrant.from(formattedPath).getPalette()
    if (!palette?.Vibrant) {
      return null
    }

    let detectedHex = palette.Vibrant.hex
    let AAscore = hex(detectedHex, '#FFF')
    while (AAscore < 3) {
      detectedHex = shade(0.005, detectedHex)
      AAscore = hex(detectedHex, '#FFF')
    }
    return detectedHex
  } catch (e) {
    return null
  }
}

export function useColor(token?: Token) {
  const [color, setColor] = useState('#2172E5')

  useLayoutEffect(() => {
    let stale = false

    if (token) {
      getColorFromToken(token).then((tokenColor) => {
        if (!stale && tokenColor !== null) {
          setColor(tokenColor)
        }
      })
    }

    return () => {
      stale = true
      setColor('#2172E5')
    }
  }, [token])

  return color
}

const hue = (p: number, q: number, t: number) => {
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6

  return p
}

const hslRgb = (h: number, s: number, l: number) => {
  let r, g, b
  h = h / 360

  if (s == 0) {
    r = g = b = l
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q

    r = hue(p, q, h + 1 / 3)
    g = hue(p, q, h)
    b = hue(p, q, h - 1 / 3)
  }

  return [
    Math.max(0, Math.min(Math.round(r * 255), 255)),
    Math.max(0, Math.min(Math.round(g * 255), 255)),
    Math.max(0, Math.min(Math.round(b * 255), 255)),
  ]
}

const uniqueID = () => Math.floor(Math.random() * Date.now())

const hslTriad = (h: number, s: number, l: number) => {
  return [
    // [h, s, l],
    [(h + 10) % 360, s, l],
    [(h + 180) % 360, s, l],
    [(h + 10) % 360, s, l],
  ]
}

const stringHash = (str: string) => {
  let hash = 5381
  let i = str.length

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i)
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0
}

const avatar = (str: string, size: string) => {
  const hash = stringHash(str)
  console.log('avatar hash', hash)
  const colors = hslTriad(hash % 360, 1, 0.5)
  const color1 = hslRgb(colors[0][0], colors[0][1], colors[0][2])
  const color2 = hslRgb(colors[1][0], colors[1][1], colors[1][2])
  const color1str = `rgb(${color1[0]}, ${color1[1]}, ${color1[2]})`
  const color2str = `rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`
  const id = uniqueID()

  return `<?xml version="1.0" encoding="UTF-8"?>
<svg ${
    size != undefined ? `width="${size}" height="${size}"` : ''
  } viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="${id}">
      <stop stop-color="${color1str}" offset="0%"></stop>
      <stop stop-color="${color2str}" offset="100%"></stop>
    </linearGradient>
  </defs>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" fill="url(#${id})" x="0" y="0" width="80" height="80"></rect>
  </g>
</svg>`
}

export function useAvatarSVG(userAddress?: string, size?: string) {
  const [svg, setSVG] = useState('')

  useLayoutEffect(() => {
    let stale = false

    if (userAddress) {
      const svgStr = avatar(userAddress, size ?? '24px')
      if (!stale && svgStr !== null) {
        setSVG(svgStr)
      }
    }

    return () => {
      stale = true
      setSVG('#2172E5')
    }
  }, [size, userAddress])

  return svg
}

export function useListColor(listImageUri?: string) {
  const [color, setColor] = useState('#2172E5')
  useLayoutEffect(() => {
    let stale = false

    if (listImageUri) {
      getColorFromUriPath(listImageUri).then((color) => {
        if (!stale && color !== null) {
          setColor(color)
        }
      })
    }

    return () => {
      stale = true
      // setColor('#2172E5')
    }
  }, [listImageUri])

  return color
}

export function useCollectionColor(listImageUri?: string) {
  const [color, setColor] = useState('#2172E5')

  useLayoutEffect(() => {
    let stale = false

    // if (listImageUri) {
    //   getColorFromUriPath(listImageUri).then((color) => {
    //     if (!stale && color !== null) {
    //       setColor(color)
    //     }
    //   })
    // }

    return () => {
      stale = true
      setColor('#2172E5')
    }
  }, [listImageUri])

  return color
}
