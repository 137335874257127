import useScrollPosition from '@react-hook/window-scroll'
import { nanoid } from '@reduxjs/toolkit'
import { useWallet } from '@solana/wallet-adapter-react'
import { ButtonSecondary, ButtonSmallText } from 'components/Button'
import { HeaderCard } from 'components/Card'
import CollectionLogo from 'components/CollectionLogo'
import { AutoColumn } from 'components/Column'
import { ConnectButton } from 'components/ConnectButton'
import ExplorerLink from 'components/ExplorerLink'
import { StyledNavLink } from 'components/Header'
import FilterDropdown from 'components/SalesFilter/FilterDropdown'
import Search from 'components/Search'
import { useApolloProgram } from 'components/SolanaManager'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import { PageWrapper } from 'pages/styled'
import { darken, transparentize } from 'polished'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useErrorModalToggle, useSetErrorModalData, useWalletModalToggle } from 'state/application/hooks'
import { setErrorModalData } from 'state/application/reducer'
import { useCreateFirebaseCollection } from 'state/collections/firebase'
import {
  useCollection,
  useCollectionsByUserID,
  useCollectionsFirebaseRequestByRequestID,
} from 'state/collections/hooks'
import { Collection } from 'state/collections/models'
import { RequestStatus } from 'state/collections/reducer'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ExternalLinkIcon, TYPE } from 'theme'
import { shortenAddress, truncateString } from 'utils'
import { solExplorerAddressURL } from 'utils/getExplorerLink'

const activeClassName = 'ACTIVE'

const GoToButton = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  font-size: 18px;
  font-weight: 600;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // word-break: break-word;
  // overflow: hidden;
  // white-space: nowrap;
  border-radius: 66px;
  &.${activeClassName} {
    font-weight: 500;
    justify-content: center;
    color: ${({ theme }) => theme.primary5};
    background-color: ${({ theme }) => transparentize(0.8, theme.headerBG)};
  }
  background-color: ${({ theme }) => transparentize(0.2, theme.headerBG)};
  mix-blend-mode: overlay;
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.3, theme.text1)};
  }
`
const GoToExploreButton = styled(GoToButton).attrs({
  activeClassName,
})`
  background ${({ theme }) => theme.transparent};
  border: 2px solid ${({ theme }) => theme.blue1};
  color: ${({ theme }) => theme.text2};

  background-size: 600% 600%;
  animation: GradientBackground 10s ease infinite;
  @keyframes GradientBackground {
    0% {
      color: ${({ theme }) => theme.text5};
      background-position: 0% 50%;
    }
    50% {
      color: ${({ theme }) => theme.text1};
      background-position: 100% 50%;
    }
    100% {
      color: ${({ theme }) => theme.text5};
      background-position: 0% 50%;
    }
  } ;
`
export default function HomePage() {
  return (
    <>
      <PageWrapper>
        <GoToExploreButton
          id={`sell-nav-link`}
          to={`/explore`}
          activeClassName={activeClassName}
          isActive={(match, { pathname }) => pathname.startsWith(`/explore`)}
        >
          Explore
        </GoToExploreButton>
      </PageWrapper>
    </>
  )
}
