import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { SaleData } from 'validators/accounts/sales'

import { AppState } from '../index'
import { Request } from './reducer'

export function useRequiredSales(): string[] {
  console.log('aState useRequiredSales')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredSales useAppSelector state: ', state)
    return state.sales.requiredSales
  })
}

export function useAllFirebaseSales(): SaleData[] {
  console.log('aState useHomepageSales')
  return useAppSelector((state: AppState) => {
    // console.log('aState useHomepageSales useAppSelector state: ', state)
    return state.sales.firebaseSales
  })
}

export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.sales.firebaseRequests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.sales.firebaseRequests.filter((r) => r.context === context)
  })
}

export function useFirebaseSale(saleAddress?: string): SaleData | undefined {
  const allSales = useAllFirebaseSales()
  const sale = useMemo(() => {
    return allSales.find((sale) => sale.pubkey === saleAddress)
  }, [allSales, saleAddress])
  return sale
}

export function useAllChainSales(): SaleData[] {
  // console.log('aState useAllChainSales')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllChainSales useAppSelector state: ', state)
    return state.sales.chainSales
  })
}

export function useAllChainRequests(): Request[] {
  console.log('aState useChainRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useChainRequests useAppSelector state: ', state)
    return state.sales.onChainRequests
  })
}

export function useChainRequestsByContext(context?: string): Request[] {
  console.log('aState useChainRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useChainRequests useAppSelector state: ', state)
    return state.sales.onChainRequests.filter((r) => r.context === context)
  })
}

export function useChainSale(saleAddress?: string): SaleData | undefined {
  const allSales = useAllChainSales()
  const sale = useMemo(() => {
    return allSales.find((sale) => sale.pubkey === saleAddress)
  }, [allSales, saleAddress])
  return sale
}

export function useSafeSale(saleAddress?: string): SaleData | undefined {
  const firebaseSale = useFirebaseSale(saleAddress)
  const chainSale = useChainSale(saleAddress)

  return useMemo(() => {
    console.log('aState useSafeSale useMemo firebaseSale: ', firebaseSale)
    console.log('aState useSafeSale useMemo chainSale: ', chainSale)
    return firebaseSale ?? chainSale ?? undefined
  }, [chainSale, firebaseSale])
}

export function useSafeSales(saleAddresses?: string[]): SaleData[] {
  const firebaseSales = useAllFirebaseSales()
  const chainSales = useAllChainSales()

  return useMemo(() => {
    console.log('aState useSafeSales useMemo firebaseSales: ', firebaseSales)
    console.log('aState useSafeSales useMemo chainSales: ', chainSales)
    const sales: SaleData[] = []
    saleAddresses?.forEach((saleAddress) => {
      const firebaseSale = firebaseSales.find((sale) => sale.pubkey === saleAddress)
      const chainSale = chainSales.find((sale) => sale.pubkey === saleAddress)
      if (firebaseSale) {
        sales.push(firebaseSale)
      } else if (chainSale) {
        sales.push(chainSale)
      }
    })
    return sales
  }, [chainSales, firebaseSales, saleAddresses])
}
