import { array, boolean, Infer, nullable, number, optional, string, type } from 'superstruct'
import { MetadataCombined, StringFromAny } from 'validators/accounts/sales'

export enum CollectionType {
  Curated = 'curated',
  Artist = 'artist',
  CandyMachine = 'candy_machine',
  OnChain = 'candy_machine',
  ArtistVerified = 'artist_verified',
}

export type Collection = Infer<typeof Collection>
export const Collection = type({
  id: string(),
  type: string(),
  user: string(),
  mints: array(string()),
  name: string(),
  description: string(),
  discord: string(),
  website: string(),
  twitter: string(),
  createdDate: string(),
  updatedDate: string(),
  candyMachine: StringFromAny,
  onChainCollectionKey: StringFromAny,
  // optdeleted: boolean(),
})
