import { nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  QueryConstraint,
  setDoc,
  writeBatch,
} from 'firebase/firestore'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { addCollectionToMintLocal, removeCollectionFromMintLocal } from 'state/mints/actions'
import { create } from 'superstruct'

import {
  deleteFirebaseCollectionRequest,
  fetchFirebaseCollectionQueryRequest,
  fetchFirebaseCollectionRequest,
  updateFirebaseCollectionRequest,
} from './actions'
import { Collection } from './models'

export const COLLECTIONS_COLLECTION = 'collectionsA1'
export const DELETED_COLLECTIONS_COLLECTION = 'deletedCollections'

export function useFetchFirebaseCollection(): (collectionID: string) => Promise<Collection | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (collectionID: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseCollectionRequest.pending({ collectionID, requestID }))
      try {
        const db = getFirestore()
        const collectionSnap = await getDoc(doc(db, COLLECTIONS_COLLECTION, collectionID))
        console.log('firebase collections: ', collectionSnap)
        if (collectionSnap.exists()) {
          console.log('firebase Document data:', collectionSnap.data())
          const collection = decodeFirebaseCollection(collectionSnap.data())
          console.log('firebase collection decoded: ', collection)
          dispatch(fetchFirebaseCollectionRequest.fulfilled({ requestID, collection }))
          return collection
        } else {
          dispatch(
            fetchFirebaseCollectionRequest.rejected({
              collectionID,
              requestID,
              errorMessage: 'Collection does not exist',
            })
          )
          return undefined
        }
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseCollectionRequest.rejected({ collectionID, requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

export function useFetchFirebaseCollectionsQuery(): (
  queryKey: string,
  queries: QueryConstraint[]
) => Promise<string[] | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (queryKey: string, queryConstraints: QueryConstraint[]) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseCollectionQueryRequest.pending({ queryKey, requestID }))
      try {
        const db = getFirestore()
        const q = query(collection(db, COLLECTIONS_COLLECTION), ...queryConstraints)
        const querySnapshot = await getDocs(q)
        const collections = querySnapshot.docs.map((doc) => {
          return decodeFirebaseCollection(doc.data())
        })
        console.log(`firebase query collections (${queryKey}): `, collections)
        dispatch(fetchFirebaseCollectionQueryRequest.fulfilled({ requestID, queryKey, collections }))
        return collections.map((collection) => collection.id)
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseCollectionQueryRequest.rejected({ queryKey, requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

const encodeCollectionForFirebase = (collection: Collection) => {
  return collection
}

export const decodeFirebaseCollection = (res: any) => {
  return create(res, Collection)
}

export function useEditCollectionMint(): (
  collection: Collection,
  mintAddress: string,
  isInCollection: boolean
) => Promise<string> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (collection: Collection, mintAddress: string, isInCollection: boolean) => {
      const requestID = nanoid()
      dispatch(updateFirebaseCollectionRequest.pending({ collectionID: collection.id, requestID }))

      const filteredMints = collection.mints?.filter((m) => m !== mintAddress) ?? []
      try {
        const newCollection: Collection = {
          ...collection,
          mints: isInCollection ? [...filteredMints, mintAddress] : filteredMints,
        }
        const db = getFirestore()
        const collectionObj = encodeCollectionForFirebase(newCollection)
        console.log('firebase uploading collection: ', collectionObj)
        setDoc(doc(db, COLLECTIONS_COLLECTION, newCollection.id), collectionObj).then(() => {
          console.log('firebase collection uploaded')
          dispatch(updateFirebaseCollectionRequest.fulfilled({ requestID, collection: newCollection }))
          if (isInCollection) {
            dispatch(addCollectionToMintLocal({ mintAddress, collectionID: collection.id }))
          } else {
            dispatch(removeCollectionFromMintLocal({ mintAddress, collectionID: collection.id }))
          }
        })
        return requestID
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseCollectionRequest.rejected({ collectionID: collection.id, requestID, errorMessage: e }))
        return requestID
      }
    },
    [dispatch]
  )
}
export function useEditCollectionInfo(): (
  collection: Collection,
  name: string,
  description: string
) => Promise<string> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (collection: Collection, name: string, description: string) => {
      console.log('firebase useEditCollectionInfo: ', collection, name, description)
      const requestID = nanoid()
      dispatch(updateFirebaseCollectionRequest.pending({ collectionID: collection.id, requestID }))

      try {
        const newCollection: Collection = {
          ...collection,
          name,
          description,
        }
        const db = getFirestore()
        const collectionObj = encodeCollectionForFirebase(newCollection)
        console.log('firebase uploading collection: ', collectionObj)
        setDoc(doc(db, COLLECTIONS_COLLECTION, newCollection.id), collectionObj).then(() => {
          console.log('firebase collection uploaded')
          dispatch(updateFirebaseCollectionRequest.fulfilled({ requestID, collection: newCollection }))
        })
        return requestID
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseCollectionRequest.rejected({ collectionID: collection.id, requestID, errorMessage: e }))
        return requestID
      }
    },
    [dispatch]
  )
}

export function useDeleteCollection(): (collection: Collection) => Promise<string> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (collection: Collection) => {
      const requestID = nanoid()
      dispatch(deleteFirebaseCollectionRequest.pending({ collectionID: collection.id, requestID }))

      try {
        const db = getFirestore()
        const collectionObj = encodeCollectionForFirebase(collection)
        console.log('firebase uploading collection: ', collectionObj)
        const batch = writeBatch(db)
          .delete(doc(db, COLLECTIONS_COLLECTION, collection.id))
          .set(doc(db, DELETED_COLLECTIONS_COLLECTION, collection.id), collectionObj)
        batch.commit().then(() => {
          console.log('firebase collection uploaded')
          dispatch(deleteFirebaseCollectionRequest.fulfilled({ requestID, collectionID: collection.id }))
        })
        return requestID
      } catch (e) {
        console.error(e)
        dispatch(deleteFirebaseCollectionRequest.rejected({ collectionID: collection.id, requestID, errorMessage: e }))
        return requestID
      }
    },
    [dispatch]
  )
}

export function useCreateFirebaseCollection(): (collection: Collection) => Promise<string> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (collection: Collection) => {
      const requestID = nanoid()
      dispatch(updateFirebaseCollectionRequest.pending({ collectionID: collection.id, requestID }))
      try {
        const db = getFirestore()
        const collectionObj = encodeCollectionForFirebase(collection)
        console.log('firebase uploading collection: ', collectionObj)
        setDoc(doc(db, COLLECTIONS_COLLECTION, collection.id), collectionObj).then(() => {
          console.log('firebase collection uploaded')
          dispatch(updateFirebaseCollectionRequest.fulfilled({ requestID, collection }))
          collection.mints.forEach((mintAddress) => {
            dispatch(addCollectionToMintLocal({ mintAddress, collectionID: collection.id }))
          })
        })
        return requestID
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseCollectionRequest.rejected({ collectionID: collection.id, requestID, errorMessage: e }))
        return requestID
      }
    },
    [dispatch]
  )
}
