import styled from 'styled-components/macro'
import { StyledInternalLink } from 'theme'

export const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width ?? 'fit-content'};
  padding: 1px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 8px;
  border: ${({ theme }) => '1px solid ' + theme.bg2};
  cursor: pointer;
  outline: none;
`

export const ToggleElement = styled.span<{ isActive?: boolean; fontSize?: string }>`
  display: flex;
  align-items: center;
  // width: 100%;/
  padding: 6px 8px;
  border-radius: 6px;
  justify-content: start;
  height: 100%;
  background: ${({ theme, isActive }) => (isActive ? theme.bg0 : 'none')};
  color: ${({ theme, isActive }) => (isActive ? theme.text2 : theme.text3)};
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  font-weight: ${({ isActive }) => (isActive ? '700' : '500')};
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme, isActive }) => (isActive ? theme.text2 : theme.text3)};
  }
`

export const ToggleElementFree = styled.span<{ isActive?: boolean; fontSize?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 10px;
  border-radius: 12px;
  justify-content: center;
  height: 100%;
  background: ${({ theme, isActive }) => (isActive ? theme.black : 'none')};
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text2)};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  font-weight: 600;
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme, isActive }) => (isActive ? theme.text2 : theme.text3)};
  }
  margin-top: 0.5px;
`
