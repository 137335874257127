import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { SalesEmptyState } from 'components/bundles/BundlesEmptyState'
import { ButtonAction, ButtonPrimary } from 'components/Button'
import { DarkGreyCard } from 'components/Card'
import CollectionsTable from 'components/collections/ColectionsTable'
import Column, { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import SaleCard from 'components/SaleCard'
import { CollectionsList } from 'components/SalesFilter/CollectionsList'
import FilterDropdown from 'components/SalesFilter/FilterDropdown'
import HorizontalFilterDropdown from 'components/SalesFilter/HorizontalDropdown'
import { useApolloProgram } from 'components/SolanaManager'
import { PageWrapper } from 'pages/styled'
import { transparentize } from 'polished'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHomepageSales } from 'state/apollo/hooks'
import { useAllCollections } from 'state/collections/hooks'
import { addRequiredSales } from 'state/sales/actions'
import { useSafeSales } from 'state/sales/hooks'
import styled from 'styled-components/macro'
import { HideSmall, StyledInternalLink, TYPE } from 'theme'

const Grid = styled(AutoColumn)`
  display: grid;
  width: 100%;
  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  grid-gap: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`
const TopSection = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

export const ResponsiveTwoColumns = styled.div<{ wide: boolean }>`
  display: grid;

  grid-template-columns: ${({ wide }) => (wide ? '300px 1fr' : '1fr')};
  grid-template-rows: max-content;
  grid-auto-flow: row;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;

    margin-top: 0;
  `};
`

const FilterWrapper = styled(Column)`
  flex: 1 1;
  position: relative;
  background-color: ${({ theme }) => transparentize(1.0, theme.bg2)};
  margin: 0.2rem 2rem 2rem 0rem;

  border-radius: 0.5rem;
`

export default function CollectionsPage() {
  const wallet = useAnchorWallet()
  const apollo = useApolloProgram()
  const dispatch = useDispatch()

  const homepageSales = useHomepageSales()
  const homepageSalesSafe = useMemo(
    () =>
      homepageSales.filter((sale) => {
        let ok = true
        try {
          const pubkey = new PublicKey(sale)
        } catch (e) {
          ok = false
        }
        return ok
      }),
    [homepageSales]
  )

  const sales = useSafeSales(homepageSalesSafe)
  const collections = useAllCollections()

  console.log('sales', sales)
  useEffect(() => {
    console.log('aState fetching sales', homepageSales)
    dispatch(addRequiredSales(homepageSalesSafe))
  }, [dispatch, homepageSales, homepageSalesSafe])

  console.log('sales', sales)
  return (
    <PageWrapper>
      <AutoColumn gap="lg">
        <TYPE.main>Your Watchlist</TYPE.main>
        {}
        <HideSmall>
          <DarkGreyCard style={{ paddingTop: '12px' }}>
            <AutoColumn gap="md">
              <TYPE.mediumHeader fontSize="16px">Trending</TYPE.mediumHeader>
              {}
            </AutoColumn>
          </DarkGreyCard>
        </HideSmall>
        <RowBetween>
          <AutoColumn>
            <TYPE.main>Top Collections</TYPE.main>
          </AutoColumn>
          <StyledInternalLink to="/collections/create">
            <ButtonAction>New</ButtonAction>
          </StyledInternalLink>
        </RowBetween>
        <CollectionsTable collections={collections} />
      </AutoColumn>
    </PageWrapper>
  )
}
