import * as anchor from '@project-serum/anchor'
import { Program } from '@project-serum/anchor'
import { AccountLayout, ASSOCIATED_TOKEN_PROGRAM_ID, NATIVE_MINT, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import {
  AccountMeta,
  Connection,
  Keypair,
  PublicKey as Pubkey,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js'
import { BID_SIZE } from 'providers/accounts/utils/bundleMints'

import { InitBundleBox, SendableTXI } from './types'

export const MAX_TOKEN_BOXES = 1

const TOKEN_METADATA_PROGRAM_ID = new Pubkey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')

export const getBundleTokenStoreAccount = async (
  apollo: anchor.Program,
  bundle: anchor.web3.PublicKey,
  tokenMint: anchor.web3.PublicKey
) => {
  console.log('tokenMint', tokenMint.toBase58())
  return await anchor.web3.PublicKey.findProgramAddress([bundle.toBuffer(), tokenMint.toBuffer()], apollo.programId)
}
export const getSaleGraveyardAccount = async (
  apollo: anchor.Program,
  sale: anchor.web3.PublicKey,
  saleReceiptMint: anchor.web3.PublicKey
) => {
  return await anchor.web3.PublicKey.findProgramAddress([sale.toBuffer(), saleReceiptMint.toBuffer()], apollo.programId)
}
export const getBidGraveyardAccount = async (
  apollo: anchor.Program,
  bid: anchor.web3.PublicKey,
  saleReceiptMint: anchor.web3.PublicKey
) => {
  return await anchor.web3.PublicKey.findProgramAddress([bid.toBuffer(), saleReceiptMint.toBuffer()], apollo.programId)
}
export const getTokenStoreAuth = async (
  apollo: anchor.Program,
  sale: anchor.web3.PublicKey,
  tokenMint: anchor.web3.PublicKey
) => {
  return await anchor.web3.PublicKey.findProgramAddress([sale.toBuffer(), tokenMint.toBuffer()], apollo.programId)
}
export const getAskStoreAcct = async (
  apollo: anchor.Program,
  ask: anchor.web3.PublicKey,
  askMint: anchor.web3.PublicKey
) => {
  return await anchor.web3.PublicKey.findProgramAddress([ask.toBuffer(), askMint.toBuffer()], apollo.programId)
}
export const getBidStoreAcct = async (
  apollo: anchor.Program,
  bid: anchor.web3.PublicKey,
  bidMint: anchor.web3.PublicKey
) => {
  return await anchor.web3.PublicKey.findProgramAddress([bid.toBuffer(), bidMint.toBuffer()], apollo.programId)
}

const getMetadataPDA = async (mint: anchor.web3.PublicKey): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from('metadata'), TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0]
}

const getMasterEditionPDA = async (mint: anchor.web3.PublicKey): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from('metadata'), TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer(), Buffer.from('edition')],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0]
}

export const getBundleMintAuth = async (apollo: anchor.Program, bundle: anchor.web3.PublicKey) => {
  return await anchor.web3.PublicKey.findProgramAddress([bundle.toBuffer()], apollo.programId)
}

export async function createWrappedSolAccount(connection: Connection, owner: Pubkey, payer: Pubkey, lamports: number) {
  // Allocate memory for the account
  const balanceNeeded = await Token.getMinBalanceRentForExemptAccount(connection)

  // Create a new account
  const newAccount = Keypair.generate()
  const tx1 = SystemProgram.createAccount({
    fromPubkey: payer,
    newAccountPubkey: newAccount.publicKey,
    lamports: balanceNeeded,
    space: AccountLayout.span,
    programId: TOKEN_PROGRAM_ID,
  })

  // Send lamports to it (these will be wrapped into native tokens by the token program)
  const tx2 = SystemProgram.transfer({
    fromPubkey: payer,
    toPubkey: newAccount.publicKey,
    lamports,
  })

  // Assign the new account to the native token mint.
  // the account will be initialized with a balance equal to the native token balance.
  // (i.e. amount)
  const tx3 = Token.createInitAccountInstruction(TOKEN_PROGRAM_ID, NATIVE_MINT, newAccount.publicKey, owner)

  // Send the three instructions
  return { wrappedSolTXIs: [tx1, tx2, tx3], wrappedSolAcct: newAccount }
}

export const genTreasuryAuthSeed = async (apollo: Program, creator: Pubkey): Promise<Pubkey> => {
  let seedKey
  let valid = false
  while (!valid) {
    const attempKeypair = Keypair.generate()
    const [bundlePubkey, bundleBump] = await anchor.web3.PublicKey.findProgramAddress(
      [creator.toBuffer(), attempKeypair.publicKey.toBuffer()],
      apollo.programId
    )
    if (bundleBump == 255) {
      valid = true
      seedKey = attempKeypair
    }
  }
  return seedKey?.publicKey as Pubkey
}

export const initBundle = async (
  apollo: anchor.Program,
  bundle: Keypair,
  bundleMint: Pubkey,
  authority: Pubkey,
  bundleMintUpdateAuthority: Pubkey,
  // curatorBasisPoints: number,
  tokenBoxes: InitBundleBox[],
  metadataName: string,
  metadataSymbol: string,
  metadataURI: string
): Promise<SendableTXI[]> => {
  // print intro log with all params
  const createInst = await apollo.account.bundle.createInstruction(bundle)
  const remainingAccounts = await Promise.all(
    tokenBoxes.map(async (b) => {
      const [bundleStoreAcct, _] = await getBundleTokenStoreAccount(apollo, bundle.publicKey, b.tokenMint)
      return [
        { pubkey: b.depositAccount, isWritable: true, isSigner: false },
        { pubkey: bundleStoreAcct, isWritable: true, isSigner: false },
        { pubkey: b.tokenMint, isWritable: false, isSigner: false },
      ]
    })
  )
  const remainingAccountsFlat = remainingAccounts.flat()
  const amounts = tokenBoxes.map((b) => new anchor.BN(b.amount))
  for (let i = 0; i < MAX_TOKEN_BOXES; i++) {
    if (amounts.length <= i) {
      amounts.push(new anchor.BN(0))
    }
  }
  const [bundleMintMintAuthority, _bump] = await getBundleMintAuth(apollo, bundle.publicKey)
  const authorityBundleMintAcct = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    bundleMint,
    authority
  )
  const bundleMintMasterEdition = await getMasterEditionPDA(bundleMint)
  const bundleMintMetadata = await getMetadataPDA(bundleMint)
  const createInst2 = await apollo.instruction.initBundle(amounts, metadataName, metadataSymbol, metadataURI, {
    accounts: {
      bundle: bundle.publicKey,
      authority,
      bundleMint,
      authorityBundleMintAcct,
      bundleMintMintAuthority,
      bundleMintUpdateAuthority,
      bundleMintMasterEdition,
      bundleMintMetadata,
      tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      rent: SYSVAR_RENT_PUBKEY,
    },
    remainingAccounts: remainingAccountsFlat,
  })
  console.log(`bundleMintMetadata: ${bundleMintMetadata.toBase58()}`)
  console.log(`bundleMintMasterEdition: ${bundleMintMasterEdition.toBase58()}`)

  return [{ txis: [createInst, createInst2], signers: [] }]
}

export const createSale = async (
  apollo: anchor.Program,
  sale: Keypair,
  authority: Pubkey,
  receiptMint: Pubkey,
  receiptUpdateAuthority: Pubkey,
  tokenMint: Pubkey,
  tokenDepositAcct: Pubkey,
  tokenAmount: number,
  metadataName: string,
  metadataSymbol: string,
  metadataURI: string
): Promise<SendableTXI[]> => {
  const createInst = await apollo.account.sale.createInstruction(sale)
  const [receiptMintAuthority, _bump] = await getBundleMintAuth(apollo, sale.publicKey)
  const authorityReceiptAcct = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    receiptMint,
    authority
  )
  const receiptMetadata = await getMetadataPDA(receiptMint)
  const receiptMasterEdition = await getMasterEditionPDA(receiptMint)

  const [tokenStoreAcct, _] = await getBundleTokenStoreAccount(apollo, sale.publicKey, tokenMint)
  const [tokenStoreAuth, _bump2] = await getTokenStoreAuth(apollo, sale.publicKey, tokenMint)

  const createInst2 = await apollo.instruction.createSale(
    new anchor.BN(tokenAmount),
    // metadataName,
    // metadataSymbol,
    metadataURI,
    {
      accounts: {
        sale: sale.publicKey,
        authority,
        tokenDepositAcct,
        tokenStoreAcct,
        tokenStoreAuth,
        tokenMint,
        receiptMint,
        receiptMetadata,
        receiptMintAuthority,
        receiptUpdateAuthority,
        receiptMasterEdition,
        authorityReceiptAcct,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      },
    }
  )
  console.log(`createSaleInst:`, createInst2)
  return [{ txis: [createInst2], signers: [] }]
}

export const cancelSale = async (
  apollo: anchor.Program,
  sale: Pubkey,
  tokenMint: Pubkey,
  saleReceiptMint: Pubkey,
  authority: Pubkey
): Promise<SendableTXI[]> => {
  const saleReceiptATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    saleReceiptMint,
    authority
  )

  const authorityTokenATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenMint,
    authority
  )

  const [tokenStoreAcct, _] = await getBundleTokenStoreAccount(apollo, sale, tokenMint)
  const [saleReceiptGraveyard, _bump] = await getSaleGraveyardAccount(apollo, sale, saleReceiptMint)
  const [tokenStoreAuth, _bum2p] = await getTokenStoreAuth(apollo, sale, tokenMint)

  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  const cancelSaleInst = await apollo.instruction.cancelSale({
    accounts: {
      sale,
      saleReceiptMint,
      saleReceiptAta: saleReceiptATA,
      saleReceiptGraveyard,
      tokenMint,
      authorityTokenAta: authorityTokenATA,
      tokenStoreAcct,
      tokenStoreAuth,
      authority,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: SYSVAR_RENT_PUBKEY,
    },
  })

  return [{ txis: [cancelSaleInst], signers: [] }]
}

export const voidBid = async (
  apollo: anchor.Program,
  bid: Pubkey,
  bidTokenMint: Pubkey,
  bidReceiptMint: Pubkey,
  authority: Pubkey
): Promise<SendableTXI[]> => {
  const bidReceiptATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    bidReceiptMint,
    authority
  )

  const authorityBitTokenATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    bidTokenMint,
    authority
  )

  const [bidTokenStoreAcct, _] = await getBidStoreAcct(apollo, bid, bidTokenMint)
  const [bidReceiptGraveyard, _bump] = await getBidGraveyardAccount(apollo, bid, bidReceiptMint)
  const [bidTokenStoreAuth, _bum2p] = await getBidStoreAcct(apollo, bid, bidTokenMint)

  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  const voidBidInst = await apollo.instruction.voidBid({
    accounts: {
      bid,
      bidReceiptMint,
      bidReceiptAta: bidReceiptATA,
      bidReceiptGraveyard,
      bidTokenMint,
      authorityBidTokenAta: authorityBitTokenATA,
      bidTokenStoreAcct,
      bidTokenStoreAuth,
      authority,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: SYSVAR_RENT_PUBKEY,
    },
  })

  return [{ txis: [voidBidInst], signers: [] }]
}

export const redeemAcceptedBid = async (
  apollo: anchor.Program,
  bid: Pubkey,
  bidTokenMint: Pubkey,
  bidReceiptMint: Pubkey,
  sale: Pubkey,
  saleTokenMint: Pubkey,
  authority: Pubkey
): Promise<SendableTXI[]> => {
  const authorityTokenATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    saleTokenMint,
    authority
  )

  const [tokenStoreAcct, _b1] = await getBundleTokenStoreAccount(apollo, sale, saleTokenMint)
  const [tokenStoreAuth, _b3] = await getTokenStoreAuth(apollo, sale, saleTokenMint)

  const bidReceiptATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    bidReceiptMint,
    authority
  )

  const authorityBitTokenATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    bidTokenMint,
    authority
  )

  const [bidTokenStoreAcct, _b4] = await getBidStoreAcct(apollo, bid, bidTokenMint)
  const [bidReceiptGraveyard, _b5] = await getBidGraveyardAccount(apollo, bid, bidReceiptMint)
  const [bidTokenStoreAuth, _b6] = await getBidStoreAcct(apollo, bid, bidTokenMint)

  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  const redeemAcceptedBidInst = await apollo.instruction.redeemAcceptedBid({
    accounts: {
      bid,
      bidReceiptMint,
      bidReceiptAta: bidReceiptATA,
      bidReceiptGraveyard,
      sale,
      tokenStoreAcct,
      tokenStoreAuth,
      tokenMint: saleTokenMint,
      authorityTokenAta: authorityTokenATA,
      authority,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: SYSVAR_RENT_PUBKEY,
    },
  })

  return [{ txis: [redeemAcceptedBidInst], signers: [] }]
}

export const addAsk = async (
  apollo: anchor.Program,
  ask: Keypair,
  sale: Pubkey,
  saleReceiptMint: Pubkey,
  authority: Pubkey,
  askMint: Pubkey,
  askAmount: number,
  expirationDate: Date
): Promise<SendableTXI[]> => {
  const expirationDateNum = expirationDate ? expirationDate.valueOf() / 1000 : 0

  const saleReceiptATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    saleReceiptMint,
    authority
  )
  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  const addAskInst = await apollo.instruction.addAsk(new anchor.BN(askAmount), new anchor.BN(expirationDateNum), {
    accounts: {
      sale,
      ask: ask.publicKey,
      tokenMint: askMint,
      saleReceiptMint,
      saleReceiptAta: saleReceiptATA,
      authority,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
    },
  })

  return [{ txis: [addAskInst], signers: [] }]
}

export const acceptAsk = async (
  apollo: anchor.Program,
  sale: Pubkey,
  ask: Pubkey,
  authority: Pubkey,
  askMint: Pubkey,
  askTokenDepositAcct: Pubkey,
  tokenMint: Pubkey,
  metadataAccts: AccountMeta[]
): Promise<SendableTXI[]> => {
  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  // const askTokenDepositAcct = await Token.getAssociatedTokenAddress(
  //   ASSOCIATED_TOKEN_PROGRAM_ID,
  //   TOKEN_PROGRAM_ID,
  //   askMint,
  //   authority
  // )
  const [askTokenStoreAcct, _b] = await getAskStoreAcct(apollo, ask, askMint)

  const [tokenStoreAcct, _] = await getBundleTokenStoreAccount(apollo, sale, tokenMint)
  const [tokenStoreAuth, _bum2p] = await getTokenStoreAuth(apollo, sale, tokenMint)

  const authorityTokenATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenMint,
    authority
  )

  const acceptAskInst = await apollo.instruction.acceptAsk({
    accounts: {
      sale,
      ask,
      authority,
      askTokenMint: askMint,
      askTokenDepositAcct,
      askTokenStoreAcct,
      askTokenStoreAuth: askTokenStoreAcct,
      tokenMint,
      tokenStoreAcct,
      tokenStoreAuth,
      authorityTokenAta: authorityTokenATA,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
    },
    remainingAccounts: metadataAccts,
  })

  return [{ txis: [acceptAskInst], signers: [] }]
}

export const addBid = async (
  apollo: anchor.Program,
  sale: Pubkey,
  bid: Keypair,
  authority: Pubkey,
  bidTokenMint: Pubkey,
  bidTokenDepositAcct: Pubkey,
  bidAmount: number,
  expirationDate: Date,
  receiptMint: Keypair,
  receiptUpdateAuthority: Keypair,
  receiptMetadataName: string,
  receiptMetadataSymbol: string,
  receiptMetadataURI: string,
  saleMetadataAcct: AccountMeta | undefined
): Promise<SendableTXI[]> => {
  const createInst = await apollo.account.bid.createInstruction(bid, BID_SIZE)

  const expirationDateNum = expirationDate ? expirationDate.valueOf() / 1000 : 0

  const [bidTokenStoreAcct, _] = await getBidStoreAcct(apollo, bid.publicKey, bidTokenMint)
  const [bidTokenStoreAuth, _bum2p] = await getBidStoreAcct(apollo, bid.publicKey, bidTokenMint)

  const [receiptMintAuthority, _bump] = await getBundleMintAuth(apollo, bid.publicKey)
  const authorityReceiptAcct = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    receiptMint.publicKey,
    authority
  )
  const receiptMetadata = await getMetadataPDA(receiptMint.publicKey)
  const receiptMasterEdition = await getMasterEditionPDA(receiptMint.publicKey)
  console.log('expirationDateNum')
  console.log('bidAmount', bidAmount)
  const addBidInst = await apollo.instruction.addBid(
    new anchor.BN(bidAmount),
    new anchor.BN(expirationDateNum),
    // receiptMetadataName,
    // receiptMetadataSymbol,
    receiptMetadataURI,
    {
      accounts: {
        sale,
        bid: bid.publicKey,
        authority,
        bidTokenMint,
        bidTokenStoreAcct,
        bidTokenStoreAuth,
        bidTokenDepositAcct,
        bidReceiptMint: receiptMint.publicKey,
        bidReceiptMetadata: receiptMetadata,
        bidReceiptMintAuthority: receiptMintAuthority,
        bidReceiptUpdateAuthority: receiptUpdateAuthority.publicKey,
        bidReceiptMasterEdition: receiptMasterEdition,
        authorityReceiptAcct,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      },
      remainingAccounts: saleMetadataAcct ? [saleMetadataAcct] : [],
    }
  )

  return [{ txis: [createInst, addBidInst], signers: [] }]
}

export const voidAsk = async (
  apollo: anchor.Program,
  ask: Pubkey,
  sale: Pubkey,
  saleReceiptMint: Pubkey,
  authority: Pubkey
): Promise<SendableTXI[]> => {
  const saleReceiptATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    saleReceiptMint,
    authority
  )
  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  const addAskInst = await apollo.instruction.voidAsk({
    accounts: {
      sale,
      ask,
      saleReceiptMint,
      saleReceiptAta: saleReceiptATA,
      authority,
    },
  })

  return [{ txis: [addAskInst], signers: [] }]
}

export const receiveAcceptedAsk = async (
  apollo: anchor.Program,
  sale: Pubkey,
  saleReceiptMint: Pubkey,
  ask: Pubkey,
  askTokenMint: Pubkey,
  authority: Pubkey
): Promise<SendableTXI[]> => {
  const saleReceiptATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    saleReceiptMint,
    authority
  )
  const [saleReceiptGraveyard, _b1] = await getSaleGraveyardAccount(apollo, sale, saleReceiptMint)
  const [askTokenStoreAcct, _b2] = await getAskStoreAcct(apollo, ask, askTokenMint)
  const [askTokenStoreAuth, _b3] = await getAskStoreAcct(apollo, ask, askTokenMint)
  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  const authorityAskTokenATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    askTokenMint,
    authority
  )
  const receiveAcceptedAskInst = await apollo.instruction.receiveAcceptedAsk({
    accounts: {
      sale,
      saleReceiptMint,
      saleReceiptAta: saleReceiptATA,
      saleReceiptGraveyard,
      ask,
      askTokenMint,
      askTokenStoreAcct,
      askTokenStoreAuth,
      authorityAskTokenAta: authorityAskTokenATA,
      authority,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: SYSVAR_RENT_PUBKEY,
    },
  })

  return [{ txis: [receiveAcceptedAskInst], signers: [] }]
}

export const acceptBid = async (
  apollo: anchor.Program,
  sale: Pubkey,
  saleReceiptMint: Pubkey,
  bid: Pubkey,
  bidTokenMint: Pubkey,
  authority: Pubkey,
  metadataAccts: AccountMeta[]
): Promise<SendableTXI[]> => {
  const saleReceiptATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    saleReceiptMint,
    authority
  )
  const [saleReceiptGraveyard, _b1] = await getSaleGraveyardAccount(apollo, sale, saleReceiptMint)
  const [bidTokenStoreAcct, _b2] = await getBidStoreAcct(apollo, bid, bidTokenMint)
  const [bidTokenStoreAuth, _b3] = await getBidStoreAcct(apollo, bid, bidTokenMint)
  // const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, sale)
  const authorityBidTokenATA = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    bidTokenMint,
    authority
  )
  const acceptBidInst = await apollo.instruction.acceptBid({
    accounts: {
      sale,
      saleReceiptMint,
      saleReceiptAta: saleReceiptATA,
      saleReceiptGraveyard,
      bid,
      bidTokenMint,
      bidTokenStoreAcct,
      bidTokenStoreAuth,
      authorityBidTokenAta: authorityBidTokenATA,
      authority,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: SYSVAR_RENT_PUBKEY,
      clock: SYSVAR_CLOCK_PUBKEY,
    },
    remainingAccounts: metadataAccts,
  })

  return [{ txis: [acceptBidInst], signers: [] }]
}

// export const acceptAsk = async (
//   apollo: anchor.Program,
//   bundle: Pubkey,
//   bundleMint: Pubkey,
//   askTokenMint: Pubkey,
//   seller: Pubkey,
//   buyer: Pubkey,
//   curator: Pubkey,
//   curatorBasisPoints: number,
//   askUUID: Pubkey,
//   metadataAccts: AccountMeta[],
//   buyerAskTokenAcct?: Pubkey
// ): Promise<SendableTXI[]> => {
//   const sellerBundleMintAccount = await Token.getAssociatedTokenAddress(
//     ASSOCIATED_TOKEN_PROGRAM_ID,
//     TOKEN_PROGRAM_ID,
//     bundleMint,
//     seller
//   )
//   const sellerAskMintAccount = await Token.getAssociatedTokenAddress(
//     ASSOCIATED_TOKEN_PROGRAM_ID,
//     TOKEN_PROGRAM_ID,
//     askTokenMint,
//     seller
//   )
//   const buyerAskTokenAcct2 = await Token.getAssociatedTokenAddress(
//     ASSOCIATED_TOKEN_PROGRAM_ID,
//     TOKEN_PROGRAM_ID,
//     askTokenMint,
//     buyer
//   )
//   buyerAskTokenAcct = buyerAskTokenAcct
//     ? buyerAskTokenAcct
//     : await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, askTokenMint, buyer)
//   const buyerBundleMintAccount = await Token.getAssociatedTokenAddress(
//     ASSOCIATED_TOKEN_PROGRAM_ID,
//     TOKEN_PROGRAM_ID,
//     bundleMint,
//     buyer
//   )

//   const curatorAskTokenAcct = await Token.getAssociatedTokenAddress(
//     ASSOCIATED_TOKEN_PROGRAM_ID,
//     TOKEN_PROGRAM_ID,
//     askTokenMint,
//     curator
//   )

//   const [bundleMintTransferAuthority, _bump] = await getBundleMintAuth(apollo, bundle)

//   const addAskInst = await apollo.instruction.acceptAsk(askUUID, new anchor.BN(curatorBasisPoints), {
//     accounts: {
//       bundle,
//       bundleMint,
//       bundleMintTransferAuthority,
//       askTokenMint,
//       seller,
//       sellerBundleMintAccount,
//       sellerAskMintAccount,
//       buyer,
//       buyerAskTokenAcct,
//       buyerBundleMintAccount,
//       bundleCurator: curator,
//       bundleCuratorReceiptAccount: curatorAskTokenAcct,
//       tokenProgram: TOKEN_PROGRAM_ID,
//       ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
//       systemProgram: SystemProgram.programId,
//       rent: SYSVAR_RENT_PUBKEY,
//       clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
//     },
//     remainingAccounts: metadataAccts,
//     // instructions: makeATATXIs,
//   })

//   // console.log('addAskInst', addAskInst)

//   return [{ txis: [addAskInst], signers: [] }]
// }
