import { nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { create } from 'superstruct'
import { SaleData } from 'validators/accounts/sales'

import { fetchChainSaleRequest, fetchFirebaseSaleRequest, updateFirebaseSaleRequest } from './actions'

export const SALES_COLLECTION = 'sales5'

export function useFetchFirebaseSaleData(): (salePubkey: PublicKey) => Promise<SaleData | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (salePubkey: PublicKey) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseSaleRequest.pending({ saleAddress: salePubkey.toBase58(), requestID }))
      try {
        const db = getFirestore()
        const saleSnap = await getDoc(doc(db, SALES_COLLECTION, salePubkey.toBase58()))
        console.log('firebase collections: ', saleSnap)
        if (saleSnap.exists()) {
          console.log('firebase Document data:', saleSnap.data())
          const saleData = decodeFirebaseSaleData(saleSnap.data())
          console.log('firebase saleData decoded: ', saleData)
          dispatch(fetchFirebaseSaleRequest.fulfilled({ requestID, saleData }))
          return saleData
        } else {
          console.log('firebase Sale not found. Creating.')
          return undefined
        }
        // return sale
      } catch (e) {
        console.error(e)
        dispatch(fetchChainSaleRequest.rejected({ requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

// export interface MetadataCombined {
//   key: MetadataKey
//   updateAuthority: StringPublicKey
//   sale: StringPublicKey
//   data: Data
//   primarySaleHappened: boolean
//   isMutable: boolean
//   editionNonce: number | undefined
//   extended?: IMetadataExtension
// }

// export interface IMetadataExtension {
//   name: string
//   symbol: string

//   creators: Creator[] | undefined
//   description: string
//   // preview image absolute URI
//   image: string
//   animation_url?: string

//   // stores link to item on meta
//   external_url: string

//   seller_fee_basis_points: number

//   properties: {
//     files?: FileOrString[]
//     category: MetadataCategory
//     maxSupply?: number
//     creators?: {
//       address: string
//       shares: number
//     }[]
//   }

//   attributes?: IMetadataAttribute[] | undefined
//   collection?: IMetadataCollection | undefined
// }

// export class Creator {
//   address: StringPublicKey
//   verified: boolean
//   share: number

//   constructor(args: { address: StringPublicKey; verified: boolean; share: number }) {
//     this.address = args.address
//     this.verified = args.verified
//     this.share = args.share
//   }
// }

// export class Data {
//   name: string
//   symbol: string
//   uri: string
//   sellerFeeBasisPoints: number
//   creators: Creator[] | undefined
//   constructor(args: {
//     name: string
//     symbol: string
//     uri: string
//     sellerFeeBasisPoints: number
//     creators: Creator[] | undefined
//   }) {
//     this.name = args.name
//     this.symbol = args.symbol
//     this.uri = args.uri
//     this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
//     this.creators = args.creators
//   }
// }

// export interface IMetadataCollection {
//   name: string
//   family: string
// }

// export interface IMetadataAttribute {
//   trait_type: string
//   value: string
// }
//
const encodeSaleObject = (saleData: SaleData) => {
  return saleData
  console.log('encodeSaleObject: ', saleData)
  // const saleObj = {
  //   // ...sale,
  //   pubkey: saleData.pubkey.toBase58(),
  //   sale: encodeSale(saleData.sale),
  //   metadata: saleData.metadata
  //     ? {
  //         key: saleData.metadata.key,
  //         updateAuthority: saleData.metadata.updateAuthority.toBase58(),
  //         mint: saleData.metadata.mint.toBase58(),
  //         data: saleData.metadata.data
  //           ? {
  //               name: saleData.metadata.data?.name,
  //               symbol: saleData.metadata.data.symbol,
  //               uri: saleData.metadata.data.uri,
  //               sellerFeeBasisPoints: saleData.metadata.data.sellerFeeBasisPoints,
  //               creators: saleData.metadata.data.creators
  //                 ? saleData.metadata.data.creators.map((creator) => ({
  //                     address: creator.address.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //             }
  //           : {},
  //         primarySaleHappened: saleData.metadata.primarySaleHappened,
  //         isMutable: saleData.metadata.isMutable,
  //         editionNonce: saleData.metadata.editionNonce ?? '',
  //         extended: saleData.metadata.extended
  //           ? {
  //               name: saleData.metadata.extended.name,
  //               symbol: saleData.metadata.extended.symbol,
  //               creators: saleData.metadata.extended.creators
  //                 ? saleData.metadata.extended.creators.map((creator) => ({
  //                     address: creator.address.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //               description: saleData.metadata.extended.description,
  //               image: saleData.metadata.extended.image,
  //               animation_url: saleData.metadata.extended.animation_url ?? '',
  //               external_url: saleData.metadata.extended.external_url,
  //               seller_fee_basis_points: saleData.metadata.extended.seller_fee_basis_points,
  //               properties: {
  //                 files: saleData.metadata.extended.properties.files
  //                   ? saleData.metadata.extended.properties.files.map((file) => {
  //                       return {
  //                         file: file.valueOf(),
  //                         content_type: file.type ?? '',
  //                       }
  //                     })
  //                   : [],
  //                 category: saleData.metadata.extended.properties.category,
  //                 maxSupply: saleData.metadata.extended.properties.maxSupply ?? '',
  //               },
  //               attributes: saleData.metadata.extended.attributes
  //                 ? saleData.metadata.extended.attributes.map((attribute) => ({
  //                     trait_type: attribute.trait_type,
  //                     value: attribute.value,
  //                   }))
  //                 : [],
  //               collection: saleData.metadata.extended.collection
  //                 ? {
  //                     name: saleData.metadata.extended.collection.name,
  //                     family: saleData.metadata.extended.collection.family,
  //                   }
  //                 : {},
  //             }
  //           : {},
  //       }
  //     : {},
  //   asks: saleData.asks ? saleData.asks.map((ask) => encodeAskV2(ask)) : [],
  //   bids: saleData.bids ? saleData.bids.map((bid) => encodeBidV2(bid)) : [],
  //   owner: saleData.owner?.toBase58() ?? '',
  // }

  // console.log('encodeSaleObject: ', saleObj)

  // return saleObj
}

export const decodeFirebaseSaleData = (res: any) => {
  console.log('decodeFirebaseSaleData: ', res)
  return create(res, SaleData)
}

export function useUpdateFirebaseSale(): (saleData: SaleData) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (saleData: SaleData) => {
      const requestID = nanoid()
      dispatch(updateFirebaseSaleRequest.pending({ saleAddress: saleData.pubkey, requestID }))
      try {
        const db = getFirestore()
        const saleObj = encodeSaleObject(saleData)
        console.log('firebase uploading sale: ', saleObj)
        setDoc(doc(db, SALES_COLLECTION, saleData.pubkey), saleObj).then(() => {
          console.log('firebase sale uploaded')
          dispatch(updateFirebaseSaleRequest.fulfilled({ requestID, saleData }))
        })
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseSaleRequest.rejected({ saleAddress: saleData.pubkey, requestID, errorMessage: e }))
        // throw e
      }
    },
    [dispatch]
  )
}
