import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state/hooks'

import { addRefinementListValue, clearAllValues, resetToExploreCollections, setSearchIndex } from './actions'
import { useSearchState } from './hooks'
import { MintData } from './models'
import { SearchIndex } from './reducer'

export default function Updater(): null {
  const dispatch = useAppDispatch()

  const searchState = useSearchState()
  const { pathname } = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(pathname.split('?')[1])
    const refinementList = query.getAll('refinementList')
    const paths = pathname.split('/')
    console.log('mSearch Updater', refinementList, paths)
    if (paths.length > 2 && paths[1] == 'c') {
      dispatch(addRefinementListValue({ index: 'featuredInCollections', value: paths[2] }))
    } else {
      dispatch(clearAllValues({ index: 'featuredInCollections' }))
    }
    if (paths.length > 2 && paths[1] == 'explore') {
      if (paths[2] == 'collections') {
        dispatch(setSearchIndex(SearchIndex.Collections))
        dispatch(resetToExploreCollections({ param: 'p' }))
      }
      if (paths[2] == 'mints') {
        dispatch(setSearchIndex(SearchIndex.Mint))
      }
    } else {
      dispatch(clearAllValues({ index: 'featuredInCollections' }))
    }
  }, [dispatch, pathname])

  return null
}
