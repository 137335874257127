import { ChevronDown } from 'react-feather'
import styled, { css, keyframes } from 'styled-components/macro'

export enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

export const StyledChevronDown = styled(ChevronDown)`
  path {
    stroke: ${({ theme }) => theme.text3};
  }
`

export const FilterButton = styled.button`
  width: fit-content;
  display: flex;
  justify-content: between;
  align-items: center;
  border-radius: 100px;
  border: none;
  background-color: transparent;
  height: 32px;
  background-color: ${({ theme }) => theme.bg0};
  border: 2px solid ${({ theme }) => theme.bg2};

  position: relative;
  padding: 4px 8px;
  border-radius: 120px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    border: 2px solid ${({ theme }) => theme.bg3};
  }

  svg {
    margin-top: 2px;
  }
`

export const FilterCoverText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
  }
  > svg {
    margin-right: 8px;
  }
`

export const FilterFlyout = styled.span`
  min-width: 250px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 35px;
  z-index: 100;
  // left: 30px;
  left: 00px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  bottom: unset;
  right: 0;
  left: unset;
  `};

  color: ${({ theme }) => theme.text2};
`
