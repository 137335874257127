import { Program } from '@project-serum/anchor'
import { Connection, ParsedAccountData, PublicKey } from '@solana/web3.js'
import { decodeAskV2, decodeBidV2 } from 'apollo/types'
import { getExtendedArt, pubkeyFromString } from 'apollo/utils'
import { apolloProgramAddress, providerURL } from 'hooks/web3'
import { Edition, MasterEditionV1, MasterEditionV2 } from 'metaplex/classes'
import {
  AUCTION_ID,
  BPF_UPGRADE_LOADER_ID,
  MEMO_ID,
  METADATA_PROGRAM_ID,
  METAPLEX_ID,
  SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
  SYSTEM,
  VAULT_ID,
} from 'metaplex/ids'
import { TOKEN_PROGRAM_ID } from 'providers/accounts/tokens'
import { create } from 'superstruct'
import { ParsedInfo } from 'validators'
import { AskV2, BidV2, MetadataCombined } from 'validators/accounts/sales'
import { TokenAccount, TokenAccountInfo } from 'validators/accounts/token'

import { getMetadata } from './metadataHelpers'

let STORE: PublicKey | undefined

export type EditionData = {
  masterEdition?: MasterEditionV1 | MasterEditionV2
  edition?: Edition
}

export const programIds = () => {
  return {
    token: TOKEN_PROGRAM_ID,
    associatedToken: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    bpf_upgrade_loader: BPF_UPGRADE_LOADER_ID,
    system: SYSTEM,
    metadata: METADATA_PROGRAM_ID,
    memo: MEMO_ID,
    vault: VAULT_ID,
    auction: AUCTION_ID,
    metaplex: METAPLEX_ID,
    store: STORE,
  }
}

export async function getBundleOwner(connection: Connection, bundleMint: PublicKey): Promise<PublicKey | undefined> {
  const fetchAllRes = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {
    filters: [
      {
        dataSize: 165,
      },
      {
        memcmp: {
          offset: 0,
          bytes: bundleMint.toBase58(),
        },
      },
    ],
  })
  if (fetchAllRes.length === 0) {
    return undefined
  }
  const accountInfo = fetchAllRes[0]

  // const largestAccountsRes = await connection.getTokenLargestAccounts(bundleMint)
  // if (largestAccountsRes.value.length === 0) {
  //   return undefined
  // }
  // const largestAccount = largestAccountsRes.value[0]
  // console.log('bundle owner largestAccountsRes', largestAccountsRes.value[0].address.toBase58())
  // console.log('bundle owner bundleMint.toBase58()', bundleMint.toBase58())
  console.log('bundle owner accountInfo.account', accountInfo.pubkey.toBase58())
  const result = (await connection.getParsedAccountInfo(accountInfo.pubkey)).value
  const parsedInfo = (result?.data as ParsedAccountData).parsed.info
  const info = create(parsedInfo, TokenAccountInfo)
  return info.owner
  console.log('bundle owner result', result)

  return result?.owner
}

export async function saleFromMint(
  connection: Connection,
  // apollo: Program,
  bundleMint: PublicKey
): Promise<PublicKey | undefined> {
  const apolloProgram = new PublicKey(apolloProgramAddress())
  console.log('apolloProgram', apolloProgram.toBase58())
  console.log('bundleMint', bundleMint.toBase58())
  const fetchAllRes = await connection.getProgramAccounts(apolloProgram, {
    encoding: 'base64',
    filters: [
      {
        dataSize: SALE_SIZE,
      },
      {
        memcmp: {
          offset: 8 + 32 + 32,
          bytes: bundleMint.toBase58(),
        },
      },
    ],
  })
  console.log('sale fetchAllRes', fetchAllRes)
  if (fetchAllRes.length === 0) {
    return undefined
  }
  return fetchAllRes[0].pubkey
  // random from fetchAllRes array
  // const
  // const bundleAccount = fetchAllRes[0]
}

export const ownerFromMint = async (
  connection: Connection,
  apollo: Program,
  mint: PublicKey
): Promise<PublicKey | undefined> => {
  const ownerAcctRes = await connection.getTokenLargestAccounts(mint)
  const ownerATAPubkey = ownerAcctRes.value[0].address
  const result = (await connection.getParsedAccountInfo(ownerATAPubkey)).value
  if (result && 'parsed' in result.data) {
    const info = create(result.data.parsed, ParsedInfo)
    if (result.data.program == 'spl-token') {
      const parsed = create(info, TokenAccount)
      const info2 = parsed.info
      if (info2.owner as string) {
        return new PublicKey(info2.owner as string)
      }
    }
  }
  return undefined
}

export const ownersFromMint = async (connection: Connection, mint: PublicKey): Promise<string[]> => {
  const ownersAcctRes = await connection.getTokenLargestAccounts(mint)
  const ownersATAPubkeys = ownersAcctRes.value.map((ownerAcct) => ownerAcct.address)
  const owners: string[] = []
  const results = await Promise.all(
    ownersATAPubkeys.map(async (ownerATAPubkey) => {
      const result = (await connection.getParsedAccountInfo(ownerATAPubkey)).value
      if (result && 'parsed' in result.data) {
        const info = create(result.data.parsed, ParsedInfo)
        if (result.data.program == 'spl-token') {
          const parsed = create(info, TokenAccount)
          const info2 = parsed.info
          if (info2.owner as string) {
            owners.push(info2.owner as string)
          }
        }
      }
    })
  )
  return owners
}

export const BID_SIZE = 32 + 32 + 32 + 8 + 8 + 1 + 1 + 1 + 8 + 50
export const SALE_SIZE = 32 + 32 + 32 + 8 + 1 + 32 + 50
export const ASK_SIZE = 32 + 32 + 8 + 8 + 1 + 1 + 8 + 1 + 50

export async function bidFromMint(connection: Connection, bidReceiptMint: PublicKey): Promise<PublicKey | undefined> {
  const apolloProgram = new PublicKey(apolloProgramAddress())
  console.log('bidFromMint apollo', apolloProgram.toBase58())
  console.log('bidReceiptMint', bidReceiptMint.toBase58())
  const fetchAllRes = await connection.getProgramAccounts(apolloProgram, {
    filters: [
      {
        dataSize: BID_SIZE,
      },
      {
        memcmp: {
          offset: 8 + 32,
          bytes: bidReceiptMint.toBase58(),
        },
      },
    ],
  })
  console.log('bid fetchAllRes', fetchAllRes)
  if (fetchAllRes.length === 0) {
    return undefined
  }
  return fetchAllRes[0].pubkey

  // random from fetchAllRes array
  // const
  // const bundleAccount = fetchAllRes[0]
  // for (const res in fetchAllRes) {
  //   console.log('res', res)
  //   const pubkey = fetchAllRes[res].pubkey
  //   console.log('pubkey', pubkey.toBase58())
  //   const bidRes = await apollo.account.bid.fetch(pubkey)
  //   const bid: BidV2 = decodeBidV2(bidRes)
  //   if (bid.receiptMint.toBase58() !== bidReceiptMint.toBase58()) {
  //     continue
  //   } else {
  //     return pubkey
  //   }
  // }
  return undefined
}

export async function asksFromSale(
  connection: Connection,
  apollo: Program,
  sale: PublicKey
): Promise<AskV2[] | undefined> {
  const apolloProgram = new PublicKey(apolloProgramAddress())
  // console.log('apolloProgram', apolloProgram.toBase58())
  // console.log('bundleMint', bundleMint.toBase58())
  const fetchAllRes = await connection.getProgramAccounts(apolloProgram, {
    filters: [
      {
        dataSize: ASK_SIZE,
      },
      {
        memcmp: {
          offset: 8,
          bytes: sale.toBase58(),
        },
      },
    ],
  })
  console.log('asks fetchAllRes', fetchAllRes)
  if (fetchAllRes.length === 0) {
    return undefined
  }
  // TODO: fix so filtering is done in request (only one will load now)
  // const bundleAccount = fetchAllRes[0]
  const asks = []
  for (const askAccount of fetchAllRes) {
    const pubkey = askAccount.pubkey
    const askRes = await apollo.account.ask.fetch(pubkey)
    const ask: AskV2 = decodeAskV2(askRes)
    ask.pubkey = pubkey.toBase58()
    asks.push(ask)
  }

  return asks
}

export async function ownerFromSale(
  connection: Connection,
  saleReceiptMint: PublicKey
): Promise<PublicKey | undefined> {
  const apolloProgram = new PublicKey(apolloProgramAddress())
  // console.log('apolloProgram', apolloProgram.toBase58())
  // console.log('bundleMint', bundleMint.toBase58())
  const ownerAcctRes = await connection.getTokenLargestAccounts(saleReceiptMint)
  const ownerATAPubkey = ownerAcctRes.value[0].address
  const result = (await connection.getParsedAccountInfo(ownerATAPubkey)).value
  if (result && 'parsed' in result.data) {
    const info = create(result.data.parsed, ParsedInfo)
    if (result.data.program == 'spl-token') {
      const parsed = create(info, TokenAccount)
      const info2 = parsed.info
      if (info2.owner as string) {
        return new PublicKey(info2.owner as string)
      }
      console.log('info2', info2)
    }
  }
  return undefined
}

export async function bidsFromSale(
  connection: Connection,
  apollo: Program,
  sale: PublicKey
): Promise<BidV2[] | undefined> {
  const apolloProgram = new PublicKey(apolloProgramAddress())
  // console.log('apolloProgram', apolloProgram.toBase58())
  // console.log('bundleMint', bundleMint.toBase58())
  const fetchAllRes = await connection.getProgramAccounts(apolloProgram, {
    filters: [
      {
        dataSize: BID_SIZE,
      },
      {
        memcmp: {
          offset: 8,
          bytes: sale.toBase58(),
        },
      },
    ],
  })
  if (fetchAllRes.length === 0) {
    return undefined
  }
  const bids = []
  for (const bidAccount of fetchAllRes) {
    const pubkey = bidAccount.pubkey
    console.log('pubkey', pubkey)
    const bidRes = await apollo.account.bid.fetch(pubkey)
    console.log('bidRes', bidRes)
    const bid: BidV2 = decodeBidV2(bidRes)
    bid.pubkey = pubkey.toBase58()
    const receiptMintPubkey = pubkeyFromString(bid.receiptMint)
    if (bid.sale == sale.toBase58() && receiptMintPubkey) {
      const owner = await ownerFromMint(connection, apollo, receiptMintPubkey)
      if (owner) {
        bid.owner = owner.toBase58()
      }
      bids.push(bid)
    }
  }

  return bids
}

async function metadataFromMint(saleTokenMint: PublicKey): Promise<MetadataCombined | undefined> {
  const metadata = await getMetadata(saleTokenMint, providerURL())
  if (metadata) {
    const extended = await getExtendedArt(metadata)
    if (!extended) {
      console.error('Extended not found for ' + saleTokenMint.toBase58())
      const r = { ...metadata }
      console.log(r)
    }
    const res = {
      ...metadata,
      extended,
    }
    try {
      const validated = create(res, MetadataCombined)
      return validated
    } catch {
      throw new Error('Metadata not valid for ' + saleTokenMint.toBase58() + ': ' + res)
    }
    return undefined
  }
  return undefined
}

export async function candyMachineFromMint(connection: Connection, mint: PublicKey): Promise<PublicKey | undefined> {
  const apolloProgram = new PublicKey(apolloProgramAddress())
  // console.log('apolloProgram', apolloProgram.toBase58())
  // console.log('bundleMint', bundleMint.toBase58())
  // const fetchAllRes = await connection.getProgramAccounts(apolloProgram, {
  //   filters: [
  //     {
  //       dataSize: BID_SIZE,
  //     },
  //     {
  //       memcmp: {
  //         offset: 8,
  //         bytes: sale.toBase58(),
  //       },
  //     },
  //   ],
  // })
  // if (fetchAllRes.length === 0) {
  //   return undefined
  // }
  // const bids = []
  // for (const bidAccount of fetchAllRes) {
  //   const pubkey = bidAccount.pubkey
  //   console.log('pubkey', pubkey)
  //   const bidRes = await apollo.account.bid.fetch(pubkey)
  //   console.log('bidRes', bidRes)
  //   const bid: BidV2 = decodeBidV2(bidRes)
  //   bid.pubkey = pubkey
  //   if (bid.sale.toBase58() == sale.toBase58()) {
  //     bid.owner = await ownerFromMint(connection, apollo, bid.receiptMint)
  //     bids.push(bid)
  //   }
  // }

  return undefined
}
