import { PrivacyPolicyModal } from 'components/PrivacyPolicy'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useRef } from 'react'
import { useModalOpen, useToggleModal } from 'state/application/hooks'

import { FilterButton, FilterCoverText, StyledChevronDown, StyledMenu } from '../styled'
import MintsPriceFlyout from './MintsPriceFlyout'

function MintsPriceCoverButton({ toggle }: { toggle: () => void }) {
  return (
    <FilterButton onClick={toggle} aria-label={`Menu`}>
      <FilterCoverText>Price</FilterCoverText>
      <StyledChevronDown size={'18px'} />
    </FilterButton>
  )
}

export default function MintsPriceFilter() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen('MintsPriceFilter')
  const toggleMenu = useToggleModal('MintsPriceFilter')
  useOnClickOutside(node, open ? toggleMenu : undefined)

  return (
    <>
      <StyledMenu ref={node as any}>
        <MintsPriceCoverButton toggle={toggleMenu} />
        {open && <MintsPriceFlyout />}
      </StyledMenu>
      <PrivacyPolicyModal />
    </>
  )
}
