import { createReducer } from '@reduxjs/toolkit'

import {
  addRefinementListValue,
  clearAllValues,
  removeRefinementListValue,
  resetToCollectionPage,
  resetToExploreCollections,
  setSearchIndex,
  setSearchQuery,
} from './actions'

export enum SearchIndex {
  Mint = 'mints4',
  Collections = 'collectionsA1',
}

export enum SearchSaleStateKey {
  LiveSale = 'liveSale',
  LiveOffer = 'liveOffer',
}

export enum SearchRefinementIndex {
  SaleState = 'saleState',
  Collection = 'featuredInCollections',
  SavedBy = 'savedBy',
  // LiveOffer = 'liveOffer',
}

export interface SearchRefinement {
  index: string
  value: string
}

export interface SearchState {
  readonly index: SearchIndex
  readonly refinementList: SearchRefinement[]
  readonly query: string
}

const DEFAULT_REFINEMENT_LIST: SearchRefinement[] = [
  {
    index: SearchRefinementIndex.SaleState,
    value: SearchSaleStateKey.LiveSale,
  },
  {
    index: SearchRefinementIndex.SaleState,
    value: SearchSaleStateKey.LiveOffer,
  },
]
const DEFAULT_QUERY = ''

const exploreDefaultState = {
  index: SearchIndex.Mint,
  refinementList: DEFAULT_REFINEMENT_LIST,
  query: DEFAULT_QUERY,
}

const COLLECTION_REFINEMENT_LIST: SearchRefinement[] = [
  {
    index: SearchRefinementIndex.Collection,
    value: '',
  },
]
const collectionInitalState = {
  index: SearchIndex.Mint,
  refinementList: COLLECTION_REFINEMENT_LIST,
  query: DEFAULT_QUERY,
}

export default createReducer(exploreDefaultState, (builder) =>
  builder
    .addCase(setSearchQuery, (state, { payload: query }) => {
      console.log('state', state)
      state = {
        ...state,
        query,
      }
      return state
    })
    .addCase(setSearchIndex, (state, { payload: index }) => {
      state = {
        ...state,
        index,
      }
      return state
    })
    .addCase(addRefinementListValue, (state, { payload: { index, value } }) => {
      console.log('state', state)
      state = {
        ...state,
        refinementList: [
          ...state.refinementList,
          {
            index,
            value,
          },
        ],
      }
      return state
    })
    .addCase(removeRefinementListValue, (state, { payload: { index, value } }) => {
      console.log('state', state)
      state = {
        ...state,
        refinementList: [...state.refinementList.filter((r) => !(r.index == index && r.value == value))],
      }
      return state
    })
    .addCase(clearAllValues, (state, { payload: { index } }) => {
      console.log('state', state)
      state = {
        ...state,
        refinementList: [...state.refinementList.filter((r) => r.index != index)],
      }
      return state
    })
    .addCase(resetToCollectionPage, (state, { payload: { collectionID } }) => {
      console.log('state', state)
      state = {
        ...collectionInitalState,
        refinementList: [
          {
            index: SearchRefinementIndex.Collection,
            value: collectionID,
          },
        ],
      }
      return state
    })
    .addCase(resetToExploreCollections, (state, { payload: { param } }) => {
      state = {
        ...state,
        // ...exploreDefaultState,
        refinementList: [],
      }
      return state
    })
)
