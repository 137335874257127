import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'state/hooks'
import { useSafeSales } from 'state/sales/hooks'

import { AppState } from '../index'
import { addRefinementListValue } from './actions'
import { MintData } from './models'
import { SearchState } from './reducer'

export function useSearchState(): SearchState {
  console.log('mSearch useSearchState')
  return useAppSelector((state: AppState) => {
    return state.search
  })
}

export function useRefinementListValue(index?: string, value?: string): boolean {
  console.log('mSearch useRefinementValue')
  const searchState = useSearchState()
  return useMemo(() => {
    const refinement = searchState.refinementList.find((r) => r.index === index && r.value === value)
    return refinement?.value ? true : false
  }, [index, searchState.refinementList, value])
}

// export function useSetRefinementListValue(index: string, value: string): void {
//   console.log('mSearch useSetRefinementValue')
//   const dispatch = useDispatch()
//   dispatch(addRefinementListValue({ index, value }))
// }
