import { Trans } from '@lingui/macro'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import AnimatedDropdown from 'components/AnimatedDropdown'
import { ButtonSecondary, ButtonSmall } from 'components/Button'
import Card, { OutlineCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import Row, { AutoRow, RowBetween, RowFixed } from 'components/Row'
import { useApolloProgram } from 'components/SolanaManager'
import { StyledLogo } from 'components/SolTokenLogo'
import { MouseoverTooltipContent } from 'components/Tooltip'
import { darken, transparentize } from 'polished'
import { useCollectionQuery, useFetchCollectionQuery } from 'providers/collectionQuery'
import { useEffect, useState } from 'react'
import { Archive, ChevronDown, Info } from 'react-feather'
import styled, { keyframes, useTheme } from 'styled-components/macro'
import { HideSmall, TYPE } from 'theme'

// import { FilterContent } from './styled'

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: center;
`

export const SelectCollectionButton = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border-radius: 12px;
  border: none;
  background: ${({ theme }) => theme.bg0};
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 2px;
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 10px;
  align-items: center;
  color: ${({ theme }) => theme.headerText};
  transition: 50ms ease color;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.headerText};
  transition: 50ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.text1};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 50ms ease border-color;
  left: -3px;
  top: -3px;
`

const FilterContent = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  width: 100%;
  margin-top: 10px;
  padding: 0px 2px;
  border-radius: 12px;
  background-color: ${({ open, theme }) =>
    open ? transparentize(1.0, theme.headerBG) : transparentize(1.0, theme.headerBG)};
  color: ${({ theme }) => theme.headerText};

  align-items: center;
  // cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  :hover {
    cursor: initial;
    // text-decoration: underline;
  }
  min-height: 50px;

  :hover {
    // background-color: ${({ open, theme }) =>
      open ? transparentize(0.0, theme.headerBG) : transparentize(0.1, theme.headerBG)};
  }
`

const NewButton = styled(ButtonSmall)`
  color: ${({ theme }) => theme.blue1};
  margin-right: 10px;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:hover {
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:active {
    // box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
`

const TitleText = styled(TYPE.sectionHeader)`
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.text2};
`

interface FilterDropdownProps {
  loading?: boolean
  title: string
  icon?: () => React.ReactElement
  content?: () => React.ReactElement
  onClickNew: () => void
}

export default function SidebarDropdown({
  loading,
  title,
  icon,
  content,
  onClickNew,
  children,
}: React.PropsWithChildren<FilterDropdownProps>) {
  const [showDetails, setShowDetails] = useState(true)
  const theme = useTheme()

  return (
    <Wrapper>
      <AutoColumn gap={'8px'} style={{ width: '100%' }}>
        <FilterContent disabled={false} open={showDetails}>
          {/* <RowFixed style={{ position: 'relative' }}> */}
          {/* {loading ? (
              <StyledPolling>
                <StyledPollingDot>
                  <Spinner />
                </StyledPollingDot>
              </StyledPolling>
            ) : (
              <HideSmall>{icon && icon()}</HideSmall>
            )} */}
          <TitleText
            onClick={() => setShowDetails(!showDetails)}
            // color={theme.headerText}
            fontSize={'24px'}
            // style={{ cursor: 'pointer' }}
          >
            {title}
          </TitleText>
          {/* </RowFixed> */}
          {/* <RowFixed> */}
          <RowFixed gap="0px">
            <NewButton
              onClick={() => {
                onClickNew()
              }}
            >
              New
            </NewButton>
            {/* <RotatingArrow stroke={theme.bg3} open={Boolean(showDetails)} /> */}
          </RowFixed>
          {/* </RowFixed> */}
        </FilterContent>
        <AnimatedDropdown open={showDetails}>{children}</AnimatedDropdown>
      </AutoColumn>
    </Wrapper>
  )
}
