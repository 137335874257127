import { Trans } from '@lingui/macro'
import { TokenInfoMap } from '@solana/spl-token-registry'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { Currency } from '@uniswap/sdk-core'
import Badge, { BadgeVariant } from 'components/Badge'
import { ArtContent } from 'components/BundleArtDisplay/ArtContent'
import { SavedIcon } from 'components/Button'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import ExplorerLink from 'components/ExplorerLink'
import Loader from 'components/Loader'
import { RowBetween, RowFixed } from 'components/Row'
import SolTokenLogo from 'components/SolTokenLogo'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import { useListColor } from 'hooks/useColor'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { Account, useAccountInfo, useFetchAccountInfo } from 'providers/accounts'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useCallback, useEffect } from 'react'
import { Grid } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Flex } from 'rebass'
import { getColorFromUriPath } from 'state/mints/firebase'
import { useSafeMint } from 'state/mints/hooks'
import { MintData } from 'state/mints/models'
import { editSavedMint } from 'state/users/actions'
import { useEditSavedMint } from 'state/users/firebase'
import { useMintIsSaved, useSafeUser } from 'state/users/hooks'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'
import { truncateString } from 'utils'
import { MetadataAttribute } from 'validators/accounts/sales'

const Wrapper = styled.div<{ bgColor?: string }>`
  ${({ theme }) => theme.flexColumnNoWrap} // background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.transparent};
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // margin: 0;
  // padding: 1rem 1rem 1rem 3rem;
  // width: 100%;
  width: 100%;
`

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg0};
`

export const AttributeBox = styled.div<{ bgColor: string }>`
  margin-left: 8px;
  margin-top: 8px;
  // height: 1px;
  width: fit-content;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 5px;
  background-color: ${({ theme }) => theme.transparent};
  // border: 2px solid ${({ bgColor, theme }) => bgColor};
  border: 2px solid ${({ bgColor, theme }) => transparentize(0.3, bgColor)};
  border-radius: 1rem;
  filter: ${({ bgColor, theme }) => `drop-shadow(0px 2.76515px 5.7083px ${transparentize(0.7, bgColor)})`};
`

export const AttributeContainer = styled.div`
  max-width: 400px;
  display: flex;

  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch;
  // align-items: start;
  // justify-content: center;

  overflow: auto;
`

const TokenImgWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Section = styled(AutoColumn)`
  // padding: 15px;
  width: 100%;
`

export enum MintInfoField {
  Name = 'name',
  Collection = 'collection',
  Description = 'description',
  Mint = 'mint',
  Attributes = 'attributes',
}

export default function MintInfoContent({
  mintAddress,
  size,
  hidden,
}: {
  mintAddress?: string
  size?: string
  hidden?: MintInfoField[]
}) {
  const mint = useSafeMint(mintAddress)
  const mintColor = useListColor(mint?.metadata?.extended?.image)
  const { tokenRegistry } = useTokenRegistry()
  const theme = useTheme()
  const dispatch = useDispatch()
  const wallet = useAnchorWallet()
  const userPubkey = wallet?.publicKey

  const user = useSafeUser(userPubkey?.toBase58())

  const mintIsSaved = useMintIsSaved(userPubkey?.toBase58(), mint?.address)
  const editSavedMint = useEditSavedMint()

  const toggleMintSaved = useCallback(() => {
    if (!userPubkey || !mint || !user) return
    if (mintIsSaved) {
      editSavedMint(user, mint.address, false)
    } else {
      editSavedMint(user, mint.address, true)
    }
  }, [userPubkey, mint, user, mintIsSaved, editSavedMint])

  // const [color, setColor] = React.useState<string>('')
  // useEffect(() => {
  //   const getColor = async () => {
  //     const color = await getColorFromUriPath(mint?.metadata?.extended?.image)
  //     setColor(color ?? '')
  //   }
  //   getColor()
  // }, [mint])

  if (mint) {
    if (mint.isNFT) {
      const metadata = mint.metadata
      if (!metadata) {
        return <Loader></Loader>
      }
      return (
        <Wrapper bgColor={mintColor}>
          <Section>
            {/* {metadata.extended?.collection?.name && (
              <AutoColumn>
                <TYPE.subHeader>{metadata.extended.collection.name}</TYPE.subHeader>
              </AutoColumn>
            )} */}
            {!hidden?.includes(MintInfoField.Name) && metadata.extended?.name && (
              <RowBetween width={'100%'}>
                {/* <AutoColumn> */}
                <TYPE.heavy color={theme.text2} fontSize={42}>
                  {metadata.extended.name}
                </TYPE.heavy>
                {/* </AutoColumn> */}
                {/* <AutoColumn> */}
                {/* <SavedIcon onClick={toggleMintSaved} fill={mintIsSaved} size={'40px'} /> */}
                <RowFixed onClick={toggleMintSaved}>
                  <SavedIcon fill={mintIsSaved} size={'20px'} />
                  {mint.savedByUsers && (
                    <TYPE.heavy
                      style={{ marginLeft: '8px', minWidth: '0.5rem', textAlign: 'right' }}
                      color={theme.text2}
                    >{`${mint.savedByUsers.length}`}</TYPE.heavy>
                  )}
                </RowFixed>
              </RowBetween>
            )}
            <SectionBreak />
            {/* {!hidden?.includes(MintInfoField.Collection) && metadata.extended?.collection?.name && (
              <AutoColumn>
                <TYPE.label color={theme.text3}>Collection</TYPE.label>
                <TYPE.heavy color={theme.text2} fontSize={24}>
                  {metadata.extended.collection.name}
                </TYPE.heavy>
              </AutoColumn>
            )} */}
            <SectionBreak />
            {!hidden?.includes(MintInfoField.Description) && metadata.extended?.description && (
              <AutoColumn>
                <TYPE.label color={theme.text3}>Description</TYPE.label>
                <TYPE.heavy color={theme.text2} fontSize={18}>
                  {metadata.extended.description}
                </TYPE.heavy>
              </AutoColumn>
            )}
            <SectionBreak />
            {!hidden?.includes(MintInfoField.Mint) && mint.address && (
              <AutoColumn>
                <TYPE.label color={theme.text3}>Mint</TYPE.label>
                <TYPE.heavy color={theme.text2} fontSize={18}>
                  <ExplorerLink address={mint.address}></ExplorerLink>
                </TYPE.heavy>
              </AutoColumn>
            )}
            {!hidden?.includes(MintInfoField.Mint) && (
              <>
                <SectionBreak />
                <AutoColumn style={{ marginBottom: '2px' }}>
                  <TYPE.label color={theme.text3}>Attributes</TYPE.label>
                </AutoColumn>
                <AutoColumn gap="4px">
                  <AttributeContainer>
                    {metadata?.extended?.attributes?.map((a: MetadataAttribute) => (
                      // <Badge key={a.trait_type} variant={BadgeVariant.DEFAULT}>
                      <AttributeBox key={a.trait_type} bgColor={mintColor}>
                        <TYPE.small fontWeight={400}>{a.trait_type.toUpperCase()}</TYPE.small>
                        <TYPE.label>{a.value}</TYPE.label>
                      </AttributeBox>

                      // </Badge>
                    ))}
                    {/* <TYPE.main>{manifestData?.description}</TYPE.main> */}
                  </AttributeContainer>
                </AutoColumn>
              </>
            )}
          </Section>
        </Wrapper>
      )
    } else {
      const tokenInfo = mint.tokenInfo ?? tokenRegistry.get(mint.address)
      if (!tokenInfo) {
        return <Loader></Loader>
      }
      return (
        <Wrapper bgColor={mintColor}>
          <Section>
            <AutoColumn>
              <TYPE.heavy color={theme.text2}>{tokenInfo?.symbol}</TYPE.heavy>
            </AutoColumn>
            <AutoColumn>
              <TYPE.largeHeader color={theme.text1}>{tokenInfo?.name}</TYPE.largeHeader>
            </AutoColumn>
            <SectionBreak />
          </Section>
          <Section>
            <AutoColumn>
              <TYPE.heavy color={theme.text2}>Mint Address</TYPE.heavy>
            </AutoColumn>
            <AutoColumn>
              <TYPE.heavy color={theme.text1}>{mint.address}</TYPE.heavy>

              {/* <TYPE.main>{mint.address}</TYPE.main> */}
            </AutoColumn>
            <SectionBreak />
          </Section>
          {tokenInfo?.tags && tokenInfo?.tags?.length > 0 && (
            <Section>
              <AutoColumn style={{ marginBottom: '2px' }}>
                <TYPE.sectionHeader>Tags</TYPE.sectionHeader>
              </AutoColumn>
              <AutoColumn gap="4px">
                {tokenInfo?.tags?.map((t) => (
                  <Badge key={t} variant={BadgeVariant.DEFAULT}>
                    <AutoColumn gap="4px" justify={'center'}>
                      {/* <TYPE.small fontWeight={400}>{t.toUpperCase()}</TYPE.small> */}
                      <TYPE.label color={theme.text3}>{t}</TYPE.label>
                    </AutoColumn>
                  </Badge>
                ))}
                {/* <TYPE.main>{manifestData?.description}</TYPE.main> */}
              </AutoColumn>
              <SectionBreak />
            </Section>
          )}
        </Wrapper>
      )
    }
  }
  return <Loader></Loader>
}
