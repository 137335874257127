import { Trans } from '@lingui/macro'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { ButtonAction, ButtonPrimary } from 'components/Button'
import { DarkGreyCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import MintCard from 'components/MintCard'
import { RowBetween } from 'components/Row'
import { useApolloProgram } from 'components/SolanaManager'
import { ErrorText, Wrapper } from 'components/swap/styleds'
import TXConfirmationModal, { ConfirmationModalContent } from 'components/TXConfirmationModal'
import { useListColor } from 'hooks/useColor'
import useTheme from 'hooks/useTheme'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMints } from 'state/mints/hooks'
import { useUserSavedMints } from 'state/users/hooks'
import { User } from 'state/users/models'
import styled from 'styled-components/macro'
import { StyledInternalLink, TYPE } from 'theme'

import { UserPageBottomWrapper } from './styled'

export const ResponsiveTwoColumns = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: row;

  padding-top: 0px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;

    margin-top: 0;
  `};
`

const InfoWrapper = styled(LightCard)`
  ${({ theme }) => theme.flexColumnNoWrap}
  background: ${({ theme }) => theme.bg2};
  margin: 30px 20px;
  padding: 0px;
`

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;

  background-color: ${({ theme }) => theme.bg1};
`

export const Section = styled(AutoColumn)`
  padding: 10px 15px;
`

enum TXType {
  VOID_ASK = 'VOID_ASK',
  ACCEPT_ASK = 'ACCEPT_ASK',
  CANCEL_SALE = 'CANCEL_SALE',
  VOID_BID = 'VOID_BID',
  ACCEPT_BID = 'ACCEPT_BID',
}

const Grid = styled(AutoColumn)`
  display: grid;
  width: 100%;
  max-width: 1200px;
  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  // grid-gap: 1rem;
  grid-gap: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

export function UserSavedMints({ user }: { user?: User }) {
  const dispatch = useDispatch()

  // const userMints = useMemo(() => {
  //   return user?.ownedMints.map((mint) => mint.address) ?? []
  // }, [user])

  const userSavedMints = useUserSavedMints(user?.address)

  console.log('UserOwnedUsersPage user', user)

  const mints = useSafeMints(userSavedMints)

  useEffect(() => {
    dispatch(addRequiredMints(userSavedMints))
  }, [dispatch, userSavedMints])

  const userColor = useListColor(mints.find(() => true)?.metadata?.extended?.image)

  const mintDatas = useSafeMints(userSavedMints)
  // console.log('UserOwnedUsersPage', UserOwnedUsersPage)

  return user ? (
    !user ? (
      <LoadingOpacityContainer $loading={true}></LoadingOpacityContainer>
    ) : (
      <>
        <UserPageBottomWrapper bgColor={userColor}>
          <Grid>
            {mints && mints?.length === 0 && (
              <InfoWrapper>
                <RowBetween>
                  <TYPE.body>No tokens found</TYPE.body>
                </RowBetween>
              </InfoWrapper>
            )}
            {userSavedMints &&
              userSavedMints
                .filter((m) => {
                  // return m.
                  const md = mintDatas.find((md) => md.address == m)
                  if (md) {
                    return md.isNFT
                  }
                  return false
                })
                ?.map((m) => {
                  return <MintCard key={m} mintAddress={m} />
                })}
          </Grid>
        </UserPageBottomWrapper>
        {}
        {}
      </>
    )
  ) : (
    <ErrorText>Bundle not found</ErrorText>
  )
}
