import { array, boolean, Infer, nullable, number, optional, string, type } from 'superstruct'
import { MetadataCombined } from 'validators/accounts/sales'

// export interface TokenExtensions {
//   readonly website?: string;
//   readonly bridgeContract?: string;
//   readonly assetContract?: string;
//   readonly address?: string;
//   readonly explorer?: string;
//   readonly twitter?: string;
//   readonly github?: string;
//   readonly medium?: string;
//   readonly tgann?: string;
//   readonly tggroup?: string;
//   readonly discord?: string;
//   readonly serumV3Usdt?: string;
//   readonly serumV3Usdc?: string;
//   readonly coingeckoId?: string;
//   readonly imageUrl?: string;
//   readonly description?: string;
// }
// export interface TokenInfo {
//   readonly chainId: number;
//   readonly address: string;
//   readonly name: string;
//   readonly decimals: number;
//   readonly symbol: string;
//   readonly logoURI?: string;
//   readonly tags?: string[];
//   readonly extensions?: TokenExtensions;
// }

export type TokenExtensions = Infer<typeof TokenExtensions>
export const TokenExtensions = type({
  website: optional(string()),
  bridgeContract: optional(string()),
  assetContract: optional(string()),
  address: optional(string()),
  explorer: optional(string()),
  twitter: optional(string()),
  github: optional(string()),
  medium: optional(string()),
  tgann: optional(string()),
  tggroup: optional(string()),
  discord: optional(string()),
  serumV3Usdt: optional(string()),
  serumV3Usdc: optional(string()),
  coingeckoId: optional(string()),
  imageUrl: optional(string()),
  description: optional(string()),
})

export type TokenInfo = Infer<typeof TokenInfo>
export const TokenInfo = type({
  chainId: number(),
  address: string(),
  name: string(),
  decimals: number(),
  symbol: string(),
  logoURI: optional(string()),
  tags: optional(array(string())),
  extensions: optional(TokenExtensions),
})

export type MintData = Infer<typeof MintData>
export const MintData = type({
  address: string(),
  isNFT: boolean(),
  metadata: optional(MetadataCombined),
  tokenInfo: optional(TokenInfo),
  owners: optional(array(string())),
  color: optional(nullable(string())),
  savedByUsers: optional(array(string())),
  featuredInCollections: optional(array(string())),
})
