import { number } from '@lingui/core/cjs/formats'
import { PublicKey } from '@solana/web3.js'
import { ArtContent, contentStyle } from 'components/BundleArtDisplay/ArtContent'
import Card, { LightCard, OutlineCard } from 'components/Card'
import AmountStepCounter from 'components/create/AmountStepCounter'
import Loader from 'components/Loader'
import { loadingAnimation } from 'components/Loader/styled'
import { LoadingView } from 'components/ModalViews'
import SolTokenLogo from 'components/SolTokenLogo'
import { ErrorText, Wrapper } from 'components/swap/styleds'
import { motion } from 'framer-motion'
import { useAccountInfo, useFetchAccountInfo } from 'providers/accounts'
import { EditionData } from 'providers/accounts/utils/metadataHelpers'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React from 'react'
// import { ArtContent } from './ArtContent'
import styled from 'styled-components/macro'
import { CustomLightSpinner, TYPE } from 'theme'
// import React, { Fragment, useState } from 'react'
import { classNames } from 'utils/utils'
import { MetadataExtension } from 'validators/accounts/sales'

// import { Circle } from 'react-feather'
import Circle from '../../assets/images/blue-loader.svg'

const TokenImgWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SelectArtCard = ({
  address,
  mintAddress,
  ownedQuantity,
  mintDecimals,
  selectedQuantity,
  setSelectedQuantity,
  disabled,
  width,
  hideIfNFT,
  hideIfToken,
}: {
  address: string
  mintAddress: string
  ownedQuantity: number
  mintDecimals: number
  selectedQuantity: number
  setSelectedQuantity: (address: string, amount: number, mintAddress: string) => void
  width?: string
  disabled?: boolean
  hideIfNFT?: boolean
  hideIfToken?: boolean
}): JSX.Element | null => {
  const fetchAccount = useFetchAccountInfo()
  const mintInfo = useAccountInfo(mintAddress)

  React.useEffect(() => {
    if (!mintInfo && mintAddress) {
      console.log('fetching mint info')
      fetchAccount(mintAddress)
    }
  }, [fetchAccount, mintAddress, mintInfo])

  const account = mintInfo?.data
  const data = account?.details?.data
  const isToken = data?.program === 'spl-token' && data?.parsed.type === 'mint'
  const nftData = (data as any)?.nftData
  const metadata = nftData?.metadata

  const { tokenRegistry } = useTokenRegistry()
  const tokenDetails = tokenRegistry.get(mintAddress)
  // if (!metadata || !isToken) {
  //   return null
  // }
  if (hideIfNFT && metadata) {
    return null
  }

  if (hideIfToken && !metadata) {
    return null
  }

  return (
    <Card width={width} key={address} padding={'0.5rem'} style={{ marginTop: '1px' }}>
      {tokenDetails?.logoURI ? (
        // <img
        //   src={tokenDetails.logoURI}
        //   style={contentStyle}
        //   alt="token logo"
        //   className="avatar-img rounded-circle border border-4 border-body"
        // />
        <TokenImgWrapper>
          <SolTokenLogo size={'100%'} clickable={true} currency={tokenRegistry.get(mintAddress)} />
        </TokenImgWrapper>
      ) : metadata ? (
        <ArtContent metadata={metadata} pubkey={mintAddress} clickable />
      ) : (
        <CustomLightSpinner src={Circle} alt="loader" size={'100%'} />
      )}
      <TYPE.main>
        {tokenDetails?.name
          ? tokenDetails?.name
          : metadata?.data?.name !== ''
          ? metadata?.data?.name
          : 'No NFT name was found'}
        {}
      </TYPE.main>
      {ownedQuantity ? (
        <div style={{ marginTop: '0.2rem' }}>
          <AmountStepCounter
            value={`${selectedQuantity}`}
            decrement={() => {
              setSelectedQuantity(address, selectedQuantity - 1, mintAddress)
              return `${selectedQuantity - 1}`
            }}
            increment={() => {
              setSelectedQuantity(address, selectedQuantity + 1, mintAddress)
              return `${selectedQuantity + 1}`
            }}
            decrementDisabled={disabled || selectedQuantity === 0}
            incrementDisabled={disabled || selectedQuantity >= ownedQuantity}
            onUserInput={(value: string) => {
              const floatValue = parseFloat(value)
              console.log('floatValue', floatValue)
              if (floatValue > ownedQuantity) {
                setSelectedQuantity(address, ownedQuantity, mintAddress)
              } else if (floatValue < 0) {
                setSelectedQuantity(address, 0, mintAddress)
              } else {
                const numDecimals = mintDecimals
                const roundedValue = Math.round(floatValue * 10 ** numDecimals) / 10 ** numDecimals
                setSelectedQuantity(address, roundedValue, mintAddress)
              }
              setSelectedQuantity(address, parseFloat(value), mintAddress)
            }}
            title={`${ownedQuantity} Owned`}
          />
        </div>
      ) : (
        <Loader></Loader>
      )}
    </Card>
  )
}

export default SelectArtCard
