import { PublicKey } from '@solana/web3.js'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { useActiveWeb3React } from '../../hooks/web3'
import { AppState } from '../index'

export function useHomepageSales(): string[] {
  console.log('aState useHomepageSales')
  return useAppSelector((state: AppState) => {
    console.log('aState useHomepageSales useAppSelector state: ', state)
    return state.apollo.currentHomepageSales
    return []
  })
}

export function useCollectionIDsFilter(): string[] {
  return useAppSelector((state: AppState) => {
    return state.apollo.collectionIDsFilter
    return []
  })
}
