// eslint-disable-next-line no-restricted-imports
import { ChevronDown } from 'react-feather'
import styled from 'styled-components/macro'

import { FilterButton, FilterCoverText, StyledChevronDown } from '../styled'

export default function MintsCollectionCoverButton({ toggle }: { toggle: () => void }) {
  return (
    <FilterButton onClick={toggle} aria-label={`Menu`}>
      <FilterCoverText>Collections</FilterCoverText>
      <StyledChevronDown size={'18px'} />
    </FilterButton>
  )
}
