import { Trans } from '@lingui/macro'
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { pubkeyFromString } from 'apollo/utils'
import { BidsEmptyState, SalesEmptyState } from 'components/bundles/BundlesEmptyState'
import { BaseButton, ButtonAction } from 'components/Button'
import { AutoColumn } from 'components/Column'
import ExplorerLink from 'components/ExplorerLink'
import { AutoRow, RowBetween } from 'components/Row'
import SaleCard from 'components/SaleCard'
import { useApolloProgram } from 'components/SolanaManager'
import Toggle from 'components/Toggle'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import { ContentPageWrapper } from 'pages/ExplorePage'
import { useBidAccounts, useFetchBidAccounts } from 'providers/accounts/bid'
import { useAccountOwnedTokens, useFetchAccountOwnedTokens } from 'providers/accounts/tokens'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { useModalOpen, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { addRequiredSales } from 'state/sales/actions'
import { useSafeSales } from 'state/sales/hooks'
import { addRequiredUsers } from 'state/users/actions'
import { useSafeUser } from 'state/users/hooks'
import styled from 'styled-components/macro'
import { StyledInternalLink, TYPE } from 'theme'
import { AskV2, BidV2 } from 'validators/accounts/sales'

import { UserOwnedUsersPage } from './UserOwnedMints'
import { UserSavedMints } from './UserSavedMints'

const PageWrapper = styled.div`
  // width: 90%;
  // overflow-y: scroll;
`

const SectionButton = styled(BaseButton)<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) => (isActive ? theme.bg5 : theme.bg1)};
  color: ${({ isActive, theme }) => (isActive ? theme.text5 : theme.secondary1)};
  
  text-decoration: none !important;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  width: fit-content;
  hover: {
    background-color: ${({ isActive, theme }) => (isActive ? theme.bg0 : theme.bg1)};
    color: ${({ isActive, theme }) => (isActive ? theme.text1 : theme.text1)};
  },
`

const ButtonsGrid = styled(AutoRow)`
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`

const BottomContentContainer = styled(AutoColumn)`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

enum BottomSection {
  Owned = 'Owned',
  Saved = 'Saved',
  ForSale = 'ForSale',
  Offers = 'Offers',
  Activity = 'Activity',
}

const LogoWrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function UserPage({
  match: {
    params: { pubkey: userPubkeyFromURL, action: actionFromURL },
  },
}: RouteComponentProps<{ pubkey?: string; action?: string }>) {
  // const wallet = useAnchorWallet()
  const apollo = useApolloProgram()
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const { publicKey, wallet, disconnect, connecting, connected, wallets } = useWallet()

  const walletModalToggle = useWalletModalToggle()
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)

  // const userPubkey = pubkeyFromString(userPubkeyFromURL)
  const [userPubkey, setUserPubkey] = useState<PublicKey | undefined>(undefined)

  useEffect(() => {
    if (userPubkeyFromURL) {
      setUserPubkey(pubkeyFromString(userPubkeyFromURL))
    }
  }, [userPubkeyFromURL])

  const user = useSafeUser(userPubkey?.toBase58())

  useEffect(() => {
    if (userPubkey) {
      dispatch(addRequiredUsers([userPubkey.toBase58()]))
    }
  }, [userPubkey, dispatch])

  const [bottomSection, setBottomSection] = useState<BottomSection>(BottomSection.Owned)

  const bottomContent = () => {
    // if (mintData?.mint.closed) {
    //   return <BundlePageClosedView mintData={mintData} />
    // }
    if (actionFromURL == 'saved') {
      return <UserSavedMints user={user} />
    }
    return <UserOwnedUsersPage user={user} />
    // if (actionFromURL == 'trade') {
    //   return <MintCreateBuyOrder mintData={mintData} isTrade={true} />
    // }
    // if (actionFromURL == 'ask') {
    //   return <BundleAddAskView mintData={mintData} />
    // }
    return <div></div>
  }

  return userPubkey ? (
    <>
      <ContentPageWrapper>
        <AutoColumn>
          <AutoRow>
            <LogoWrapper>
              <UserLogo size="10rem" userAddress={userPubkey.toBase58()}></UserLogo>
            </LogoWrapper>
          </AutoRow>
          <AutoRow align={'center'} justify={'center'} marginTop={'30px'}>
            <TYPE.heavy fontSize={'32px'}>
              <ExplorerLink address={userPubkey.toBase58()} />
            </TYPE.heavy>
          </AutoRow>
        </AutoColumn>
        <ButtonsGrid>
          <SectionButton
            as={StyledInternalLink}
            to={`/user/${userPubkey.toBase58()}/u`}
            isActive={history.location.pathname.includes(`/user/${userPubkey.toBase58()}/u`)}
          >
            Owned
          </SectionButton>
          <SectionButton
            as={StyledInternalLink}
            to={`/user/${userPubkey.toBase58()}/saved`}
            isActive={history.location.pathname.includes(`/user/${userPubkey.toBase58()}/saved`)}
          >
            Created
          </SectionButton>
          <SectionButton
            as={StyledInternalLink}
            to={`/user/${userPubkey.toBase58()}/saved`}
            isActive={history.location.pathname.includes(`/user/${userPubkey.toBase58()}/saved`)}
          >
            Collections
          </SectionButton>
          <SectionButton
            as={StyledInternalLink}
            to={`/user/${userPubkey.toBase58()}/sales`}
            isActive={history.location.pathname.includes(`/user/${userPubkey.toBase58()}/sales`)}
          >
            Bids
          </SectionButton>
          <SectionButton
            as={StyledInternalLink}
            to={`/user/${userPubkey.toBase58()}/offers`}
            isActive={history.location.pathname.includes(`/user/${userPubkey.toBase58()}/offers`)}
            // onClick={() => setBottomSection(BottomSection.Offers)}
          >
            Following
          </SectionButton>
          <SectionButton
            as={StyledInternalLink}
            to={`/user/${userPubkey.toBase58()}/activity`}
            isActive={history.location.pathname.includes(`/user/${userPubkey.toBase58()}/activity`)}
            // onClick={() => setBottomSection(BottomSection.Activity)}
          >
            Activity
          </SectionButton>
          {userPubkey?.toBase58() == publicKey?.toBase58() && (
            <SectionButton
              // width={'100%'}
              as={'button'}
              style={{ backgroundColor: theme.transparent }}
              isActive={false}
              onClick={() => {
                console.log('clicked')
                disconnect()
                // walletModalToggle()
              }}
            >
              <TYPE.heavy fontSize={'14px'} color={theme.blue1}>
                Disconnect
              </TYPE.heavy>
            </SectionButton>
          )}
          <BottomContentContainer>{bottomContent()}</BottomContentContainer>
        </ButtonsGrid>
      </ContentPageWrapper>
    </>
  ) : (
    <>
      <PageWrapper>
        <AutoRow>
          <TYPE.heavy>User not found</TYPE.heavy>
        </AutoRow>
      </PageWrapper>
    </>
  )
}
