import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { BaseButton } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import OrderModalBase from 'components/OrderModalBase'
import { RowBetween } from 'components/Row'
import {
  ConfirmationPendingContent,
  TransactionErrorContent,
  TransactionSubmittedContent,
} from 'components/TXConfirmationModal'
import useLast from 'hooks/useLast'
import { CollectionEditor } from 'pages/CreateCollection/CollectionEditor'
import { ContentPageWrapper } from 'pages/ExplorePage'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDeleteCollection, useEditCollectionInfo } from 'state/collections/firebase'
import { useCollection, useCollectionsFirebaseRequestByRequestID } from 'state/collections/hooks'
import { RequestStatus } from 'state/collections/reducer'
import styled from 'styled-components/macro'
import { CloseIcon, TYPE } from 'theme'

import { useEditCollectionModalConfig, useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal, setEditCollectionModalConfig } from '../../state/application/reducer'
import { ContentWrapper, PaddedColumn, SubmitFooter } from './styled'

export default function DeleteCollectionModal() {
  const wallet = useAnchorWallet()
  const userPubkey = wallet?.publicKey
  const dispatch = useDispatch()
  const isOpen = useModalOpen(ApplicationModal.DELETE_COLLECTION)
  const toggleModal = useToggleModal(ApplicationModal.DELETE_COLLECTION)
  const lastOpen = useLast(isOpen)
  const config = useEditCollectionModalConfig()
  const collectionID = useMemo(() => config?.collectionID, [config])
  const collection = useCollection(collectionID)

  const [requestID, setRequestID] = useState<string | null>(null)

  const firebaseRequest = useCollectionsFirebaseRequestByRequestID(requestID ?? undefined)
  const firebaseSubmitError = useMemo(() => {
    if (!requestID) return null
    if (!firebaseRequest) return null
    return firebaseRequest.errorMessage ?? null
  }, [requestID, firebaseRequest])

  const handleDismiss = useCallback(() => {
    setRequestID(null)
    toggleModal()
    dispatch(setEditCollectionModalConfig({ collectionID: undefined }))
  }, [dispatch, toggleModal])

  const validationError = useMemo(() => {
    // if (descriptionValue == '') {
    //   return 'Description is required'
    // }
    return null
  }, [])

  const deleteCollection = useDeleteCollection()

  const handleSubmit = useCallback(() => {
    const run = async () => {
      console.log('collectionModal handleSubmit run')
      if (!collection) {
        console.error('collectionModal Collection not found')
        return
      }
      if (userPubkey?.toBase58() == collection.user) {
        console.log('collectionModal submitting')
        const requestID = await deleteCollection(collection)
        // console.log('collectionModal requestID', requestID)
        setRequestID(requestID)
      } else {
        console.error('collectionModal You do not have permission to edit this collection')
      }
    }
    run()
  }, [collection, deleteCollection, userPubkey])

  const submissionContent = () => {
    return firebaseSubmitError ? (
      <TransactionErrorContent message={firebaseSubmitError} onDismiss={handleDismiss}></TransactionErrorContent>
    ) : firebaseRequest?.status == RequestStatus.Fulfilled ? (
      <TransactionSubmittedContent hash={undefined} onDismiss={handleDismiss} doneLink={undefined} />
    ) : (
      <ConfirmationPendingContent onDismiss={handleDismiss} pendingText={'pendingText'} />
    )
  }

  return (
    <OrderModalBase isOpen={isOpen} onDismiss={handleDismiss} minHeight={false} maxHeight={700}>
      {requestID ? (
        submissionContent()
      ) : (
        <ContentWrapper>
          <PaddedColumn gap="16px">
            <RowBetween>
              <TYPE.heavy fontWeight={500} fontSize={22}>
                Edit Collection
              </TYPE.heavy>
              <CloseIcon onClick={handleDismiss} />
            </RowBetween>
          </PaddedColumn>
          <PaddedColumn gap="16px"></PaddedColumn>

          <SubmitFooter
            disabled={validationError != null}
            onClick={() => {
              handleSubmit()
            }}
          >
            <TYPE.error error={validationError != null}>{validationError ?? 'Save'}</TYPE.error>
          </SubmitFooter>
        </ContentWrapper>
      )}
    </OrderModalBase>
  )
}
