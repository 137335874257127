import { useWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { BaseButton, ButtonSmallText } from 'components/Button'
import { DarkCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import ExplorerLink from 'components/ExplorerLink'
import GenericArtDisplay from 'components/GenericArtDisplay'
import MintArtDisplay from 'components/MintArtDisplay'
import { AutoRow, RowBetween } from 'components/Row'
import SolTokenLogo from 'components/SolTokenLogo'
import UserLogo from 'components/UserLogo'
import { useListColor } from 'hooks/useColor'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { useSafeMint } from 'state/mints/hooks'
import { ORDER_SIDE, useMatchBuyOrder, useMatchSellOrder, useRevokeOrder } from 'state/orders/chain'
import { useChainRequestsByID } from 'state/orders/hooks'
import { Order } from 'state/orders/models'
import { RequestStatus } from 'state/orders/reducer'
import styled from 'styled-components/macro'
import { ExternalLink, ExternalLinkIcon, Label, StyledInternalLink, TYPE } from 'theme'
import { shortenAddress } from 'utils'
import { solExplorerAddressURL, solExplorerTXURL } from 'utils/getExplorerLink'
import { formatDateDiff, mintIsNative } from 'utils/utils'

export const Wrapper = styled.div<{ xColor: string }>`
  width: 100%;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.transparent};
  // border: 2px solid ${({ xColor, theme }) => transparentize(0.3, xColor)};
  border-radius: 1rem;
  // filter: ${({ xColor, theme }) => `drop-shadow(0px 2.76515px 5.7083px ${transparentize(0.7, xColor)})`};
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 3fr repeat(4, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
    > *:first-child {
      display: none;
    }
    > *:nth-child(3) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const ResponsiveLogo = styled(CurrencyLogo)`
  @media screen and (max-width: 670px) {
    width: 16px;
    height: 16px;
  }
`

const SORT_FIELD = {
  amount: 'amount',
  price: 'price',
  pricePerToken: 'pricePerToken',
  by: 'by',
  expires: 'expires',
}

enum OrderActionButton {
  Cancel = 'Cancel',
  BuyNow = 'BuyNow',
  AcceptOffer = 'AcceptOffer',
  None = 'None',
}

const OrderButton = styled(BaseButton)<{ xColor?: string }>`
  // ${({ theme }) => theme.flexColumnNoWrap}
  border: ${({ xColor, theme }) => `2px solid ${xColor ?? theme.primary1}`};
  padding: ${({ padding }) => padding ?? '10px 14px'};
  font-weight: 600;
  // width: 100%;
  background-color: ${({ theme }) => transparentize(0.5, theme.bg0)};
  color: ${({ theme }) => theme.text5};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.2, theme.bg0)};
    color: ${({ theme }) => theme.text4};
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.2, theme.bg0)};
    color: ${({ theme }) => theme.text4};
  }
  &:active {
    background-color: ${({ theme }) => transparentize(0.2, theme.bg0)};
    color: ${({ theme }) => theme.text4};
  }
`

const BuyNowButton = styled(OrderButton)`
  padding: 8px 32px;
  width: fit-content;
  // background-color: ${({ theme }) => theme.blue1};
  border: none;
  // border: ${({ theme }) => `2px solid ${theme.bg0}`};
  background-color: ${({ theme }) => transparentize(0.1, theme.blue1)};
  color: ${({ theme }) => theme.text5};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.0, theme.blue1)};
    color: ${({ theme }) => theme.text5};
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.0, theme.blue1)};
    color: ${({ theme }) => theme.text5};
  }
  &:active {
    background-color: ${({ theme }) => transparentize(0.0, theme.blue1)};
    color: ${({ theme }) => theme.text5};
  }
`

const CancelButton = styled(OrderButton)`
  padding: 5px 20px;
  width: fit-content;
  border: none;
  border: ${({ theme }) => `2px solid ${theme.text3}`};
  background-color: ${({ theme }) => transparentize(0.1, theme.bg0)};
  color: ${({ theme }) => theme.text2};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.0, theme.bg0)};
    color: ${({ theme }) => theme.text2};
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.0, theme.bg0)};
    color: ${({ theme }) => theme.text2};
  }
  &:active {
    background-color: ${({ theme }) => transparentize(0.0, theme.bg0)};
    color: ${({ theme }) => theme.text2};
  }
`

const AcceptButton = styled(OrderButton)`
  padding: 5px 20px;
  width: fit-content;
  border: none;
  // border: ${({ theme }) => `2px solid ${theme.text3}`};
  background-color: ${({ theme }) => transparentize(0.1, theme.green1)};
  color: ${({ theme }) => theme.white};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.0, theme.green1)};
    color: ${({ theme }) => theme.white};
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.0, theme.green1)};
    color: ${({ theme }) => theme.white};
  }
  &:active {
    background-color: ${({ theme }) => transparentize(0.0, theme.green1)};
    color: ${({ theme }) => theme.white};
  }
`

function LabelWithContent({ label, children }: React.PropsWithChildren<{ label: string }>) {
  return (
    <AutoColumn>
      <AutoRow>
        <TYPE.heavy color={'text3'} fontSize={'12px'}>
          {label}
        </TYPE.heavy>
      </AutoRow>
      {children}
    </AutoColumn>
  )
}

export default function OrderCard({ order, index }: { order: Order; index: number }) {
  // theming
  const theme = useTheme()
  const { publicKey } = useWallet()
  const assetMint = useSafeMint(order.assetMint)
  const paymentMint = useSafeMint(order.paymentMint)
  const mintColor = useListColor(
    // order.side == ORDER_SIDE.BUY
    //   ?
    paymentMint?.isNFT ? paymentMint.metadata?.extended?.image : paymentMint?.tokenInfo?.logoURI
    // : assetMint?.metadata?.extended?.image
  )

  // formatDateDiff

  const expirationCountdownFormatted = useMemo(() => {
    const expirationDate = new Date(order.expirationDate * 1)
    return formatDateDiff(new Date(), expirationDate, true)
  }, [order.expirationDate])

  const [requestID, setRequestID] = useState<string | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const [res, setRes] = useState<string | undefined>()

  const matchSellOrder = useMatchSellOrder()
  const matchBuyOrder = useMatchBuyOrder()
  const revokeOrder = useRevokeOrder()

  // const buyNowError
  const orderRequests = useChainRequestsByID(requestID)

  useEffect(() => {
    const errorRequest = orderRequests?.find((request) => request.status === RequestStatus.Rejected)
    const fulfilledRequest = orderRequests?.find((request) => request.status === RequestStatus.Fulfilled)
    if (errorRequest) {
      setErrorMessage(errorRequest.errorMessage)
    }
    if (fulfilledRequest) {
      setRes(fulfilledRequest.txRes)
    }
  }, [orderRequests])

  const handleBuyNow = useCallback(() => {
    const run = async () => {
      if (!assetMint) {
        setErrorMessage('Asset mint not found')
        return
      }
      const requestID = await matchSellOrder(order, assetMint)
      console.log('ordercard requestID', requestID)
      setRequestID(requestID)
    }
    run()
  }, [matchSellOrder, order, assetMint])

  const handleAcceptOffer = useCallback(() => {
    const run = async () => {
      if (!assetMint) {
        setErrorMessage('Asset mint not found')
        return
      }
      // if (!paymentMint) {
      //   setErrorMessage('Payment mint not found')
      //   return
      // }
      const requestID = await matchBuyOrder(order, assetMint)
      console.log('ordercard requestID', requestID)
      setRequestID(requestID)
    }
    run()
  }, [assetMint, matchBuyOrder, order])

  const handleCancel = useCallback(() => {
    const run = async () => {
      const requestID = await revokeOrder(order)
      console.log('ordercard requestID', requestID)
      setRequestID(requestID)
    }
    run()
  }, [revokeOrder, order])

  const buttonType = useMemo(() => {
    if (order.maker == publicKey?.toBase58()) {
      return OrderActionButton.Cancel
    } else if (order.side == ORDER_SIDE.SELL) {
      return OrderActionButton.BuyNow
    } else if (order.side == ORDER_SIDE.BUY) {
      return OrderActionButton.AcceptOffer
    }
    return OrderActionButton.None
  }, [order, publicKey])

  return (
    <Wrapper xColor={mintColor}>
      <RowBetween alignItems={'baseline'}>
        <LabelWithContent label={'Price'}>
          <AutoRow>
            <MintArtDisplay mintAddress={order.paymentMint} size={'32px'} />
            <TYPE.heavy color={'text1'} fontSize={'36px'} marginLeft={'10px'}>
              {mintIsNative(order.paymentMint) ? order.paymentBaseSize / LAMPORTS_PER_SOL : order.paymentBaseSize / 1}
            </TYPE.heavy>
          </AutoRow>
        </LabelWithContent>
        <AutoColumn justify="end">
          {buttonType == OrderActionButton.BuyNow ? (
            <BuyNowButton
              onClick={() => {
                handleBuyNow()
              }}
            >
              Buy Now
            </BuyNowButton>
          ) : buttonType == OrderActionButton.Cancel ? (
            <CancelButton
              onClick={() => {
                handleCancel()
              }}
            >
              Cancel
            </CancelButton>
          ) : buttonType == OrderActionButton.AcceptOffer ? (
            <AcceptButton
              onClick={() => {
                handleAcceptOffer()
              }}
            >
              Accept
            </AcceptButton>
          ) : (
            <></>
          )}
          {!errorMessage?.includes('User rejected') && (
            <>
              <TYPE.error error={errorMessage != undefined}>{errorMessage}</TYPE.error>
              {res && (
                <ExternalLink href={solExplorerTXURL(res)}>
                  <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                    View on Explorer
                  </Text>
                </ExternalLink>
              )}
            </>
          )}
        </AutoColumn>
      </RowBetween>
      <AutoRow marginTop={'10px'} gap={'5px'}>
        <LabelWithContent label={order.side == ORDER_SIDE.BUY ? 'By' : 'Seller'}>
          {/* <StyledInternalLink to={`/user/${order.maker}`}>
            <AutoRow padding={'5px 0px'}>
              <UserLogo size="30px" userAddress={order.maker}></UserLogo>
              <TYPE.heavy fontSize={'14px'} marginLeft="10px" color={theme.text2}>
                {order.maker ? shortenAddress(order.maker, 6) : 'N/A'}
              </TYPE.heavy>
            </AutoRow>
          </StyledInternalLink> */}
          <ButtonSmallText as={Link} to={`/user/${order.maker}`}>
            <AutoRow padding={'5px 0px'}>
              <UserLogo size="30px" userAddress={order.maker}></UserLogo>
              <TYPE.heavy fontSize={'14px'} marginLeft="10px" color={theme.text2}>
                {order.maker ? shortenAddress(order.maker, 6) : 'N/A'}
                {/* <ExternalLinkIcon href={solExplorerAddressURL(order.maker)} /> */}
              </TYPE.heavy>
            </AutoRow>
          </ButtonSmallText>
        </LabelWithContent>
        <LabelWithContent label={'Expires'}>
          <AutoRow padding={'5px 0px'}>
            <TYPE.heavy fontSize={'14px'} color={theme.text2}>
              {expirationCountdownFormatted}
            </TYPE.heavy>
          </AutoRow>
        </LabelWithContent>
        <LabelWithContent label={'Fees'}>
          <AutoRow padding={'5px 2px'}>
            {/* <TYPE.heavy color={theme.text2} fontSize={'12px'}>
              {`Creators: ${(100 * order.creatorsFeeSize) / order.paymentBaseSize}%, Curator: ${
                (100 * order.curatorFeeSize) / order.paymentBaseSize
              }%`}
            </TYPE.heavy> */}
            <MintArtDisplay mintAddress={order.paymentMint} size={'10px'} />
            <TYPE.heavy color={'text2'} fontSize={'12px'} marginLeft={'3px'}>
              {mintIsNative(order.paymentMint) ? order.paymentBaseSize / LAMPORTS_PER_SOL : order.paymentBaseSize / 1}
            </TYPE.heavy>
          </AutoRow>
        </LabelWithContent>
      </AutoRow>
    </Wrapper>
  )
}
