import { darken } from 'polished'
import { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { truncateString } from 'utils'

// import { ReactComponent as LeftArrow } from '../../assets/svg/niceNavRightArrow.svg'
import { ReactComponent as Arrow } from '../../assets/svg/niceNavArrow.svg'
import { ReactComponent as Circle } from '../../assets/svg/niceNavCircle.svg'
import { ReactComponent as Slash } from '../../assets/svg/niceNavSlash.svg'

const StyledArrow = styled(Arrow)<{ disabled: boolean; left?: boolean }>`
  // margin: 0 0rem 0 0rem;
  // height: 35%;

  path {
    stroke: ${({ disabled, theme }) => (disabled ? theme.text4 : theme.text2)};
    stroke-width: ${({ disabled }) => (disabled ? '3px' : '3px')}};
  }
  // if left flip horizontally
  ${({ left }) => left && 'transform: scaleX(-1)'}
`
const StyledSlash = styled(Slash)`
  // margin: 0 0.25rem 0 0.35rem;
  // height: 35%;

  path {
    stroke: ${({ theme }) => theme.text2};
    stroke-width: 3px;
  }
`
const StyledCircle = styled(Circle)`
  // margin: 0 0.25rem 0 0.35rem;
  // height: 35%;

  path {
    // stroke: ${({ theme }) => theme.text2};
    stroke-width: 3px;
  }
`

const activeClassName = 'ACTIVE'

export const NiceNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;
  padding: 2px 6px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 8px 6px;
  `}
  width: fit-content;
  display: flex;
  margin: 0 0px;
  // font-weight: 600;
  align-items: center;
  height: 100%;
  margin: 0 0px;
  // font-weight: 600;

  &.${activeClassName} {
    border-radius: 12px;
    // background-color: ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.text3};
    font-weight: 600;
  }

  :hover {
    color: ${({ theme }) => darken(0.1, theme.text2)};
  }
`

const Wrapper = styled.div`
  display: flex;
  // flex-flow: row;
  align-items: center;
  width: 100%;
  // border-bottom: 1px solid ${({ theme }) => theme.bg2};
  padding: 0px 0px 10px 0px;
  // justify-content: center;
  // z-index: 1;
  // overflow-x: hidden;
`
export default function NiceNav() {
  const location = useLocation()
  const pathname = useMemo(() => location.pathname, [location])
  console.log('NiceNav pathname', pathname)
  const path = useMemo(() => pathname.split('/').filter(Boolean), [pathname])
  const isCollectionPage = useMemo(() => path.includes('c'), [path])
  const isMintPage = useMemo(() => path.includes('m'), [path])
  const isFeedPage = useMemo(() => path.includes('explore'), [path])
  const columns = useMemo(() => {
    const cols = []
    if (isCollectionPage || isMintPage) {
      cols.push(
        <NiceNavLink to={'/explore'}>
          <StyledArrow key={'arrow'} left={false} disabled={true} />
        </NiceNavLink>
      )
      cols.push(
        <NiceNavLink to={'/explore'}>
          <StyledArrow key={'arrow'} left={true} disabled={false} />
        </NiceNavLink>
      )
    }

    cols.push(
      <NiceNavLink to={'/explore'} style={{ marginLeft: '5px' }}>
        <StyledCircle />
      </NiceNavLink>
    )

    if (isCollectionPage) {
      cols.push(
        <NiceNavLink to={'/explore'}>
          <StyledSlash />
        </NiceNavLink>
      )
      cols.push(
        <NiceNavLink key={'collections'} to={`/c`}>
          Collections
        </NiceNavLink>
      )
      if (path.length == 1) return cols
      cols.push(<StyledSlash />)
      cols.push(
        <NiceNavLink key={'p'} to={`/c/${path[1]}`}>
          {path[1]}
        </NiceNavLink>
      )
      return cols
    }
    if (isMintPage) {
      cols.push(
        <NiceNavLink to={'/explore'}>
          <StyledSlash />
        </NiceNavLink>
      )
      cols.push(
        <NiceNavLink key={'mint'} to={`/m`}>
          Mint
        </NiceNavLink>
      )
      if (path.length == 1) return cols
      cols.push(<StyledSlash />)
      cols.push(
        <NiceNavLink key={'p'} to={`/m/${path[1]}`}>
          {truncateString(path[1])}
        </NiceNavLink>
      )
      return cols
    }
    if (isFeedPage) {
      cols.push(
        <NiceNavLink to={'/explore'}>
          <StyledSlash />
        </NiceNavLink>
      )
      cols.push(
        <NiceNavLink key={'feed'} to={`/explore`}>
          Explore
        </NiceNavLink>
      )
      // if (path.length == 1) return cols
    }
    return cols
  }, [isCollectionPage, isFeedPage, isMintPage, path])
  // const columns = useMemo(() => {
  //   const columns = path.map((p, i) => {
  //     if (p == 'c') {
  //       return (
  //         // <AutoColumn gsap="md" key={p}>
  //         [
  //           <StyledNavLink key={i} to={`/${path.slice(0, i + 1).join('/')}`}>
  //             Collections
  //           </StyledNavLink>,
  //         ]
  //         // </AutoColumn>
  //       )
  //     }
  //     return (
  //       // <AutoColumn gap="md" key={p}>

  //       [
  //         <StyledNavLink key={i} to={path}>
  //           {truncateString(p)}
  //         </StyledNavLink>,
  //       ]
  //       // </AutoColumn>
  //     )
  //   })

  //   return columns.flat()
  // }, [path, path])
  return columns ? (
    <Wrapper>
      {columns.map((c, i) => (
        <div key={i}>{c}</div>
      ))}
    </Wrapper>
  ) : (
    <>Err</>
  )
}
