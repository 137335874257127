// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { PublicKey } from '@solana/web3.js'
import { MetadataRecord } from 'components/BundleArtDisplay/ArtContent'
import SelectArtCard from 'components/SelectArtCard'
import { useApolloProgram } from 'components/SolanaManager'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import useToggle from 'hooks/useToggle'
import { SelectedToken } from 'pages/CreateSale'
import { useAccountOwnedTokens, useFetchAccountOwnedTokens } from 'providers/accounts/tokens'
import { FetchStatus } from 'providers/cache'
import { useTokenRegistry } from 'providers/mints/token-registry'
import { useEffect, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components/macro'

import { AutoRow } from '../Row'

export const TwoColumnGrid = styled.div`
  display: grid;
  // grid-column-gap: 5px;
  // grid-row-gap: 15px;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: max-content;
  // grid-auto-flow: row;

  // padding-top: 20px;
  max-width: 100%;
  overflow: contain;

  // border-top: 1px solid ${({ theme }) => theme.bg2};

  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   grid-template-columns: 1fr;

  //   margin-top: 0;
  // `};
`
const ContentWrapper = styled(AutoRow)`
  width: 100%;
  padding: 1rem;
`

interface NFTSearchProps {
  userPubkey: PublicKey
  selectedMints: SelectedToken[]
  setSelectedMints: (mints: SelectedToken[]) => void
  hideIfNFT?: boolean
  hideIfToken?: boolean
}

export function NFTSelectList({ userPubkey, selectedMints, setSelectedMints, hideIfNFT, hideIfToken }: NFTSearchProps) {
  const { tokenRegistry } = useTokenRegistry()
  // console.log('userPubkey', userPubkey.toBase58())

  const apollo = useApolloProgram()
  const theme = useTheme()

  const fixedList = useRef<FixedSizeList>()
  const [hasFetchedOwnedTokens, setHasFetchedOwnedTokens] = useState(false)
  const ownedTokens = useAccountOwnedTokens(userPubkey.toBase58())
  const fetchAccountTokens = useFetchAccountOwnedTokens()

  useEffect(() => {
    if (!hasFetchedOwnedTokens) {
      fetchAccountTokens(userPubkey.toBase58())
      setHasFetchedOwnedTokens(true)
    }
  }, [fetchAccountTokens, hasFetchedOwnedTokens, userPubkey])

  const [metadataRecords, setMetadataRecords] = useState<MetadataRecord[]>([])

  const detailsList: React.ReactNode[] = []

  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)

  if (ownedTokens?.status === FetchStatus.Fetching) {
    return <Trans>Fetching Owned Tokens</Trans>
  }
  if (ownedTokens?.data?.tokens) {
    return (
      <AutoRow gap="1%">
        {ownedTokens.data.tokens
          .filter((token) => {
            return !token.info.bid && !token.info.sale
          })
          .map((token) => {
            // console.log(token.info.mint.toBase58())
            // const tokenDetails = tokenRegistry.get(token.info.mint.toBase58())
            const address = token.pubkey.toBase58()
            const ownedQuantity = parseFloat(token.info.tokenAmount.uiAmountString)
            const selectedQuantity = selectedMints.find((s) => s.address == address)?.amount
            const disabled =
              selectedMints.filter((s) => s.address != address)?.reduce((acc, s) => acc + s.amount, 0) > 0

            // const selected
            // return (
            //   <AutoRow key={address}>
            //     <Trans>{address}</Trans>
            //   </AutoRow>
            // )
            if (ownedQuantity == 0) {
              return null
            }
            return (
              <div key={address} style={{ width: '48%' }}>
                <SelectArtCard
                  key={address}
                  width="100%"
                  address={token.pubkey.toBase58()}
                  mintAddress={token.info.mint.toBase58()}
                  mintDecimals={token.info.tokenAmount.decimals}
                  ownedQuantity={ownedQuantity}
                  disabled={disabled}
                  selectedQuantity={selectedQuantity ? selectedQuantity : 0}
                  setSelectedQuantity={(address, amount, mintAddress) => {
                    console.log('setSelectedQuantity', address, amount, mintAddress)
                    const updatedMints = selectedMints.map((s) => {
                      if (s.address === address) {
                        console.log('setSelectedQuantity2', address, amount)
                        return { address, amount, mintAddress }
                      }
                      return s
                    })
                    if (updatedMints.length === 0 || updatedMints.find((s) => s.address === address) == null) {
                      setSelectedMints([...updatedMints, { address, amount, mintAddress }])
                    } else {
                      setSelectedMints(updatedMints)
                    }
                  }}
                  hideIfNFT={hideIfNFT}
                  hideIfToken={hideIfToken}
                ></SelectArtCard>
              </div>
            )
          })}
      </AutoRow>
    )
  }

  return <Trans>No Tokens Found</Trans>
}
