import Loader from 'components/Loader'
import { Wrapper } from 'components/swap/styleds'
import { useAvatarSVG } from 'hooks/useColor'
import { hsl } from 'polished'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useNFTModalToggle, useSetNFTModalMintAddress } from 'state/application/hooks'
import styled from 'styled-components/macro'

import Logo from '../Logo'

export const StyledLogo = styled(Logo)<{ clickable?: boolean; size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ clickable }) => (clickable ? 'rgba(37, 41, 46, 0.2) 0px 10px 30px;' : 'none')};
  :hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'none')};
    transform: ${({ clickable }) => (clickable ? 'scale(1.1)' : 'none')};
  }
  transition: ${({ clickable }) => (clickable ? ' all 0.2s ease-in-out' : 'none')};
`

const WrapperButton = styled(Wrapper)`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  // width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
`

// const stringHash = require('string-hash')
// const hslTriad = require('hsl-triad')
// const hslRgb = require('hsl-rgb')

export default function UserLogo({
  userAddress,
  size = '24px',
  style,
  clickable,
  navToProfile = true,
  ...rest
}: {
  userAddress?: string
  size?: string
  clickable?: boolean
  navToProfile?: boolean
  style?: React.CSSProperties
}) {
  const toggleNFTModal = useNFTModalToggle()
  const setNFTModalAddr = useSetNFTModalMintAddress()
  const history = useHistory()
  // if (!currency || currency.isNative) return []

  const avatarSVG = useAvatarSVG(userAddress, size)
  //   if (currency.isToken) {
  //     const defaultUrls = []
  //     const url = getTokenLogoURL(currency.address, currency.chainId)
  //     if (url) {
  //       defaultUrls.push(url)
  //     }
  //     if (currency instanceof WrappedTokenInfo) {
  //       return [...uriLocations, ...defaultUrls]
  //     }
  //     return defaultUrls
  //   }
  //   return []
  // }, [currency, uriLocations])

  // if (currency?.isNative) {
  //   return <StyledEthereumLogo src={EthereumLogo} alt="ethereum logo" size={size} style={style} {...rest} />
  // }
  const userLogoSeed = useMemo(() => userAddress + 'a', [userAddress])
  console.log('userLogoSeed', userLogoSeed)
  if (userAddress) {
    return (
      <WrapperButton
        onClick={() => {
          if (navToProfile) {
            history.push(`/user/${userAddress}`)
          }
        }}
      >
        <StyledLogo
          size={size}
          srcs={[`data:image/svg+xml;base64,${btoa(avatarSVG)}`]}
          clickable={clickable}
          alt={`${userAddress ?? 'user'} logo`}
          style={style}
          {...rest}
        />
      </WrapperButton>
    )
  } else {
    return <Loader></Loader>
  }
}

{
  /* <Image
alt={name}
src={`data:image/svg+xml;base64,${btoa(gradientAvatar(uid))}`}
{...sizeProps}
{...props}
/> */
}
