import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { ButtonLight, ButtonSmall } from 'components/Button'
import CollectionLogo from 'components/CollectionLogo'
import { AutoColumn, ColumnBetween } from 'components/Column'
import MintsCollectionFilter from 'components/filter/MintsCollectionFilter'
import MintsPriceFilter from 'components/filter/MintsPriceFilter'
import MintsStatusFilter from 'components/filter/MintsStatusFilter'
import MintCard from 'components/MintCard'
import NiceNav from 'components/NiceNav'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import { useApolloProgram } from 'components/SolanaManager'
import { ToggleWrapper } from 'components/Toggle/MultiToggle'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import { ContentPageWrapper, ExploreToggleElement, Grid, GridWrapper } from 'pages/ExplorePage'
import { PageWrapper } from 'pages/styled'
import { transparentize } from 'polished'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInfiniteHits } from 'react-instantsearch-hooks'
import { useDispatch } from 'react-redux'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { useToggleModal } from 'state/application/hooks'
import { ApplicationModal, setEditCollectionModalConfig } from 'state/application/reducer'
import { addRequiredCollections } from 'state/collections/actions'
import { useCollection } from 'state/collections/hooks'
import { appendFirebaseMintIfEmpty } from 'state/mints/actions'
import { decodeFirebaseMintData } from 'state/mints/firebase'
import { resetToCollectionPage } from 'state/search/actions'
import { addRequiredUsers } from 'state/users/actions'
import { useSafeUser } from 'state/users/hooks'
import styled from 'styled-components/macro'
import { StyledInternalLink, TYPE } from 'theme'
import { shortenAddress } from 'utils'

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;
  background-color: ${({ theme }) => theme.bg1};
`

export const Section = styled(AutoColumn)`
  padding: 15px;
`

export const ArtContainer = styled(AutoColumn)`
  max-width: 500px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  max-width: 100%;

`};
`

export const SellBox = styled.div<{ bgColor?: string }>`
  background-color: ${({ theme }) => theme.bg1};
  width: 90%;
  margin: 5rem 5rem 0 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 90%;
  `};
  border: 2px solid ${({ bgColor }) => bgColor};
  border-radius: 1rem;
  filter: ${({ bgColor, theme }) =>
    `drop-shadow(0px 3.76515px 20.7083px ${transparentize(0.9, bgColor ?? theme.bg1)})`};
  height: 100%;
  width: 100%;
`

const CollectionPageWrapper = styled(PageWrapper)`
  padding: 70px 0px;
`

export const ResponsiveTopGrid = styled.div`
  margin-top: 10px;
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
  grid-template-columns: 200px 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: row;
  align-items: start;
  justify-content: start;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0px 10px;

  `};
`

const SaveButton = styled(ButtonSmall)`
  margin-left: 5px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.text3};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.red3};
  }
  &:hover {
  }
  &:active {
  }
`
const EditButton = styled(ButtonSmall)`
  padding: 5px 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.text2};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.text1};
  }
  &:hover {
  }
  &:active {
  }
`

export const TopBar = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid ${({ theme }) => transparentize(0, theme.bg3)};
  background: ${({ theme }) => transparentize(0.9, theme.bg1)};
`
export const TopBarLeftWrapper = styled.div`
  display: flex;
  width: fit-content;
  // justify-content: space-between;
  gap: 10px;
  align-items: center;
`
export const FiltersWrapper = styled.div`
  display: flex;
  width: fit-content;
  // justify-content: space-between;
  gap: 10px;
  align-items: center;
`
// const BottomWrapper = styled.div`
//   width: 80%;
//   max-width: 1400px;
//   // background-color: ${({ theme }) => theme.bg0};
// `

export default function CollectionPage({
  match: {
    params: { collectionID: collectionID },
  },
}: RouteComponentProps<{ collectionID?: string; action?: string }>) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const wallet = useAnchorWallet()
  const userPubkey = wallet?.publicKey
  const apollo = useApolloProgram()
  const theme = useTheme()
  const { pathname } = useLocation()

  const dispatch = useDispatch()

  const user = useSafeUser(userPubkey?.toBase58())

  useEffect(() => {
    if (userPubkey) {
      dispatch(addRequiredUsers([userPubkey.toBase58()]))
    }
  }, [dispatch, userPubkey])

  console.log('mintpage collectionID', collectionID)

  useEffect(() => {
    if (collectionID) {
      dispatch(resetToCollectionPage({ collectionID }))
    }
  }, [collectionID, dispatch])
  const collectionData = useCollection(collectionID)

  useEffect(() => {
    if (apollo && collectionID && !collectionData) {
      dispatch(addRequiredCollections([collectionID]))
    }
  }, [apollo, collectionData, collectionID, dispatch])

  // const { tokenRegistry } = useTokenRegistry()

  const [showConfirm, setShowConfirm] = useState(false)
  const [submitError, setSubmitError] = useState<string | undefined>()
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [clickedAskUUID, setClickedAskUUID] = useState<PublicKey | undefined>()

  const { hits, isLastPage, showMore } = useInfiniteHits()

  const mintDatas = useMemo(() => {
    return hits
      .map((mintData) => {
        try {
          return decodeFirebaseMintData(mintData)
        } catch (e) {
          console.log('ExplorePage decodeFirebaseMintData error', e)
        }
        return undefined
      })
      .filter((mintData) => mintData != undefined)
  }, [hits])
  useEffect(() => {
    for (const mintData of mintDatas) {
      if (mintData) dispatch(appendFirebaseMintIfEmpty(mintData))
    }
  }, [dispatch, mintDatas])

  const userIsOwner = useMemo(() => user?.address == collectionData?.user, [collectionData, user])
  const toggleEditModal = useToggleModal(ApplicationModal.EDIT_COLLECTION)
  const toggleDeleteModal = useToggleModal(ApplicationModal.DELETE_COLLECTION)

  const handleEdit = useCallback(() => {
    if (!userIsOwner || !collectionData) return
    dispatch(setEditCollectionModalConfig(collectionID))
    toggleEditModal()
  }, [collectionData, collectionID, dispatch, toggleEditModal, userIsOwner])

  const handleDelete = useCallback(() => {
    if (!userIsOwner || !collectionData) return
    dispatch(setEditCollectionModalConfig(collectionID))
    toggleDeleteModal()
  }, [collectionData, collectionID, dispatch, toggleDeleteModal, userIsOwner])

  const filteredMetadatas = useMemo(() => {
    return mintDatas.filter((mintData) => {
      const metadata = mintData?.metadata
      return metadata?.extended?.image ? true : false
    })
  }, [mintDatas])

  enum ExploreType {
    NFTs = 'all',
    Owners = 'owners',
    Activity = 'activity',
  }

  const displayType = useMemo(() => {
    switch (pathname) {
      case '/c/nfts':
        return ExploreType.NFTs
      case '/c/owners':
        return ExploreType.Owners
      case '/c/activity':
        return ExploreType.Activity
      default:
        return ExploreType.NFTs
    }
  }, [pathname])

  return collectionData ? (
    <>
      {}
      {/* <TXConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        errorText={submitError}
        doneLink={'/bundles'}
        content={() => (
          // 
          <ConfirmationModalContent
            title={<Trans>Confirm Instant Purchase</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={() => <Trans>Tokens</Trans>}
            bottomContent={() => (
              <TYPE.darkGray fontWeight={500} fontSize={20}>
                <Trans>Submit</Trans>
              </TYPE.darkGray>
            )}
          />
        )}
        pendingText={'...'}
      /> */}
      <ContentPageWrapper>
        <NiceNav></NiceNav>
        <ResponsiveTopGrid>
          <div onClick={handleEdit} style={{ cursor: userIsOwner ? 'pointer' : 'default' }}>
            <CollectionLogo collectionID={collectionData.id} size={'100%'}></CollectionLogo>
          </div>
          <ColumnBetween>
            <RowBetween align="start">
              <AutoColumn>
                <TYPE.label
                  onClick={handleEdit}
                  style={{ cursor: userIsOwner ? 'pointer' : 'default' }}
                  fontSize={'55px'}
                  color={theme.text2}
                >
                  {collectionData.name}
                </TYPE.label>
                <AutoRow>
                  <TYPE.darkGray fontSize={'20px'}>Curated by</TYPE.darkGray>
                  <StyledInternalLink to={`/user/${collectionData.user}`} style={{ marginLeft: '15px' }}>
                    <AutoRow padding={'5px 0px'}>
                      <UserLogo size="30px" userAddress={collectionData.user}></UserLogo>
                      <TYPE.heavy fontSize={'14px'} marginLeft="10px" color={theme.text2}>
                        {collectionData.user ? shortenAddress(collectionData.user, 6) : 'N/A'}
                        {/* <ExternalLinkIcon href={solExplorerAddressURL(order.maker)} /> */}
                      </TYPE.heavy>
                    </AutoRow>
                  </StyledInternalLink>
                </AutoRow>
              </AutoColumn>
              <RowFixed>
                {userIsOwner && <EditButton onClick={handleEdit}>Request Verification</EditButton>}
                {userIsOwner && <EditButton onClick={handleEdit}>Edit</EditButton>}
                <SaveButton onClick={handleDelete}>Delete</SaveButton>
              </RowFixed>
            </RowBetween>
          </ColumnBetween>
        </ResponsiveTopGrid>
        <TopBar>
          <TopBarLeftWrapper>
            {/* <ToggleWrapper>
              <ExploreToggleElement
                to={'/explore/mints'}
                isActive={(match, { pathname }) => displayType == ExploreType.NFTs}
              >
                NFTs
              </ExploreToggleElement>
              <ExploreToggleElement
                to={'/explore/collections'}
                isActive={(match, { pathname }) => displayType == ExploreType.Owners}
              >
                Collections
              </ExploreToggleElement>

              <ExploreToggleElement
                to={'/explore/activity'}
                isActive={(match, { pathname }) => displayType == ExploreType.Activity}
              >
                Activity
              </ExploreToggleElement>
            </ToggleWrapper> */}
            <FiltersWrapper>
              <MintsStatusFilter></MintsStatusFilter>
              <MintsCollectionFilter></MintsCollectionFilter>
              <MintsPriceFilter></MintsPriceFilter>
            </FiltersWrapper>
          </TopBarLeftWrapper>
          Sort
        </TopBar>
        <GridWrapper>
          <Grid>
            {filteredMetadatas && filteredMetadatas?.length === 0 && <>Empty</>}
            {filteredMetadatas
              .slice(
                0,
                !isLastPage ? filteredMetadatas.length - (filteredMetadatas.length % 3) : filteredMetadatas.length
              )
              .map((mint, i) => {
                return (
                  <AutoColumn key={i} style={{ padding: '10px' }}>
                    <MintCard key={mint?.address ?? 'i'} mintAddress={mint?.address} />
                  </AutoColumn>
                )
              })}
            <div style={{ height: '200px' }}></div>
            <div style={{ height: '200px' }}></div>
            <div style={{ height: '200px' }}></div>
          </Grid>

          {!isLastPage && (
            <ButtonLight width={'100%'} onClick={showMore}>
              Show more
            </ButtonLight>
          )}
        </GridWrapper>
        {JSON.stringify(collectionData.mints, null, 2)}
      </ContentPageWrapper>
    </>
  ) : (
    <TYPE.subHeader>Collection Not Found</TYPE.subHeader>
  )
}
