import Loader from 'components/Loader'
import { Wrapper } from 'components/swap/styleds'
import { useAvatarSVG } from 'hooks/useColor'
import { hsl } from 'polished'
import React, { useMemo } from 'react'
import { useNFTModalToggle, useSetNFTModalMintAddress } from 'state/application/hooks'
import styled from 'styled-components/macro'

import Logo from '../Logo'

export const StyledLogo = styled(Logo)<{ clickable?: boolean; size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  // border-radius: ${({ size }) => size};
  border-radius: ${({ size }) => '10px'};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ clickable }) => (clickable ? 'rgba(37, 41, 46, 0.2) 0px 10px 30px;' : 'none')};
  :hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'none')};
    transform: ${({ clickable }) => (clickable ? 'scale(1.1)' : 'none')};
  }
  transition: ${({ clickable }) => (clickable ? ' all 0.2s ease-in-out' : 'none')};
`

const WrapperButton = styled(Wrapper)`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  // width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
`

// const stringHash = require('string-hash')
// const hslTriad = require('hsl-triad')
// const hslRgb = require('hsl-rgb')

export default function CollectionLogo({
  collectionID,
  size = '24px',
  style,
  clickable,
  ...rest
}: {
  collectionID?: string
  size?: string
  clickable?: boolean
  style?: React.CSSProperties
}) {
  const avatarSVG = useAvatarSVG(collectionID, size)

  const userLogoSeed = useMemo(() => collectionID, [collectionID])
  console.log('userLogoSeed', userLogoSeed)
  if (collectionID) {
    return (
      <WrapperButton
        onClick={() => {
          // if (currency) {
          //   setNFTModalAddr(currency.address)
          //   toggleNFTModal()
          // }
        }}
      >
        <StyledLogo
          size={size}
          srcs={[`data:image/svg+xml;base64,${btoa(avatarSVG)}`]}
          clickable={clickable}
          alt={`${collectionID ?? 'user'} logo`}
          style={style}
          {...rest}
        />
      </WrapperButton>
    )
  } else {
    return <Loader></Loader>
  }
}

{
  /* <Image
alt={name}
src={`data:image/svg+xml;base64,${btoa(gradientAvatar(uid))}`}
{...sizeProps}
{...props}
/> */
}
