// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import * as anchor from '@project-serum/anchor'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { pubkeyFromString, pubkeyFromStringForced } from 'apollo/utils'
import { ButtonLight } from 'components/Button'
import { OutlineCard } from 'components/Card'
import { useApolloProgram } from 'components/SolanaManager'
import { ResizingTextArea, TextInput } from 'components/TextInput'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useWalletModalToggle } from 'state/application/hooks'
import { metadataFromMint } from 'state/sales/chain'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'

const CollectionMintsHeader = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
`
const CollectionMintsSubHeader = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const CollectionInput = memo(styled(TextInput)`
  margin-top: 10.5px;
  margin-bottom: 7.5px;
`)

const CollectionEditorContainer = styled.div`
  margin-top: 10px;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
`

const ValidatorContainer = styled(OutlineCard)`
  margin-top: 10px;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.bg2};
  // background-color: ${({ theme }) => theme.bg1};
`
export const ResponsiveTwoColumns = styled.div<{ wide: boolean }>`
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  grid-template-columns: ${({ wide }) => (wide ? '1fr 1fr' : '1fr')};
  grid-template-rows: max-content;
  grid-auto-flow: row;

  // padding-top: 20px;

  // border-top: 1px solid ${({ theme }) => theme.bg2};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;

    margin-top: 0;
  `};
`

export const CollectionMintsInput = ({
  mintsRaw,
  onMintsRawInput,
  mints,
  verifiedMints,
  errorMessage,
}: {
  mintsRaw: string
  onMintsRawInput: (raw: string) => void
  mints: string[]
  verifiedMints: string[]
  errorMessage: string
}) => {
  const randomPlaceholder = useMemo(
    () =>
      `${anchor.web3.Keypair.generate().publicKey.toBase58()}, ${anchor.web3.Keypair.generate().publicKey.toBase58()}`,
    []
  )

  return (
    <ResponsiveTwoColumns wide={true}>
      <CollectionEditorContainer>
        <CollectionMintsHeader>
          <Trans>Collection Mints</Trans>
        </CollectionMintsHeader>
        <CollectionMintsSubHeader>
          <Trans>Comma separated list of mint addresses (base58)</Trans>
        </CollectionMintsSubHeader>
        <CollectionInput
          value={mintsRaw}
          onUserInput={onMintsRawInput}
          placeholder={randomPlaceholder}
          fontSize="0.75rem"
        />
        <hr />
        {/* <ResizingTextArea value={body} onUserInput={onBodyInput} placeholder={bodyPlaceholder} fontSize="1rem" /> */}
      </CollectionEditorContainer>
      {mints.length > 0 && (
        <ValidatorContainer>
          <CollectionMintsHeader>
            <Trans>{`Validating ${verifiedMints.length} / ${mints.length} Mint${mints.length > 1 ? 's' : ''}`} </Trans>
          </CollectionMintsHeader>
          <CollectionMintsSubHeader>
            <Trans>Comma separated list of mint addresses (base58)</Trans>
          </CollectionMintsSubHeader>
          {errorMessage.length > 0 && (
            <CollectionMintsSubHeader>
              <TYPE.error error={true}>{errorMessage}</TYPE.error>
            </CollectionMintsSubHeader>
          )}
          {verifiedMints.length > 0 && verifiedMints.length == mints.length && (
            <CollectionMintsSubHeader>
              <TYPE.green>{`${mints.length} Mint${mints.length > 1 ? 's' : ''} Validated!`}</TYPE.green>
            </CollectionMintsSubHeader>
          )}
        </ValidatorContainer>
      )}
    </ResponsiveTwoColumns>
  )
}
