import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { SaleData } from 'validators/accounts/sales'

import { AppState } from '../index'
import { Collection } from './models'
import { Request } from './reducer'

export function useRequiredCollections(): string[] {
  console.log('aState useRequiredCollections')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredSales useAppSelector state: ', state)
    return state.collections.requiredCollections
  })
}

export function useAllCollections(): Collection[] {
  console.log('aState useAllCollections')
  return useAppSelector((state: AppState) => {
    // console.log('aState useHomepageSales useAppSelector state: ', state)
    return state.collections.collections
  })
}

export function useAllCollectionsFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.collections.collectionRequests
  })
}

export function useCollectionsFirebaseRequestByRequestID(requestID?: string): Request | undefined {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.collections.collectionRequests.find((r) => r.requestID === requestID)
  })
}

export function useCollection(collectionID?: string): Collection | undefined {
  const allCollections = useAllCollections()
  const collection = useMemo(() => {
    return allCollections.find((collection) => collection.id === collectionID)
  }, [allCollections, collectionID])
  return collection
}

export function useCollectionsByUserID(userAddress?: string): Collection[] {
  const allCollections = useAllCollections()
  const collections = useMemo(() => {
    return allCollections.filter((collection) => collection.user === userAddress)
  }, [allCollections, userAddress])
  return collections
}

export function useCollections(collectionIDs?: string[]): Collection[] {
  const allCollections = useAllCollections()

  return useMemo(() => {
    console.log('aState useCollections useMemo allCollections: ', allCollections)
    const collections: Collection[] = []
    collectionIDs?.forEach((collectionID) => {
      const collection = allCollections.find((c) => c.id === collectionID)
      if (collection) {
        collections.push(collection)
      }
    })
    return collections
  }, [allCollections, collectionIDs])
}

export function useAllFirebaseCollectionQueryRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.collections.queryRequests
  })
}
