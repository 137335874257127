import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { BaseButton, ButtonCreate, ButtonSmall, SavedIcon } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import MintArtDisplay from 'components/MintArtDisplay'
import { MintMenu } from 'components/NiceMenu'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import UserLogo from 'components/UserLogo'
import { useListColor } from 'hooks/useColor'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useCreateOrderModalToggle, useWalletModalToggle } from 'state/application/hooks'
import { addRequiredCollections } from 'state/collections/actions'
import { useCollections } from 'state/collections/hooks'
import { resetToSellOrder } from 'state/createOrder/actions'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import { addRequiredOrderQueries } from 'state/orders/actions'
import { ORDER_SIDE } from 'state/orders/chain'
import { useOrdersByAssetMintAndSide } from 'state/orders/hooks'
import { RequiredOrderQuery } from 'state/orders/reducer'
import { useEditSavedMint } from 'state/users/firebase'
import { useMintIsSaved, useSafeUser } from 'state/users/hooks'
import styled, { keyframes } from 'styled-components/macro'
import { TYPE } from 'theme'
import { truncateString } from 'utils'
import { mintIsNative } from 'utils/utils'

import { ReactComponent as Ellipsis } from '../../assets/svg/ellipsis.svg'

const MintCardContainer = styled(Link)`
  padding: 0.25rem 0.2rem 0.5rem;
  width: 100%;
  border-radius: 0.7rem;

  // height: 100%;
  align-items: center;
  text-align: left;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  background-color: ${({ theme }) => transparentize(1.0, theme.bg0)};
  background-opacity: 0.1;
  &:focus {
    background-color: ${({ theme }) => transparentize(0.6, theme.bg0)};
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.6, theme.bg0)};
  }

  overflow: visible;
`

const AskCardRow = styled.div`
  color: ${({ theme }) => theme.secondary2} !important;
  display: grid;
  grid-template-columns: 30px 20px;
  justify-content: end;
`

const DetailsSection = styled(AutoColumn)`
  padding: 15px 17px 0px 17px;
  width: 100%;
`

const ActivityRow = styled.div`
  // padding: 0px 8px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.7rem;
  background-color: ${({ theme }) => theme.transparent};
  width: 100%;
`
const SellButton = styled(ButtonCreate)`
  padding: 15px; 17px; 5px; 17px;
  color: ${({ theme }) => theme.text2};
  width: 60px;
`

const MintDisplayContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // overflow: auto;
  // width: 100%;
  // height: 100%;
`

const ArtDisplayPillsRow = styled(RowBetween)`
  position: absolute;
  bottom: -14px;
  // left: 8px;
  // width: 100%;
  padding: 0px 8px;
`

const ArtDisplayPill = styled(BaseButton)`
  padding: 8px; 13px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 28px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.bg0};
`

const animatedgradient = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`

const StyledEllipis = styled(Ellipsis)`
  // margin: 0 0.25rem 0 0.35rem;
  // height: 35%;

  // path {ii
  //   // stroke: ${({ theme }) => theme.text2};
  //   stroke-width: 3px;
  // }
`

const UserCollectionsButton = styled(ButtonSmall)`
  color: ${({ theme }) => theme.blue1};
  background-color: ${({ theme }) => theme.bg0};
  // margin-right: 10px;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:hover {
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:active {
    // box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    // border: 1px solid ${({ theme }) => theme.primary3};
  }
`

function LabelWithContent({ label, children }: React.PropsWithChildren<{ label: string }>) {
  return (
    <AutoColumn style={{ justifyContent: 'end' }}>
      <AutoRow width={'100%'}>
        <TYPE.heavy width={'100%'} textAlign="right" color={'text3'} fontSize={'12px'}>
          {label}
        </TYPE.heavy>
      </AutoRow>

      {children}
    </AutoColumn>
  )
}

export default function MintCard({ mintAddress }: { mintAddress?: string }) {
  const [buttonIsHovered, setButtonIsHovered] = React.useState(false)
  const [mintCardIsHovered, setMintCardIsHovered] = React.useState(false)
  const { tokenRegistry } = useTokenRegistry()

  const wallet = useAnchorWallet()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const userPubkey = wallet?.publicKey

  const mint = useSafeMint(mintAddress)

  const user = useSafeUser(userPubkey?.toBase58())
  console.log('mint card user:', user)

  const mintIsSaved = useMintIsSaved(userPubkey?.toBase58(), mint?.address)
  const editSavedMint = useEditSavedMint()
  const toggleMintSaved = useCallback(() => {
    if (!user || !userPubkey || !mint) return
    if (mintIsSaved) {
      editSavedMint(user, mint.address, false)
    } else {
      editSavedMint(user, mint.address, true)
    }
  }, [user, userPubkey, mint, mintIsSaved, editSavedMint])

  useEffect(() => {
    if (mintAddress) {
      dispatch(addRequiredMints([mintAddress]))
    }
  }, [mintAddress, dispatch])

  const mintColor = useListColor(mint?.metadata?.extended?.image)

  const toggleCreateOrderModal = useCreateOrderModalToggle()
  const toggleWalletModal = useWalletModalToggle()

  const orderQueryRequest = useMemo(() => new RequiredOrderQuery([mint?.address ?? '']), [mint?.address])

  useEffect(() => {
    dispatch(addRequiredOrderQueries([orderQueryRequest]))
  }, [dispatch, orderQueryRequest])

  const sellOrders = useOrdersByAssetMintAndSide(mint?.address, ORDER_SIDE.SELL)
  const topSellOrder = useMemo(
    () =>
      sellOrders
        .filter((o) => true)
        .sort((a, b) => b.paymentBaseSize - a.paymentBaseSize)
        ?.find((order) => order),
    [sellOrders]
  )
  const buyOrders = useOrdersByAssetMintAndSide(mint?.address, ORDER_SIDE.BUY)
  const topBuyOrder = useMemo(
    () =>
      buyOrders
        .filter((o) => true)
        .sort((a, b) => b.paymentBaseSize - a.paymentBaseSize)
        ?.find((order) => order),
    [buyOrders]
  )

  const mintCollections = useCollections(mint?.featuredInCollections ?? [])
  useEffect(() => {
    if (mint?.featuredInCollections && mint?.featuredInCollections?.length > 0) {
      dispatch(addRequiredCollections(mint?.featuredInCollections))
    }
  }, [dispatch, mint?.featuredInCollections])

  const topCollection = useMemo(() => {
    if (mintCollections.length > 0) {
      return mintCollections[0]
    } else {
      return undefined
    }
  }, [mintCollections])

  // const setCreateOrderModalConfig = useSetCreateOrderModalConfig()
  const topPriceRow = () => {
    console.log('MintCard mint:', mint)
    if (topSellOrder) {
      return (
        <ActivityRow>
          <AutoRow alignItems="center">
            <TYPE.heavy fontSize={'11px'} color={theme.text3} style={{ marginRight: '6px' }}>
              Seller
            </TYPE.heavy>
            <UserLogo size="17px" userAddress={topSellOrder.maker}></UserLogo>
            <TYPE.heavy fontSize={'11px'} color={theme.text3} style={{ marginLeft: '4px' }}>
              {truncateString(topSellOrder.maker)}
            </TYPE.heavy>
          </AutoRow>
          <RowFixed style={{ width: 'fit-content' }}>
            <MintArtDisplay setIsHovered={setButtonIsHovered} mintAddress={topSellOrder.paymentMint} size={'16px'} />
            <TYPE.heavy color={'text2'} fontSize={'18px'} marginLeft={'3px'}>
              {mintIsNative(topSellOrder.paymentMint)
                ? topSellOrder.paymentBaseSize / LAMPORTS_PER_SOL
                : topSellOrder.paymentBaseSize / 1}
            </TYPE.heavy>
          </RowFixed>
        </ActivityRow>
      )
    }
    if (topBuyOrder) {
      return (
        <LabelWithContent label={'Top Offer'}>
          <AutoRow width={'100%'}>
            <MintArtDisplay setIsHovered={setButtonIsHovered} mintAddress={topBuyOrder.paymentMint} size={'16px'} />
            <TYPE.heavy color={'text2'} fontSize={'18px'} marginLeft={'3px'}>
              {mintIsNative(topBuyOrder.paymentMint)
                ? topBuyOrder.paymentBaseSize / LAMPORTS_PER_SOL
                : topBuyOrder.paymentBaseSize / 1}
            </TYPE.heavy>
          </AutoRow>
        </LabelWithContent>
      )
    }
    if (mint?.owners?.includes(userPubkey?.toBase58() ?? '') && mintAddress)
      return (
        <SellButton
          xColor={mintColor}
          onClick={() => {
            dispatch(resetToSellOrder({ assetMint: mintAddress }))
            if (!wallet) {
              toggleWalletModal()
            } else {
              toggleCreateOrderModal()
            }
          }}
          onMouseEnter={() => {
            if (!buttonIsHovered) {
              setButtonIsHovered(true)
            }
          }}
          onMouseLeave={() => {
            if (buttonIsHovered) {
              setButtonIsHovered(false)
            }
          }}
        >
          Sell
        </SellButton>
      )
    return <></>
  }

  return (
    <MintCardContainer
      to={buttonIsHovered ? history.location : `/m/${mint?.address}`}
      key={`${mintAddress}`}
      onMouseEnter={() => {
        setMintCardIsHovered(true)
      }}
      onMouseLeave={() => {
        if (!buttonIsHovered) {
          setMintCardIsHovered(false)
        }
      }}
    >
      {mint ? (
        <>
          <MintDisplayContainer>
            <div style={{ minHeight: '300px' }}>
              <MintArtDisplay
                key={mint.address}
                setIsHovered={setButtonIsHovered}
                mintAddress={mint.address}
                clickable
              />
            </div>
            <ArtDisplayPillsRow>
              <ArtDisplayPill
                as={Link}
                to={`/c/${topCollection?.id}`}
                // onClick={() => {
                //   // if (topCollection) {
                //   history.push(`/c/${topCollection?.id}`)
                //   // }
                // }}
                onMouseEnter={() => {
                  if (!buttonIsHovered) {
                    setButtonIsHovered(true)
                  }
                }}
                onMouseLeave={() => {
                  if (buttonIsHovered) {
                    setButtonIsHovered(false)
                  }
                }}
              >
                <TYPE.heavy style={{ minWidth: '0.5rem', textAlign: 'right' }} color={theme.text2}>
                  {topCollection?.name ?? mintCollections.length}
                </TYPE.heavy>
              </ArtDisplayPill>
              <ArtDisplayPill
                onClick={toggleMintSaved}
                onMouseEnter={() => {
                  if (!buttonIsHovered) {
                    setButtonIsHovered(true)
                  }
                }}
                onMouseLeave={() => {
                  if (buttonIsHovered) {
                    setButtonIsHovered(false)
                  }
                }}
              >
                <SavedIcon fill={mintIsSaved} size={'20px'} />
                {mint.savedByUsers && (
                  <TYPE.heavy
                    style={{ minWidth: '0.5rem', textAlign: 'right' }}
                    color={theme.text2}
                  >{`${mint.savedByUsers.length}`}</TYPE.heavy>
                )}
              </ArtDisplayPill>
            </ArtDisplayPillsRow>
          </MintDisplayContainer>
          <DetailsSection>
            <RowBetween style={{ width: '100%' }}>
              <TYPE.heavy fontSize={'22px'} color={theme.text2}>
                {mint.isNFT ? mint.metadata?.data.name : mint.tokenInfo?.name ? mint.tokenInfo?.name : 'Unknown'}
              </TYPE.heavy>
              {/* <UserCollectionsButton
                onMouseEnter={() => {
                  if (!buttonIsHovered) {
                    setButtonIsHovered(true)
                  }
                }}
                onMouseLeave={() => {
                  if (buttonIsHovered) {
                    setButtonIsHovered(false)
                  }
                }}
                onClick={() => {
                  // onClickNew()
                  console.log('mint:', mint)
                }}
              > */}
              {/* <TYPE.heavy color={theme.text2} fontSize={'8px'}>
                  Add To Collection
                </TYPE.heavy> */}
              {mintCardIsHovered && (
                <MintMenu
                  mintAddress={mint.address}
                  setButtonIsHovered={setButtonIsHovered}
                  ToggleUI={StyledEllipis}
                ></MintMenu>
              )}
              {/* </UserCollectionsButton> */}
            </RowBetween>
            {topPriceRow()}

            {/* <RowBetween style={{ width: '100%' }}>
              <AutoColumn justify="end">{bottomRight()}</AutoColumn>
              <SavedIcon
                onClick={toggleMintSaved}
                fill={mintIsSaved}
                size={'20px'}
                onMouseEnter={() => {
                  if (!buttonIsHovered) {
                    setButtonIsHovered(true)
                  }
                }}
                onMouseLeave={() => {
                  if (buttonIsHovered) {
                    setButtonIsHovered(false)
                  }
                }}
              />
            </RowBetween> */}
          </DetailsSection>
        </>
      ) : (
        <LoadingOpacityContainer style={{ minHeight: '400px' }} $loading={true}></LoadingOpacityContainer>
      )}
    </MintCardContainer>
  )
}
