// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { ResizingTextArea, TextInput } from 'components/TextInput'
import React, { memo } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

const CollectionEditorHeader = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
`

const CollectionInput = memo(styled(TextInput)`
  margin-top: 10.5px;
  margin-bottom: 7.5px;
`)

const CollectionEditorContainer = styled.div`
  margin-top: 10px;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
`

export const CollectionEditor = ({
  className,
  title,
  input,
  placeholder,
  onInput,
  optional,
}: {
  className?: string
  title: string
  input: string
  placeholder: string
  onInput: (title: string) => void
  optional?: boolean
}) => {
  const bodyPlaceholder = `${t``}...`

  return (
    <CollectionEditorContainer className={className}>
      <CollectionEditorHeader>
        <Trans>{`${title} ${optional ? ' (optional)' : ''}`}</Trans>
      </CollectionEditorHeader>
      <CollectionInput
        value={input}
        onUserInput={onInput}
        placeholder={placeholder}
        fontSize={optional ? '1.0rem' : '1.2rem'}
      />
      <hr />
      {/* <ResizingTextArea value={body} onUserInput={onBodyInput} placeholder={bodyPlaceholder} fontSize="1rem" /> */}
    </CollectionEditorContainer>
  )
}
