import { nanoid } from '@reduxjs/toolkit'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { Connection, PublicKey } from '@solana/web3.js'
import { getExtendedArt, pubkeyFromStringForced } from 'apollo/utils'
import { useApolloProgram } from 'components/SolanaManager'
import { providerURL } from 'hooks/web3'
import { getMetadata } from 'providers/accounts/utils/metadataHelpers'
import { useTokenRegistry } from 'providers/mints/token-registry'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { TokenInfo } from 'state/mints/models'
import { assert, create, is, optional } from 'superstruct'
import { MetadataCombined, MetadataDetails } from 'validators/accounts/sales'
import { TokenAccountInfo } from 'validators/accounts/token'

import { fetchChainUserRequest } from './actions'
import { useAllFirebaseUsers } from './hooks'
import { OwnedMint, User } from './models'

const url = providerURL()
const connection = new Connection(url)

export function useFetchUserFromChain(): (userAddress: string) => Promise<User> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()
  const allFirebaseUsers = useAllFirebaseUsers()

  // const user = useUserAccount(userAddress.toBase58())
  return useCallback(
    async (userAddress: string) => {
      const requestID = nanoid()
      dispatch(fetchChainUserRequest.pending({ userAddress, requestID }))
      try {
        const ownedMints = await mintsFromUser(pubkeyFromStringForced(userAddress))
        const lamports = await connection.getBalance(pubkeyFromStringForced(userAddress))
        console.log('useFetchUserFromChain userAddress', userAddress)
        console.log('useFetchUserFromChain ownedMints', ownedMints)

        const user: User = {
          address: userAddress,
          ownedMints,
          lamports,
          // savedMints: allFirebaseUsers.find((u) => u.address == userAddress)?.savedMints ?? [],
        }
        dispatch(fetchChainUserRequest.fulfilled({ requestID, user }))
        return user
      } catch (e) {
        console.error('useFetchUserFromChain error', e)
        dispatch(fetchChainUserRequest.rejected({ requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

export async function mintsFromUser(userPubkey: PublicKey): Promise<OwnedMint[]> {
  const { value } = await new Connection(url, 'processed').getParsedTokenAccountsByOwner(userPubkey, {
    programId: TOKEN_PROGRAM_ID,
  })
  console.log('useFetchUserFromChain mintsFromUser value', value)
  const ownedMints: OwnedMint[] = []
  for (const accountInfo of value) {
    try {
      const parsedInfo = accountInfo.account.data.parsed.info
      const info = create(parsedInfo, TokenAccountInfo)
      if (info.tokenAmount.amount !== '0') {
        ownedMints.push({ address: info.mint.toBase58(), amount: info.tokenAmount.amount })
      }
    } catch (e) {
      console.log('mintsFromUser error', e)
      throw e
    }
  }
  return ownedMints
}
