import { nanoid } from '@reduxjs/toolkit'
import { Connection, PublicKey } from '@solana/web3.js'
import { getExtendedArt, pubkeyFromStringForced } from 'apollo/utils'
import { useApolloProgram } from 'components/SolanaManager'
import { providerURL } from 'hooks/web3'
import { ownerFromMint, ownersFromMint } from 'providers/accounts/utils/bundleMints'
import { getMetadata } from 'providers/accounts/utils/metadataHelpers'
import { useTokenRegistry } from 'providers/mints/token-registry'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { assert, create, is, optional } from 'superstruct'
import { MetadataCombined, MetadataDetails } from 'validators/accounts/sales'

import { fetchChainMintRequest } from './actions'
import { MintData, TokenInfo } from './models'

const url = providerURL()
const connection = new Connection(url)

export function useFetchMintFromChain(): (mintAddress: string) => Promise<MintData> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()
  // const { tokenRegistry } = useTokenRegistry()

  // const mint = useMintAccount(mintAddress.toBase58())
  return useCallback(
    async (mintAddress: string) => {
      const requestID = nanoid()
      dispatch(fetchChainMintRequest.pending({ mintAddress, requestID }))
      try {
        const metadata = (await metadataFromMint(pubkeyFromStringForced(mintAddress))) as MetadataCombined
        // console.log('metadata owner:: ', metadata.)
        let m2: MetadataCombined | undefined = undefined
        if (metadata) {
          const newData: MetadataDetails = {
            symbol: metadata.data.symbol,
            name: metadata.data.name,
            uri: metadata.data.uri,
            sellerFeeBasisPoints: metadata.data.sellerFeeBasisPoints,
            creators: metadata.data.creators.map((creator) => {
              return {
                address: creator.address,
                share: creator.share,
                verified: creator.verified,
              }
            }),
          }
          m2 = create({ ...metadata, data: newData }, MetadataCombined)
        }
        // console.log('useFetchMintFromChain newData', newData as MetadataDetails)
        const isNFT = metadata ? true : false
        console.log('useFetchMintFromChain mintAddress', mintAddress)
        console.log('useFetchMintFromChain isNFT', isNFT)
        console.log('useFetchMintFromChain metadata', metadata)
        console.log('useFetchMintFromChain m2', m2)
        const owners = isNFT ? await ownersFromMint(connection, pubkeyFromStringForced(mintAddress)) : []
        console.log('useFetchMintFromChain owner', owners)
        // console.log('useFetchMintFromChain tokenRegistry', tokenRegistry)
        // const tokenInfo = tokenRegistry.get(mintAddress)
        // console.log('useFetchMintFromChain tokenInfo', tokenInfo)
        // const tokenInfo2 = create(tokenInfo, optional(TokenInfo))
        // const hasTokenInfo = tokenInfo2 !== undefined
        // console.log('useFetchMintFromChain tokenInfo2', tokenInfo2)

        const mintData: MintData = {
          address: mintAddress,
          // metadata: m2,
          owners,
          // tokenInfo: tokenInfo2,
          isNFT,
          color: null,
          // savedByUsers: [],
          // featuredInCollections: [],
        }
        if (isNFT) {
          mintData.metadata = m2
        }
        // else {
        //   mintData.tokenInfo = tokenInfo2
        // }

        dispatch(fetchChainMintRequest.fulfilled({ requestID, mintData }))
        return mintData
      } catch (e) {
        console.error(e)
        dispatch(fetchChainMintRequest.rejected({ requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

export async function metadataFromMint(mint: PublicKey): Promise<MetadataCombined | undefined> {
  const metadata = await getMetadata(mint, providerURL())
  if (metadata) {
    const extended = await getExtendedArt(metadata)
    if (!extended) {
      console.error('Metadata not found for ' + mint.toBase58())
    }
    console.log('metadataFromMint extended', extended)
    const res = {
      ...metadata,
      extended: extended ?? null,
    }
    console.log('metadataFromMint res', res)
    const validated = create(res, MetadataCombined)
    console.log('metadataFromMint validated', validated)
    return validated
  }
  return undefined
}
