import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'

import { TYPE } from '../../theme'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'

const StyledCreateHeader = styled.div`
  padding: 1rem 1rem 0rem 0.5rem;
  width: 100%;
  align-test: center;
  color: ${({ theme }) => theme.text2};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function CreateHeader() {
  return (
    <StyledCreateHeader>
      <RowFixed>
        <TYPE.black fontWeight={500} fontSize={18} style={{ width: '100%' }}>
          <Trans>New Sale</Trans>
        </TYPE.black>
      </RowFixed>
    </StyledCreateHeader>
  )
}
