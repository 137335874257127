import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { AccountMeta, Connection, PublicKey, SYSVAR_RENT_PUBKEY, TransactionInstruction } from '@solana/web3.js'
import { providerURL } from 'hooks/web3'
import { METADATA_PROGRAM_ID, toPublicKey } from 'metaplex/ids'
import { METADATA_PREFIX } from 'metaplex/types'
import { getMetadata } from 'providers/accounts/utils/metadataHelpers'
import { MintData } from 'state/mints/models'
import { assert, create, is } from 'superstruct'
import { Metadata, MetadataExtension, Sale, SaleData } from 'validators/accounts/sales'

// import { Account, getAccountInfo } from 'providers/accounts'
// import { Bundle } from './types'

export function pubkeyFromString(address?: string): PublicKey | undefined {
  if (!address) return
  let pubkey
  try {
    pubkey = new PublicKey(address)
    return pubkey
  } catch (e) {
    console.error(e)
    throw new Error('Invalid public key: ' + address)
    return undefined
  }
}

export function pubkeyFromStringForced(address: string): PublicKey {
  return pubkeyFromString(address) as PublicKey
}

export async function generateMetadataPDA(tokenMint: PublicKey, addEditionToSeeds = false): Promise<PublicKey> {
  const metadataSeeds = [
    Buffer.from(METADATA_PREFIX),
    toPublicKey(METADATA_PROGRAM_ID).toBuffer(),
    tokenMint.toBuffer(),
  ]

  if (addEditionToSeeds) {
    metadataSeeds.push(Buffer.from('edition'))
  }

  return (await PublicKey.findProgramAddress(metadataSeeds, toPublicKey(METADATA_PROGRAM_ID)))[0]
}

export const getExtendedArt = async (metadata: Metadata | undefined): Promise<MetadataExtension | undefined> => {
  if (metadata) {
    const uri = metadata.data.uri
    const processJson = (extended: any) => {
      console.log('getExtendedArt extendedd', extended)
      if (!extended || extended?.properties?.files?.length === 0) {
        return undefined
      }
      if (!extended.properties.category) {
        extended.properties.category = 'image'
      }
      if (!extended.external_url) {
        extended.external_url = ''
      }

      if (extended?.image) {
        extended.image = extended.image.startsWith('http') ? extended.image : `${metadata.data.uri}/${extended.image}`
      }

      return extended
    }
    try {
      return fetch(uri)
        .then(async (_) => {
          try {
            const data = await _.json()
            try {
              // localStorage.setItem(uri, JSON.stringify(data))
              return processJson(data)
            } catch (e) {
              console.error(e)
              return undefined
            }
          } catch {
            return undefined
          }
        })
        .catch((e) => {
          return undefined
        })
    } catch (ex) {
      console.error(ex)
      return undefined
    }
    return undefined
  }
  return undefined
}

export const ataTXIIfEmpty = async (
  connection: Connection,
  mint: PublicKey,
  ataPubkey: PublicKey,
  owner: PublicKey,
  payer: PublicKey
) => {
  const ataRes = await connection.getParsedAccountInfo(ataPubkey)
  console.log('ataRes1', ataRes)
  if (!ataRes.value) {
    return Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      ataPubkey,
      owner,
      payer
    )
  } else {
    return undefined
  }
}

export const acceptAskRemainingAccts = async (
  saleData: SaleData,
  askTokenMint: PublicKey,
  payer: PublicKey
): Promise<{ remainingAccounts: AccountMeta[]; ataTXIs: TransactionInstruction[] }> => {
  // const filteredTokenBoxes = bundle.tokenBoxes.filter((b) => b.amount.valueOf() > 0)
  // console.log('filteredTokenBoxes', filteredTokenBoxes)
  const nullAcct = { pubkey: SYSVAR_RENT_PUBKEY, isWritable: false, isSigner: false }
  const remainingAccts: AccountMeta[] = []
  const ataTXIs: TransactionInstruction[] = []
  const connection = new Connection(providerURL(), 'confirmed')
  // for (const tokenBox of bundle.tokenBoxes) {
  console.log('sale mint', saleData.sale?.tokenMint)
  console.log('sale amount', saleData.sale?.amount.toString())
  const mintPubkey = pubkeyFromString(saleData.sale?.tokenMint)
  if (!mintPubkey) {
    throw new Error('Invalid token mint: ' + saleData.sale?.tokenMint)
    return { remainingAccounts: [], ataTXIs: [] }
  }
  const metadataKey = await generateMetadataPDA(mintPubkey)
  const metadata = await getMetadata(mintPubkey, providerURL())
  if (metadata) {
    const res: AccountMeta[] = [{ pubkey: metadataKey, isWritable: false, isSigner: false }]
    const extended = saleData.metadata?.extended
    if (!extended) {
      throw new Error('Metadata not found for ' + saleData.sale?.tokenMint)
    }
    console.log('extended', extended)
    if (!metadata.data.creators) {
      console.error('creators not found for ' + saleData.sale?.tokenMint)
      // remainingAccts.push(nullAcct)
      return { remainingAccounts: remainingAccts, ataTXIs }
    }
    for (const c of metadata.data.creators) {
      const creatorPubkey = new PublicKey(c.address)
      res.push({ pubkey: creatorPubkey, isWritable: false, isSigner: false })
      console.log('creators', c)
      if (c.share == 0) continue
      const creatorDepositAcct = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        askTokenMint,
        creatorPubkey,
        false
      )
      const ataRes = await ataTXIIfEmpty(connection, askTokenMint, creatorDepositAcct, creatorPubkey, payer)
      if (ataRes) {
        ataTXIs.push(ataRes)
      }
      console.log('ataRes', ataRes)
      res.push({ pubkey: creatorDepositAcct, isWritable: true, isSigner: false })
    }
    console.log('res', res)
    remainingAccts.push(...res)
  } else {
    return { remainingAccounts: [], ataTXIs }
  }

  return { remainingAccounts: remainingAccts, ataTXIs }
}

export const calculateAskFees = async (
  sale: Sale,
  askAmount: number
  // seller: PublicKey,
  // payer: PublicKey
): Promise<number> => {
  const tokenMintPubkey = pubkeyFromString(sale.tokenMint)
  if (!tokenMintPubkey) {
    throw new Error('Invalid token mint: ' + sale.tokenMint)
    return 0
  }
  const metadata = await getMetadata(tokenMintPubkey, providerURL())
  if (metadata) {
    const convertedMetadata = create(metadata, Metadata) as Metadata
    const extended = await getExtendedArt(convertedMetadata)
    if (!extended) {
      throw new Error('Metadata not found for ' + tokenMintPubkey.toBase58())
    }
    console.log('calculateAskFees extendedd', extended)
    if (extended.seller_fee_basis_points) {
      return (1 + extended.seller_fee_basis_points / 10000) * askAmount
    } else {
      return askAmount
    }
  } else {
    return askAmount
  }
}

export const calculateCreatorFees = async (
  mintData: MintData,
  baseSize: number
  // seller: PublicKey,
  // payer: PublicKey
): Promise<number> => {
  const tokenMintPubkey = pubkeyFromString(mintData.address)
  if (!tokenMintPubkey) {
    throw new Error('Invalid token mint: ' + mintData.address)
    return 0
  }
  if (mintData.metadata) {
    if (!mintData.metadata.extended) {
      throw new Error('Metadata not found for ' + tokenMintPubkey.toBase58())
    }
    if (mintData.metadata.extended.seller_fee_basis_points) {
      return (1 + mintData.metadata.extended.seller_fee_basis_points / 10000) * baseSize
    } else {
      return baseSize
    }
  } else {
    return baseSize
  }
}

// export async function getMetadata(pubkey: PublicKey, url: string): Promise<Metadata | undefined> {
//   const connection = new Connection(url, 'confirmed')
//   const metadataKey = await generatePDA(pubkey)
//   const accountInfo = await connection.getAccountInfo(toPublicKey(metadataKey))
//   if (accountInfo && accountInfo.data.length > 0) {
//     if (!isMetadataAccount(accountInfo)) return

//     if (isMetadataV1Account(accountInfo)) {
//       const metadata = decodeMetadata(accountInfo.data)

//       if (isValidHttpUrl(metadata.data.uri)) {
//         return metadata
//       }
//       return
//     }
//   }
//   return
// }

// export async function getEditionData(pubkey: PublicKey, url: string): Promise<EditionData | undefined> {
//   const connection = new Connection(url, 'confirmed')
//   const editionKey = await generatePDA(pubkey, true /* addEditionToSeeds */)
//   const accountInfo = await connection.getAccountInfo(toPublicKey(editionKey))

//   if (accountInfo && accountInfo.data.length > 0) {
//     if (!isMetadataAccount(accountInfo)) return

//     if (isMasterEditionAccount(accountInfo)) {
//       return {
//         masterEdition: decodeMasterEdition(accountInfo.data),
//         edition: undefined,
//       }
//     }

//     // This is an Edition NFT. Pull the Parent (MasterEdition)
//     if (isEditionV1Account(accountInfo)) {
//       const edition = decodeEdition(accountInfo.data)
//       const masterEditionAccountInfo = await connection.getAccountInfo(toPublicKey(edition.parent))

//       if (
//         masterEditionAccountInfo &&
//         masterEditionAccountInfo.data.length > 0 &&
//         isMasterEditionAccount(masterEditionAccountInfo)
//       ) {
//         return {
//           masterEdition: decodeMasterEdition(masterEditionAccountInfo.data),
//           edition,
//         }
//       }
//     }
//   }

//   return
// }

// async function generatePDA(tokenMint: PublicKey, addEditionToSeeds = false): Promise<PublicKey> {
//   const PROGRAM_IDS = programIds()

//   const metadataSeeds = [
//     Buffer.from(METADATA_PREFIX),
//     toPublicKey(PROGRAM_IDS.metadata).toBuffer(),
//     tokenMint.toBuffer(),
//   ]

//   if (addEditionToSeeds) {
//     metadataSeeds.push(Buffer.from('edition'))
//   }

//   return (await PublicKey.findProgramAddress(metadataSeeds, toPublicKey(PROGRAM_IDS.metadata)))[0]
// }
