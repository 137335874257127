import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { ButtonLight } from 'components/Button'
import { DarkCard } from 'components/Card'
import CollectionCard from 'components/CollectionCard'
import CollectionLogo from 'components/CollectionLogo'
import Column, { AutoColumn } from 'components/Column'
import MintsCollectionFilter from 'components/filter/MintsCollectionFilter'
import MintsPriceFilter from 'components/filter/MintsPriceFilter'
import MintsStatusFilter from 'components/filter/MintsStatusFilter'
import Loader from 'components/Loader'
import MintCard from 'components/MintCard'
import { AutoRow, RowFixed } from 'components/Row'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Configure, useInfiniteHits } from 'react-instantsearch-hooks'
import { useDispatch } from 'react-redux'
import { NavLink, RouteComponentProps, useLocation } from 'react-router-dom'
import { appendFirebaseCollectionLocal } from 'state/collections/actions'
import { decodeFirebaseCollection } from 'state/collections/firebase'
import { appendFirebaseMintIfEmpty } from 'state/mints/actions'
import { decodeFirebaseMintData } from 'state/mints/firebase'
import { addRequiredOrderQueries } from 'state/orders/actions'
import { useAllLiveOrders } from 'state/orders/hooks'
import { RequiredOrderQuery } from 'state/orders/reducer'
import { addRequiredUsers } from 'state/users/actions'
import styled from 'styled-components/macro'
import { StyledInternalLink, TYPE } from 'theme'
import { shortenAddress } from 'utils'

export const ContentPageWrapper = styled.div`
  height: fit-content;

  min-width: 250px;
  overflow-x: visible !important;
`

export const Grid = styled(AutoColumn)`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

export const CollectionsGrid = styled(AutoColumn)`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

export const CollectionRowGrid = styled(DarkCard)`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: center;
  // align-content: space-between;
  grid-template-columns: 15px 250px 75px 150px 110px 60px 50px;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

export const GridWrapper = styled.div`
  max-height: 88vh;
  overflow-y: scroll;

  position: relative;
  left: -40px;
  right: -40px;
  width: calc(100% + 100px);
  padding-left: 30px;
  padding-right: 30px;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const SearchSettingsWrapper = styled(Column)`
  position: relative;
  top: 0;
  width: 100%;
  padding: 0px 0px 10px 0px;
`
const activeClassName = 'ACTIVE'

export const ExploreToggleElement = styled(NavLink).attrs({
  activeClassName,
})`
  display: flex;
  align-items: center;

  padding: 6px 8px;
  border-radius: 6px;
  justify-content: start;
  height: 100%;
  background: ${({ theme }) => 'none'};
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme }) => theme.text2};
  }
  font-size: 14px;

  &.${activeClassName} {
    background: ${({ theme }) => theme.bg1};
    color: ${({ theme }) => theme.text2};
    font-weight: 700;
  }
  text-decoration: none;
`

export const TopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid ${({ theme }) => transparentize(0, theme.bg3)};
  background: ${({ theme }) => transparentize(0.9, theme.bg1)};
`
export const TopBarLeftWrapper = styled.div`
  display: flex;
  width: fit-content;

  gap: 10px;
  align-items: center;
`
export const FiltersWrapper = styled.div`
  display: flex;
  width: fit-content;

  gap: 10px;
  align-items: center;
`

enum ExploreType {
  NFTs = 'all',
  Collections = 'collections',
  Activity = 'activity',
}

export default function ExplorePage({
  match: {
    params: { type: typeFromURL },
  },
}: RouteComponentProps<{ type?: string }>) {
  const wallet = useAnchorWallet()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const theme = useTheme()
  const debouncedSetStateRef = useRef(null)

  useEffect(() => {
    if (wallet?.publicKey) {
      console.log('mintissaved dispatching addRequiredUsers', wallet.publicKey)
      dispatch(addRequiredUsers([wallet?.publicKey.toBase58()]))
    }
  }, [dispatch, wallet?.publicKey])

  const orderQueryRequest = useMemo(() => new RequiredOrderQuery(undefined, undefined, true, true), [])
  console.log('ExplorePage orderQueryRequest', orderQueryRequest)

  useEffect(() => {
    dispatch(addRequiredOrderQueries([orderQueryRequest]))
  }, [dispatch, orderQueryRequest])

  const orders = useAllLiveOrders()
  console.log('ExplorePage orders', orders)

  const { hits, isLastPage, showMore } = useInfiniteHits()

  useEffect(() => {
    console.log('ExplorePage –––––––––', isLastPage)
    console.log('ExplorePage isLastPage', isLastPage)
    console.log('ExplorePage hits', hits)
    console.log('ExplorePage ––––––––', showMore)
  }, [isLastPage, hits, showMore])

  const mintDatas = useMemo(() => {
    return hits
      .map((mintData) => {
        try {
          return decodeFirebaseMintData(mintData)
        } catch (e) {
          console.log('ExplorePage decodeFirebaseMintData error', e)
        }
        return undefined
      })
      .filter((mintData) => mintData != undefined)
  }, [hits])
  useEffect(() => {
    for (const mintData of mintDatas) {
      if (mintData) dispatch(appendFirebaseMintIfEmpty(mintData))
    }
  }, [dispatch, mintDatas, orderQueryRequest])

  const filteredMetadatas = useMemo(() => {
    return mintDatas.filter((mintData) => {
      const metadata = mintData?.metadata
      return metadata?.extended?.image ? true : false
    })
  }, [mintDatas])

  const collectionDatas = useMemo(() => {
    return hits
      .map((collectionData) => {
        try {
          return decodeFirebaseCollection(collectionData)
        } catch (e) {
          console.log('ExplorePage decodeFirebaseCollection error', e)
          return undefined
        }
      })
      .filter((collectionData) => collectionData != undefined)
  }, [hits])
  useEffect(() => {
    for (const collectionData of collectionDatas) {
      if (collectionData) dispatch(appendFirebaseCollectionLocal(collectionData))
    }
  }, [dispatch, collectionDatas, orderQueryRequest])

  const filteredCollections = useMemo(() => {
    return collectionDatas.filter((collectionData) => {
      return true
    })
  }, [collectionDatas])

  const displayType = useMemo(() => {
    switch (pathname) {
      case '/explore/nfts':
        return ExploreType.NFTs
      case '/explore/collections':
        return ExploreType.Collections
      case '/explore/activity':
        return ExploreType.Activity
      default:
        return ExploreType.NFTs
    }
  }, [pathname])

  return (
    <ContentPageWrapper>
      <TopBar>
        <TopBarLeftWrapper>
          <ToggleWrapper>
            <ExploreToggleElement
              to={'/explore/mints'}
              isActive={(match, { pathname }) => displayType == ExploreType.NFTs}
            >
              NFTs
            </ExploreToggleElement>
            <ExploreToggleElement
              to={'/explore/collections'}
              isActive={(match, { pathname }) => displayType == ExploreType.Collections}
            >
              Collections
            </ExploreToggleElement>

            <ExploreToggleElement
              to={'/explore/activity'}
              isActive={(match, { pathname }) => displayType == ExploreType.Activity}
            >
              Activity
            </ExploreToggleElement>
          </ToggleWrapper>
          <FiltersWrapper>
            <MintsStatusFilter></MintsStatusFilter>
            <MintsCollectionFilter></MintsCollectionFilter>
            <MintsPriceFilter></MintsPriceFilter>
          </FiltersWrapper>
        </TopBarLeftWrapper>
        Sort
      </TopBar>
      <GridWrapper>
        {displayType == ExploreType.Activity ? (
          <TYPE.heavy>Activity</TYPE.heavy>
        ) : pathname === '/explore/collections' ? (
          <>
            <CollectionsGrid>
              {filteredCollections.map((collectionData, i) => {
                return collectionData ? (
                  <CollectionCard collectionID={collectionData.id} i={i}></CollectionCard>
                ) : (
                  <Loader></Loader>
                )
              })}
            </CollectionsGrid>
            {!isLastPage && (
              <ButtonLight width={'100%'} onClick={showMore}>
                Show more
              </ButtonLight>
            )}
          </>
        ) : (
          <>
            <Grid>
              {filteredMetadatas && filteredMetadatas?.length === 0 && <>Empty</>}
              {filteredMetadatas
                .slice(
                  0,
                  !isLastPage ? filteredMetadatas.length - (filteredMetadatas.length % 3) : filteredMetadatas.length
                )
                .map((mint, i) => {
                  return (
                    <AutoColumn key={i} style={{ padding: '10px', minHeight: '300px' }}>
                      <MintCard key={mint?.address ?? 'i'} mintAddress={mint?.address} />
                    </AutoColumn>
                  )
                })}
              <div style={{ height: '200px' }}></div>
              <div style={{ height: '200px' }}></div>
              <div style={{ height: '200px' }}></div>
            </Grid>
            {!isLastPage && (
              <ButtonLight width={'100%'} onClick={showMore}>
                Show more
              </ButtonLight>
            )}
          </>
        )}
      </GridWrapper>
    </ContentPageWrapper>
  )
}
