import {
  any,
  array,
  boolean,
  coerce,
  create,
  defaulted,
  enums,
  Infer,
  nullable,
  number,
  optional,
  string,
  type,
} from 'superstruct'
import { BoolFromNumber, NumberFromString } from 'validators/pubkey'

export const BoolFromAny = coerce(boolean(), any(), (value) => value == '1' || value == true)
export const StringFromAny = coerce(string(), any(), (value) =>
  value ? (typeof value == 'string' ? value : JSON.stringify(value)) : ''
)
export const NumberFromAny = coerce(number(), any(), (value) => {
  if (typeof value === 'number') return value
  if (typeof value === 'string') {
    try {
      parseInt(value, 10)
      return parseInt(value, 10)
    } catch {
      return 0
    }
  }
  if (typeof value === 'boolean') return value ? 1 : 0
  return 0
})

export type Sale = Infer<typeof Sale>
export const Sale = type({
  tokenMint: string(),
  receiptMint: string(),
  amount: NumberFromString,
  closed: BoolFromNumber,
  acceptedAsk: string(),
})

export type AskV2 = Infer<typeof AskV2>
export const AskV2 = type({
  pubkey: optional(defaulted(string(), '')),
  sale: string(),
  tokenMint: string(),
  amount: NumberFromString,
  expirationDate: NumberFromString,
  cancelled: BoolFromNumber,
  accepted: BoolFromNumber,
  feesPaid: NumberFromString,
  fundsWithdrawn: BoolFromNumber,
})

export type BidV2 = Infer<typeof BidV2>
export const BidV2 = type({
  pubkey: optional(defaulted(string(), '')),
  sale: string(),
  receiptMint: string(),
  tokenMint: string(),
  amount: number(),
  expirationDate: number(),
  cancelled: BoolFromNumber,
  accepted: BoolFromNumber,
  feesStored: number(),
  redeemed: BoolFromNumber,
  owner: optional(defaulted(string(), '')),
})

export type IMetadataCollection = Infer<typeof IMetadataCollection>
export const IMetadataCollection = type({
  name: defaulted(string(), ''),
  family: defaulted(string(), ''),
})

export type MetadataAttribute = Infer<typeof MetadataAttribute>
export const MetadataAttribute = type({
  trait_type: StringFromAny,
  value: StringFromAny,
})
export const AttributesFromAny = coerce(array(MetadataAttribute), any(), (value) =>
  value && Array.isArray(value) ? value.map((v) => create(v, MetadataAttribute)) : []
)

export type MetadataFile = Infer<typeof MetadataFile>
export const MetadataFile = type({
  uri: defaulted(string(), ''),
  type: defaulted(string(), ''),
})

export type Creator = Infer<typeof Creator>
export const Creator = type({
  address: string(),
  verified: BoolFromAny,
  share: number(),
})

export type MetadataDetails = Infer<typeof MetadataDetails>
export const MetadataDetails = type({
  name: string(),
  symbol: string(),
  uri: string(),
  sellerFeeBasisPoints: number(),
  creators: defaulted(array(Creator), []),
})

export type MetadataKey = Infer<typeof MetadataKey>
export const MetadataKey = enums([
  'Uninitialized',
  'MetadataV1',
  'EditionV1',
  'MasterEditionV1',
  'MasterEditionV2',
  'EditionMarker',
])

export type MetadataCategory = Infer<typeof MetadataCategory>
export const MetadataCategory = enums(['audio', 'video', 'image', 'vr', 'html'])

export type MetadataProperties = Infer<typeof MetadataProperties>
export const MetadataProperties = type({
  files: optional(array(MetadataFile)),
  category: StringFromAny,
  maxSupply: StringFromAny,
})
export const PropertiesFromAny = coerce(MetadataProperties, any(), (value) =>
  value
    ? create(value, MetadataProperties)
    : {
        files: [],
        category: '',
        maxSupply: '',
      }
)
export type MetadataExtension = Infer<typeof MetadataExtension>
export const MetadataExtension = type({
  name: StringFromAny,
  creators: defaulted(array(Creator), []),
  description: StringFromAny,
  image: StringFromAny,
  animation_url: StringFromAny,
  seller_fee_basis_points: NumberFromAny,
  properties: PropertiesFromAny,
  attributes: AttributesFromAny,
  collection: StringFromAny,
  use: StringFromAny,
})

export type MetadataCombined = Infer<typeof MetadataCombined>
export const MetadataCombined = type({
  key: number(),
  updateAuthority: string(),
  parent: defaulted(string(), ''),
  mint: string(),
  data: MetadataDetails,
  primarySaleHappened: BoolFromNumber,
  isMutable: BoolFromNumber,
  editionNonce: defaulted(string(), ''),
  extended: optional(nullable(MetadataExtension)),
})

export type Metadata = Infer<typeof MetadataCombined>
export const Metadata = type({
  key: number(),
  updateAuthority: string(),
  mint: string(),
  data: MetadataDetails,
  primarySaleHappened: BoolFromNumber,
  isMutable: BoolFromNumber,
  editionNonce: defaulted(string(), ''),
  extended: optional(MetadataExtension),
})
export type SaleData = Infer<typeof SaleData>
export const SaleData = type({
  pubkey: string(),
  sale: Sale,
  asks: optional(array(AskV2)),
  bids: optional(array(BidV2)),
  owner: optional(defaulted(string(), '')),
  metadata: optional(MetadataCombined),
})
