import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { pubkeyFromString } from 'apollo/utils'
import { SupportedChainId } from 'constants/chains'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useFetchListCallback } from '../../hooks/useFetchListCallback'
import useInterval from '../../hooks/useInterval'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { useActiveWeb3React } from '../../hooks/web3'
import { addRequiredUsers } from './actions'
import { useFetchUserFromChain } from './chain'
import { useFetchFirebaseUser, useUpdateFirebaseUser } from './firebase'
import {
  useAllChainRequests,
  useAllChainUsers,
  useAllFirebaseRequests,
  useAllFirebaseUsers,
  useRequiredUsers,
} from './hooks'
import { User } from './models'
import { RequestStatus } from './reducer'

const shouldRefreshFirebaseUser = (chainUser: User, firebaseUser: User): boolean => {
  console.log('shouldRefreshFirebaseUser: ', chainUser, firebaseUser)
  if (firebaseUser.ownedMints.length !== chainUser.ownedMints.length) {
    return true
  }

  // hackey way to prevent overwrites of savedMints after chain fetch
  // if (
  //   firebaseUser.savedMints &&
  //   chainUser.savedMints &&
  //   firebaseUser.savedMints.length !== chainUser.savedMints.length
  // ) {
  //   return true
  // }
  console.log('shouldRefreshFirebaseUser firebaseUser.ownedMints.length: ', firebaseUser.ownedMints.length)
  console.log('shouldRefreshFirebaseUser chainUser.ownedMints.length: ', chainUser.ownedMints.length)
  const chainStringified = JSON.stringify(
    [chainUser.ownedMints]
    // .sort((a, b) => {
    //   console.log('shouldRefreshFirebaseUser chainUser a: ', a)
    //   console.log('shouldRefreshFirebaseUser chainUser b: ', b)
    //   return a.address.localeCompare(b.address)
    // })
  )

  const firebaseStringified = JSON.stringify(
    [firebaseUser.ownedMints]
    // .sort((a, b) => {
    //   console.log('shouldRefreshFirebaseUser firebaseUser a: ', a)
    //   console.log('shouldRefreshFirebaseUser firebaseUser b: ', b)
    //   return a.address.localeCompare(b.address)
    // })
  )

  return chainStringified !== firebaseStringified
}
// firebaseUser.owner != chainUser.owner

export default function Updater(): null {
  const dispatch = useAppDispatch()
  const wallet = useAnchorWallet()

  // get all loaded lists, and the active urls
  const requiredUsers = useRequiredUsers()
  const allChainUsers = useAllChainUsers()
  const allFirebaseUsers = useAllFirebaseUsers()
  const allChainUserRequests = useAllChainRequests()
  const allFirebaseRequests = useAllFirebaseRequests()
  const fetchFirebaseUser = useFetchFirebaseUser()
  const updateFirebaseUser = useUpdateFirebaseUser()
  const fetchChainUser = useFetchUserFromChain()
  // const requiredUsers = useChainRequestsByContext('user')
  // const useAllChainRequests = useAllChainRequests()

  useEffect(() => {
    console.log('requiredUsers requiredUsers: ', requiredUsers)
    requiredUsers.forEach((userAddress) => {
      const pubkey = pubkeyFromString(userAddress)
      if (!pubkey) return
      console.log('requiredUsers allFirebaseUsers: ', allFirebaseUsers)
      const firebaseUser = allFirebaseUsers.find((m) => m.address == pubkey.toBase58())
      const firebaseFetchRequest = allFirebaseRequests.find(
        (r) => r.context == pubkey.toBase58() && r.type == 'fetch' && r.status != RequestStatus.Fulfilled
      )
      const firebaseCreateRequest = allFirebaseRequests.find(
        (r) => r.context == pubkey.toBase58() && r.type == 'update' && r.status != RequestStatus.Fulfilled
      )
      const chainUser = allChainUsers.find((m) => m.address == pubkey.toBase58())
      const chainRequest = allChainUserRequests.find(
        (r) =>
          r.context == pubkey.toBase58() &&
          r.type == 'fetch' &&
          r.status != RequestStatus.Fulfilled &&
          r.timestamp > Date.now() - 60 * 1000
      )
      // .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      // const chainRequests = allChainUserRequests
      //   .filter((r) => r.context == pubkey.toBase58() && r.type == 'fetch' && r.status != RequestStatus.Fulfilled)
      //   .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      console.log('requiredUsers userAddress: ', userAddress)
      console.log('requiredUsers pubkey: ', pubkey)
      console.log('requiredUsers firebaseUser: ', firebaseUser)
      console.log('requiredUsers firebaseFetchRequest: ', firebaseFetchRequest)
      console.log('requiredUsers firebaseCreateRequest: ', firebaseCreateRequest)
      console.log('requiredUsers chainUser: ', chainUser)
      // console.log('requiredUsers chainRequests: ', chainRequests)
      console.log('requiredUsers chainRequest: ', chainRequest)

      if (!firebaseUser && !firebaseFetchRequest) {
        console.log('requiredUsers request1')
        fetchFirebaseUser(userAddress)
        return
      }
      if (!chainUser && !chainRequest) {
        console.log('requiredUsers request2')
        fetchChainUser(userAddress)
        return
      }
      if (chainUser && !firebaseUser && !firebaseCreateRequest) {
        console.log('requiredUsers request3')
        updateFirebaseUser(chainUser)
        return
      }

      if (chainUser && firebaseUser) {
        console.log('requiredUsers request4 chainUser: ', chainUser)
        console.log('requiredUsers request4 firebaseUser: ', firebaseUser)
        const shouldUpdate = shouldRefreshFirebaseUser(chainUser, firebaseUser)
        console.log('requiredUsers shouldUpdate: ', shouldUpdate)
        console.log('requiredUsers shouldUpdate firebaseCreateRequest: ', firebaseCreateRequest)
        if (shouldRefreshFirebaseUser(firebaseUser, chainUser) && !firebaseCreateRequest) {
          console.log('requiredUsers request4 triggered for ' + pubkey.toBase58())
          updateFirebaseUser(chainUser)
          return
        }
      }
    })
  }, [
    allChainUserRequests,
    allChainUsers,
    allFirebaseUsers,
    allFirebaseRequests,
    updateFirebaseUser,
    dispatch,
    fetchChainUser,
    fetchFirebaseUser,
    requiredUsers,
  ])
  // useEffect(() => {
  //   allChainUsers.forEach((chainUser) => {
  //     if (!allFirebaseUsers.find((firebaseUser) => firebaseUser.pubkey === chainUser.add)) {
  //       if (!allFirebaseRequests.find((r) => r.context == chainUser.pubkey)) {
  //       }
  //     }
  //   })
  // }, [allChainUsers, allFirebaseRequests, allFirebaseUsers, dispatch, requiredUsers])
  // fetch all lists every 10 minutes, but only after we initialize library
  // useEffect(() => {
  // useEffect(() => {
  //   if (wallet?.publicKey) {
  //     dispatch(addRequiredUsers([wallet?.publicKey.toBase58()]))
  //   }
  // }, [dispatch, wallet?.publicKey])

  return null
}
// const i = 0
// export default i
