import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import numbro from 'numbro'

export const cleanName = (name?: string): string | undefined => {
  if (!name) {
    return undefined
  }

  return name.replace(/\s+/g, '-')
}

export const getLast = <T>(arr: T[]) => {
  if (arr.length <= 0) {
    return undefined
  }

  return arr[arr.length - 1]
}

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ')
}

export const WRAPPED_SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112')
export const SYSTEM_ACCOUNT = new PublicKey('11111111111111111111111111111111')
export function mintIsNative(mint: string): boolean {
  const isNative = mint == WRAPPED_SOL_MINT.toBase58()
  return isNative
}

export const formatRemainingTime = (endDate: Date) => {
  // const date = new Date(ms);
  const now = new Date()
  const diff = endDate.valueOf() - now.valueOf()
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)
  const daysStr = days > 0 ? `${days}d ` : ''
  const hoursStr = days < 10 && hours > 0 ? `${hours}h ` : ''
  const minutesStr = minutes > 0 && hours < 10 ? `${minutes}m ` : ''
  const secondsStr = minutes == 0 ? `${seconds}s ` : ''
  // return string should be a maximum of 2 words
  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`
}

export function formatRemainingTimeDiff(diff: number) {
  // const date = new Date(ms);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)
  const daysStr = days > 0 ? `${days} days ` : ''
  const hoursStr = days < 10 && hours > 0 ? `${hours} hr ` : ''
  const minutesStr = minutes > 0 && hours < 10 ? `${minutes} min ` : ''
  const secondsStr = minutes == 0 ? `${seconds} sec ` : ''
  // return string should be a maximum of 2 words
  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`
}

// abcd...wxyz
export function truncateString(str?: string) {
  if (!str) {
    return ''
  }
  return str.slice(0, 4) + '...' + str.slice(str.length - 4, str.length)
}

export const lamportsToSol = (lamports: number) => {
  return Math.round((lamports / LAMPORTS_PER_SOL) * 100) / 100
}

type ExpandedDate = {
  years: number
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

function formatDate(ms: number): ExpandedDate {
  return {
    years: Math.floor(ms / 31536000000),
    days: Math.floor((ms / 86400000) % 365),
    hours: Math.floor((ms / 3600000) % 24),
    minutes: Math.floor((ms / 60000) % 60),
    seconds: Math.floor((ms / 1000) % 60),
    milliseconds: Math.floor(ms % 1000),
  }
}

/*
Simple function to add a key value pair if the value is greater than 0
*/
function add(key: any, value: any, postfix: any) {
  if (value > 0) {
    key.push(value + postfix)
  }

  return key
}

/*
The datePrettyFormat function converts milliseconds to a human readible
date output that corresponds to a specific format.
*/
function datePrettyFormat(ms: number, short?: boolean) {
  if (typeof ms !== 'number') {
    throw new TypeError('Expected a number')
  }

  if (ms < 1000) {
    return Math.ceil(ms) + 'ms'
  }

  // opts = opts || {};

  // var secDecimalDigits = true ? opts.secDecimalDigits : 1
  const secDecimalDigits = 0
  // let ret = []
  const parsed = formatDate(ms)

  let ret = add([], parsed.years, 'y')
  ret = add(ret, parsed.days, 'd')
  ret = add(ret, parsed.hours, 'h')
  ret = add(ret, parsed.minutes, 'm')

  // if (opts.compact) {
  //   ret = add(ret, parsed.seconds, 's');
  //   return '~' + ret[0];
  // }

  ret = short
    ? add(ret, ((((ms / 1000) % 60) % 60) % 60).toFixed(secDecimalDigits).replace(/\.0$/, ''), 's')
    : add(ret, ((ms / 1000) % 60).toFixed(secDecimalDigits).replace(/\.0$/, ''), 's')

  return ret.join(' ')
}

/*
Most of the solutions don't take into account a case that fails when
the two dates involved go across a daylight saving change. In this case,
the date on which day light saving change happens will have a duration in
milliseconds which != 1000*60*60*24, so the typical calculation will fail.
This function returns the difference between two dates represented in
a specific format
An example output without options is: 34y 338d 4h 8m 7s
An example output with options is: ~34y
*/
export function formatDateDiff(a: Date, b: Date, short?: boolean) {
  // UTC time never observes Daylight Savings Time (DST)
  const utc1 = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes(),
    a.getSeconds(),
    a.getMilliseconds()
  )
  const utc2 = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes(),
    b.getSeconds(),
    b.getMilliseconds()
  )

  return datePrettyFormat(utc2 - utc1, short)
}

export const formatDollarAmount = (num: number | undefined, digits = 2, round = true) => {
  if (num === 0) return '$0.00'
  if (!num) return '-'
  if (num < 0.001 && digits <= 3) {
    return '<$0.001'
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  })
}
