import { Trans } from '@lingui/macro'
import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import { Text } from 'rebass'
import styled, { DefaultTheme } from 'styled-components/macro'

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`

export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme }) => theme.primary1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

export const LoadingRows = styled(BaseLoadingRows)`
  min-width: 75%;
  max-width: 960px;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(3, 1fr);

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
`

export enum CollectionVerificationState {
  UNVERIFED = 0,
  VERIFIED = 1,
}
const handleColorType = (status: CollectionVerificationState, theme: DefaultTheme) => {
  switch (status) {
    case CollectionVerificationState.UNVERIFED:
      return theme.blue1
    case CollectionVerificationState.VERIFIED:
      return theme.green1
    default:
      return theme.blue1
  }
}

function StatusText({ status }: { status: CollectionVerificationState }) {
  switch (status) {
    case CollectionVerificationState.UNVERIFED:
      return <Trans>Not Verified</Trans>
    case CollectionVerificationState.VERIFIED:
      return <Trans>Verified</Trans>
    default:
      return <Trans>Undetermined</Trans>
  }
}

const StyledProposalContainer = styled.span<{ status: CollectionVerificationState }>`
  font-size: 0.825rem;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 8px;
  color: ${({ status, theme }) => handleColorType(status, theme)};
  border: 1px solid ${({ status, theme }) => handleColorType(status, theme)};
  width: fit-content;
  justify-self: flex-end;
  text-transform: uppercase;
  flex: 0 0 100px;
  text-align: center;
`

export function CollectionVerificationStatus({ status }: { status: CollectionVerificationState }) {
  return (
    <StyledProposalContainer status={status}>
      <StatusText status={status} />
    </StyledProposalContainer>
  )
}
