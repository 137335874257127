import { PrivacyPolicyModal } from 'components/PrivacyPolicy'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useRef } from 'react'
import { useModalOpen, useToggleModal } from 'state/application/hooks'

import { StyledMenu } from '../styled'
import MintsCollectionCoverButton from './MintsCollectionCoverButton'
import MintsCollectionFlyout from './MintsCollectionFlyout'

export default function MintsCollectionFilter() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen('MintsCollectionFilter')
  const toggleMenu = useToggleModal('MintsCollectionFilter')
  useOnClickOutside(node, open ? toggleMenu : undefined)

  return (
    <>
      <StyledMenu ref={node as any}>
        <MintsCollectionCoverButton toggle={toggleMenu} />
        {open && <MintsCollectionFlyout />}
      </StyledMenu>
      <PrivacyPolicyModal />
    </>
  )
}
