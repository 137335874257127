import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { LoadingView, SubmittedView } from 'components/ModalViews'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { useCollection, useCollectionsFirebaseRequestByRequestID } from 'state/collections/hooks'
import { RequestStatus } from 'state/collections/reducer'
import { ThemeContext } from 'styled-components/macro'
import { ExternalLink, StyledInternalLink, TYPE } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

export const CollectionSubmissionModal = ({
  isOpen,
  requestID,
  onDismiss,
}: {
  isOpen: boolean
  requestID: string | undefined
  onDismiss: () => void
}) => {
  const theme = useContext(ThemeContext)
  const request = useCollectionsFirebaseRequestByRequestID(requestID)
  const collection = useCollection(request?.context)

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      {!requestID ? (
        <LoadingView onDismiss={onDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>
              <Trans>Submitting Collection</Trans>
            </TYPE.largeHeader>
          </AutoColumn>
        </LoadingView>
      ) : (
        <SubmittedView onDismiss={onDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={20} textAlign="center">
              <Trans>Collection Submitted!</Trans>
            </Text>
            {request?.status == RequestStatus.Fulfilled && (
              <StyledInternalLink to={`/collections/${collection?.id}`}>
                <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                  <Trans>View Collection</Trans>
                </Text>
              </StyledInternalLink>
            )}
            <ButtonPrimary as={Link} to="/collections" onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
              <Text fontWeight={500} fontSize={20}>
                <Trans>Return</Trans>
              </Text>
            </ButtonPrimary>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
