import algoliasearch from 'algoliasearch/lite'
import React, { useMemo } from 'react'
import { Configure, InstantSearch, useConfigure } from 'react-instantsearch-hooks'
import { useLocation } from 'react-router-dom'
import { useSearchState } from 'state/search/hooks'
import { SearchRefinementIndex } from 'state/search/reducer'

export const AlgoliaSearchClient = algoliasearch('FCQ5WW8ALY', '4f03d5bb811dadb72b651db19619c4b3')

export default function SearchProvider({ children }: React.PropsWithChildren<{ index: string }>) {
  const searchState = useSearchState()
  const location = useLocation()
  const refinementList = useMemo(() => searchState.refinementList, [searchState])
  const refinementKeys = useMemo(() => new Set(refinementList.map((r) => r.index)), [refinementList])
  console.log('facetFilters refinementKeys', refinementKeys)
  const filters = useMemo(() => {
    const f = []
    for (const index of refinementKeys) {
      const values = refinementList.filter((value) => value.index === index).map((r) => r.value)
      if (index == SearchRefinementIndex.SaleState) {
        f.push([...values.map((value) => `${SearchRefinementIndex.SaleState}:${value}`)])
      }
      if (index == SearchRefinementIndex.Collection) {
        f.push([...values.map((value) => `${SearchRefinementIndex.Collection}:${value}`)])
      }
    }
    return f
  }, [refinementKeys, refinementList])

  console.log('SearchProvider facetFilters', filters)
  console.log('SearchProvider searchState.index', searchState.index)

  // // facetFilters: [
  // 'attribute:value', // (single string)

  // // attribute1:value AND attribute2:value (multiple strings)
  // 'attribute1:value', 'attribute2:value'

  // // attribute1:value OR attribute2:value (multiple strings within an array)
  // ['attribute1:value', 'attribute2:value'],

  // // (attribute1:value OR attribute2:value) AND attribute3:value (combined strings and arrays)
  // ['attribute1:value', 'attribute2:value'], 'attribute3:value',

  return (
    <InstantSearch
      searchClient={AlgoliaSearchClient}
      indexName={searchState.index}
      // refin
      routing={false}
    >
      {children}
      <Configure hitsPerPage={15} query={searchState.query} facetFilters={filters} />
    </InstantSearch>
  )
}
