import { Trans } from '@lingui/macro'
import * as anchor from '@project-serum/anchor'
import { TokenInfo } from '@solana/spl-token-registry'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { useFirebaseLog } from 'api/firebaseHooks'
import { addAsk, createSale } from 'apollo/instructions'
import CreateHeader from 'components/create/CreateHeader'
import { NFTSelectList } from 'components/create/NFTSelectList'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import DowntimeWarning from 'components/DowntimeWarning'
import ExpirationDurationSelector, { datifyExpiration, ExpiationDuration } from 'components/ExpirationDateSelector'
import { useApolloProgram } from 'components/SolanaManager'
import TXConfirmationModal from 'components/TXConfirmationModal'
import { useTokenRegistry } from 'providers/mints/token-registry'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import { addRequiredSales } from 'state/sales/actions'
import { mintIsNative, WRAPPED_SOL_MINT } from 'utils/utils'

import { ButtonLight, ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { RowBetween } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import {
  bundleMintUpdateAuthorityKeypair,
  curatorAddress,
  curatorBasisPoints,
  useActiveWeb3React,
} from '../../hooks/web3'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'
import {
  DynamicSection,
  PageWrapper,
  ResponsiveTwoColumns,
  RightContainer,
  ScrollablePage,
  StackedContainer,
  StackedItem,
  Wrapper,
} from './styled'

export default function CreateSale({
  match: {},
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string; feeAmount?: string; tokenId?: string }>) {
  const { chainId } = useActiveWeb3React()
  const wallet = useAnchorWallet()
  const apollo = useApolloProgram()
  const publicKey = wallet?.publicKey
  const toggleWalletModal = useWalletModalToggle()

  const dispatch = useDispatch()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)

  const [txHash, setTxHash] = useState<string>('')

  const logEvent = useFirebaseLog()

  const [newSalePubkey, setNewSalePubkey] = useState<string | undefined>()

  // const { uploadSaleIfNeeded } = useFirebaseSaleRefresher(apollo, newSalePubkey)
  useEffect(() => {
    if (newSalePubkey) {
      // uploadSaleIfNeeded()
      dispatch(addRequiredSales([newSalePubkey]))
    }
  }, [dispatch, newSalePubkey])

  async function onSubmit() {
    setSubmitError(undefined)
    const selectedMint = selectedMints.find((m) => m.amount > 0)
    logEvent('event!')

    if (apollo && publicKey && askCurrency?.address && selectedMint) {
      try {
        const sale = anchor.web3.Keypair.generate()
        const curatorAddrStr = curatorAddress()
        const curatorBasisPts = curatorBasisPoints()
        const curatorPubkey = new PublicKey(curatorAddrStr)
        const bundleMint = Keypair.generate()
        const bundleMintUpdateAuth = bundleMintUpdateAuthorityKeypair()
        const metadataURI = 'https://apollo-full-stack.vercel.app/sale_metadata.json'
        const metadataName = 'Apollo Sale'
        const metadataSymbol = 'APOLLO'
        // const initBundleTXIs = await initBundle(
        //   apollo,
        //   bundle,
        //   bundleMint.publicKey,
        //   publicKey as PublicKey,
        //   bundleMintUpdateAuth.publicKey,
        //   // curatorPubkey,
        //   // curatorBasisPts,
        //   initBundleBoxes,
        //   metadataName,
        //   metadataSymbol,
        //   metadataURI
        // )
        const createSaleTXIs = await createSale(
          apollo,
          sale,
          publicKey as PublicKey,
          bundleMint.publicKey,
          bundleMintUpdateAuth.publicKey,
          new PublicKey(selectedMint.mintAddress),
          new PublicKey(selectedMint.address),
          selectedMint.amount,
          metadataName,
          metadataSymbol,
          metadataURI
        )
        const tx = new anchor.web3.Transaction()
        const askAmtNum = parseFloat(askAmount)
        tx.add(...createSaleTXIs.flatMap((t) => t.txis))
        const askMint = new PublicKey(askCurrency.address)

        const initialAsk = anchor.web3.Keypair.generate()
        const addAskTXIs = await addAsk(
          apollo,
          initialAsk,
          sale.publicKey,
          bundleMint.publicKey,
          publicKey as PublicKey,
          askMint,
          mintIsNative(askMint.toBase58()) ? askAmtNum * LAMPORTS_PER_SOL : askAmtNum,
          datifyExpiration(expirationDuration)
        )
        tx.add(...addAskTXIs.flatMap((t) => t.txis))
        console.log('transactions', tx)
        setAttemptingTxn(true)
        const res = await apollo.provider.send(tx, [sale, bundleMint, bundleMintUpdateAuth, initialAsk], {
          // commitment: 'finalized',
        })
        if (res) {
          console.log('res', res)
          setAttemptingTxn(false)
          setTxHash(res)
          setNewSalePubkey(sale.publicKey.toString())
        }
      } catch (e) {
        setAttemptingTxn(false)
        console.error(e)
        setSubmitError(e.toString())
      }
    } else {
      setSubmitError('Invalid Parameters')
    }
  }

  const [expirationDuration, setExpirationDuration] = useState<ExpiationDuration>(ExpiationDuration.SEVENDAYS)
  const { tokenRegistry } = useTokenRegistry()

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setTimeout(() => {
      setSubmitError(undefined)
    }, 200)

    if (txHash) {
    }
    setTxHash('')
  }, [history, txHash])

  const Buttons = () => (
    <ButtonLight
      onClick={() => {
        setShowConfirm(true)
      }}
      $borderRadius="12px"
      padding={'12px'}
    >
      <Trans>Submit</Trans>
    </ButtonLight>
  )

  const [selectedMints, setSelectedMints] = useState<SelectedToken[]>([])
  const [askAmount, setSelectedAskAmount] = useState<string>('1')
  const [askCurrency, setSelectedAskCurrency] = useState<TokenInfo | undefined>()
  const [expirationDate, setExpirationDate] = useState<ExpiationDuration | undefined>()
  const [submitError, setSubmitError] = useState<string | undefined>()

  useEffect(() => {
    setSelectedAskCurrency(tokenRegistry.get(WRAPPED_SOL_MINT.toBase58()))
  }, [tokenRegistry])

  return (
    <>
      <ScrollablePage>
        <DowntimeWarning />
        <TXConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          errorText={submitError}
          doneLink={`user/${publicKey?.toBase58()}`}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>Confirm Bundle and Ask</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={() => <Trans>Tokens</Trans>}
              bottomContent={() => (
                <ButtonPrimary style={{ marginTop: '1rem' }} onClick={onSubmit}>
                  <Text fontWeight={500} fontSize={20}>
                    <Trans>Submit</Trans>
                  </Text>
                </ButtonPrimary>
              )}
            />
          )}
          pendingText={'...'}
        />
        <PageWrapper wide={true}>
          <CreateHeader></CreateHeader>
          <Wrapper>
            <ResponsiveTwoColumns wide={true}>
              <div>
                <TYPE.mediumHeader>
                  <Trans>Token</Trans>
                </TYPE.mediumHeader>
                {!publicKey ? (
                  <ButtonLight onClick={toggleWalletModal} $borderRadius="12px" padding={'12px'}>
                    <Trans>Connect Wallet</Trans>
                  </ButtonLight>
                ) : (
                  <NFTSelectList
                    userPubkey={publicKey!}
                    setSelectedMints={setSelectedMints}
                    selectedMints={selectedMints}
                  />
                )}
              </div>
              <>
                <RightContainer gap="lg">
                  <DynamicSection gap="md" disabled={false}>
                    <StackedContainer>
                      <StackedItem style={{}}>
                        <AutoColumn gap="md">
                          <RowBetween>
                            <TYPE.mediumHeader>
                              <Trans>Initial Ask</Trans>
                            </TYPE.mediumHeader>
                          </RowBetween>
                          {/*  */}
                          <CurrencyInputPanel
                            label={'Select Token'}
                            value={`${askAmount}`}
                            currency={askCurrency}
                            onUserInput={(value: string) => {
                              if (parseFloat(value) > 0) {
                                setSelectedAskAmount(value)
                              } else {
                                setSelectedAskAmount('0')
                              }
                            }}
                            onCurrencySelect={setSelectedAskCurrency}
                            id="swap-currency-input"
                            loading={false}
                          />
                          <TYPE.sectionHeader>Expires</TYPE.sectionHeader>
                          <ExpirationDurationSelector
                            expirDuration={expirationDate}
                            handleExpirDurationSelect={setExpirationDate}
                          ></ExpirationDurationSelector>
                        </AutoColumn>
                      </StackedItem>
                    </StackedContainer>
                  </DynamicSection>
                  <Buttons />
                </RightContainer>
              </>
            </ResponsiveTwoColumns>
          </Wrapper>
        </PageWrapper>
      </ScrollablePage>
      <SwitchLocaleLink />
    </>
  )
}

export type SelectedToken = { address: string; amount: number; mintAddress: string }
